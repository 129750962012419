<template>
    <el-dialog
        :title="msg('统计')"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :show-close = "false"
        width="300">
        <el-form ref="form" label-width="100px">
            <el-form-item :label="msg('选中数量：')">
                <el-tag size="medium" type="primary">{{checked}}</el-tag> 条
            </el-form-item>
            <el-form-item :label="msg('对应订单：')">
                <el-tag size="medium" type="primary">{{count}}</el-tag> 个
            </el-form-item>
            <el-form-item :label="msg('商品件数：')">
                <el-tag size="medium" type="primary">{{qty}}</el-tag> 件
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="toggleVisible">{{msg('确定')}}</el-button>
        </span>
    </el-dialog>
</template>
<script>

export default {
    name: 'SummaryDetail',
    data(){
        return {

        }
    },
    props: {
        checked: Number,  //选中数量
        count: Number,   //对应订单
        qty: Number,     //商品件数
        visible: Boolean, //是否显示
    },
    computed: {
        isVisible: {
            get() {
                return this.visible
            },
            set(newValue) {
                this.$emit('update:visible', newValue)
            }
        }
    },
    methods: {
        toggleVisible() {
            this.isVisible = !this.isVisible
        }
    }
}
</script>