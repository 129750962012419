<!-- 货物采购--更新快递单号 -->
<template>
<el-form label-width="100px">
    <el-row>
        <el-col :xs="16" :sm="12">
            <el-form-item :label="msg('快递单号')" label-width="100px">
                <el-input
                    v-model="innerCode" style="margin-top:0px;" placeholder="请输入快递单号" size="small" @keyup.native="autoSelectCode">
                </el-input>
                    <el-tag type="danger">提示: 需要分包的快递请在后面报单备注加好说明</el-tag>
            </el-form-item>
        </el-col>
    </el-row>
    <div style="text-align:center; margin-top: 1px;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>  

    <el-row>
        <el-table :data="results" style="width: 100%;margin-top: 10px;" size="small" ref="dataTable" max-height="400" stripe>
          <el-table-column label="执行结果">
            <!-- <el-table-column type="selection" min-width="55"></el-table-column> -->
            <el-table-column prop="detailId" :label="msg('ID')" min-width="40" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="code" :label="msg('单号')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
            <!-- <el-table-column prop="title" :label="msg('标题')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="spec" :label="msg('规格')" min-width="100" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="sku" :label="msg('SKU')" min-width="100" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="valuSku" :label="msg('货号')" min-width="80" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="qty" :label="msg('数量')" min-width="40" :show-overflow-tooltip="true"></el-table-column> -->
            <el-table-column prop="msg" :label="msg('结果')" min-width="150" :show-overflow-tooltip="true"></el-table-column>
          </el-table-column>
        </el-table>
    </el-row> 

</el-form> 
</template>

<script>
export default {
  name: 'StockDetailPurchase',
  data(){
    return{
        innerCode:null,
        results:[],
        idList:[],
    }
  }, 
  computed:{
  },
  mounted: function(){
  },
  methods:{
      init(data){
            this.innerCode = null;
            this.results = [];
            this.idList = data;
      },
      autoSelectCode(e){
        if(e.keyCode == 13){
            this.onSubmit();
        }
      },
    onSubmit(){
        // var selectes = this.$refs.dataTable.selection;

        // let pc = '';
        // if(selectes.length > 0){
        //     pc = selectes.map(s=>s.expBatch).join(",");
        // }
        if(!this.innerCode){
            this.$message(this.msg('快递单号不能为空'));
        }
        let para = [];
        this.idList.forEach(e=>{
            para.push({
                id:e.detailId,
                stockId:e.stockId,
                innerExpCode:this.innerCode,
                code:e.code
            });
        })
        this.$axios.post(this.$kit.api.stock.updateDetailCodes+"?type=0",para)
            .then((result) => {
                if(result.status){
                    let data = result.data;
                    this.results = data;
                    this.innerCode = null;
                    // this.$emit("close-dg");
                }else{
                    this.$message(this.msg('操作失败'));
                }
            });
        // this.$emit("close-dg",
        //     {
        //         code:this.code,
        //         type:this.type,
        //         price:this.price,
        //         created:this.created,
        //         idx:this.idx,
        //         idx1:this.idx1
        //     });
    },
    
  }
}
</script>

<style scoped lang="scss">

</style>
