<!--打印自定义面单-->
<template>
    <div
        class="print-box-zdy"
        v-if="ctrls && ctrls.length>0"
        style="display: inline"
    >
        <div class="print-wrap-zdy" v-for="(page, index) in this.datas[0].ticketPage" :key="index">
            <div class="next-page" v-if="index>0"></div>
            <span v-html="page"></span>
        </div>
    </div>
</template>

<script>

// import JsBarcode from 'jsbarcode';
export default {
    name: "Ticket",
    data() {
        return {
        }
    },
    props: {
        ctrls: Array, // 0-打印面单，1-打印货单，2-打印入仓标签, 3-打印找货图, 5-打印上架单，6-打印报关单
        datas: Array,
        ticketSetting: Object,
        printType:Boolean
    },
    components:{
    },
    computed: {
    },
    methods:{
    }
};
</script>

<style lang="scss">
.next-page {
  page-break-before: always;
}
</style>
