<!--导入库存-->
<template>
    <div class="content-panel">
        <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
            <el-col :span="20">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('货物管理')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{msg('导入资料')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-row :gutter="10"  class="ctrl-wrap">
            <el-col :sm="24" :md="12">
                <el-button size="mini"
                    @click="doSelectFile()" type="success" 
                    style="margin-right:5px; margin-bottom:5px;">
                        <i class="iconfont icon-xuanzewenjian" 
                            style="display:inline-block;margin-right:4px;"></i>
                        <transition name="el-fade-in-linear">
                            <span v-show="!isMinScreen">{{msg('上传文件')}}</span>
                        </transition>
                </el-button>
                <div ref="fileArea" style="display:none;">
                </div>
                <!-- <el-radio-group type="info" v-model="strategy" size="mini" >
                    <el-radio-button label="0">新增</el-radio-button>
                    <el-radio-button label="1">更新</el-radio-button>
                </el-radio-group> -->
                <el-select v-model="strategy" style="margin-bottom:5px;"
                    placeholder="选择导入类型" size="mini" @change="changeStrategy">
                    <el-option
                        v-for="item in importTypes"
                        :key="item.value" 
                        :label="item.label"
                        :value="item.value"> 
                    </el-option>
                </el-select>
                <el-select v-if="strategy == 3" v-model="orderOrigin" style="margin-bottom:5px;"
                    placeholder="选择订单来源" size="mini">
                    <el-option
                        v-for="item in orderOrigins"
                        :key="item.value" 
                        :label="item.label"
                        :value="item.value"> 
                    </el-option>
                </el-select>
                <el-button size="mini"  @click="doUpload()" type="primary" style="margin-bottom:5px;">
                    <i class="el-icon-upload2" style="display:inline-block;margin-right:4px;"></i>
                    <transition name="el-fade-in-linear">
                        <span v-show="!isMinScreen"> {{msg('确认提交')}}</span>
                    </transition>
                </el-button>
                <el-select style="margin-bottom:5px;"
                    placeholder="点击查看有效配送方式" size="mini">
                    <el-option
                        v-for="item in expCompanys"
                        :key="item.value" 
                        :label="item.label"
                        :value="item.value"> 
                    </el-option>
                </el-select>
            </el-col>
            <el-col :sm="24" :md="12">
                <el-select v-model="importTemplate" @change="selectChanged" style="margin-bottom:5px;"
                        clearable 
                        @clear="clearSelect"
                        placeholder="请选择模板" size="mini">
                    <el-option
                        v-for="item in templates"
                        :key="item.id" 
                        :label="item.name"
                        :value="item.id"> 
                            <span style="float: left">{{ item.name }}</span>
                            <span style="float: right; position:relative; top:10px;" @click="deleteTemplate($event, item)" class="el-icon-delete-solid">&nbsp;&nbsp;</span>
                    </el-option>
                </el-select>
                <el-button type="primary" size="mini" @click="doDownloadTpl" plain style="margin-bottom:5px;">
                    <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
                    <transition name="el-fade-in-linear">
                        <span v-show="!isMinScreen">{{msg('下载模板')}}</span>
                    </transition>
                </el-button> 
                <el-button size="mini" @click="saveTemplate" type="success" style="margin-bottom:5px;">
                    <i class="el-icon-upload2" style="display:inline-block;margin-right:4px;"></i>
                    <transition name="el-icon-document-checked">
                        <span v-show="!isMinScreen">{{msg('保存模板')}}</span>
                    </transition>
                </el-button>
                <el-button type="primary" size="mini" @click="doDownloadDefaultTpl" plain style="margin-bottom:5px;">
                    <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
                    <transition name="el-fade-in-linear">
                        <span v-show="!isMinScreen">{{msg('下载默认模板')}}</span>
                    </transition>
                </el-button>
            </el-col>
        </el-row>
        <el-row :gutter="15">
            <el-col :sm="24" :md="12">
                <el-table :data="colNames" :height="docHeight" style="width: 100%" size="mini" ref="colTable" stripe>
                    <el-table-column type="selection" width="50"></el-table-column>
                    <el-table-column prop="needName" :label="msg('数据列')" min-width="120" 
                        :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div>
                                <div>{{scope.row.needName}}</div>
                                <div v-if="scope.row.remark" 
                                        style="font-size: 0.5rem; color: red; white-space:pre-wrap;">
                                    {{scope.row.remark}}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="targetName" min-width="120" :label="msg('选择表头')" >
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.targetName" placeholder="请选择表头" 
                                filterable allow-create default-first-option clearable
                                @change="(idx) => selectHeadChanged(idx, scope.row, scope.$index)"
                                size="small">
                                    <el-option v-for="(item, idx) in excelHeads"
                                        :key="idx" 
                                        :label="item.targetName"
                                        :value="item">
                                    </el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
            <el-col :sm="24" :md="12">
               <el-table :data="results" :height="docHeight" style="width: 100%" size="mini" ref="dataTable" stripe>
                    <el-table-column type="index" width="50"></el-table-column>
                    <el-table-column prop="code" :label="msg('单号')" min-width="120" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="codeType" :label="msg('单号类型')" min-width="100" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="msg" :label="msg('上传结果')" min-width="200"></el-table-column>
                </el-table>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import XLSX from "xlsx";
export default {
    name: 'StockImport',
    components: {},
    data(){
        return {
            strategy: null,
            excelHeads: [], //从excel解析出数据的表头
            excelData: null, //从excel解析出的数据
            importTemplate: null,
            storeId: null,
            colNames: [  //字段映射
            ],
            importTypes:[],
            orderOrigin:null,
            orderOrigins:[
                {
                    label:this.msg('店配'),
                    value:1
                },
                {
                    label:this.msg('线下'),
                    value:2
                },
            ],
            results:[
            ],
            templates: [],
            expCompanys:[],
        }
    },
    computed: {
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
    },
    mounted(){
        this.init();
        this.getTemplates();
        this.initColumn();
    },
    methods:{
        init(){
            //初始化权限
            this.importTypes = [];
            if(this.hasPermission('stock:importChuhuo')){
                this.importTypes.push(
                    {
                        label:this.msg('新增出货资料'),
                        value:3
                    }
                )
            }
            if(this.hasPermission('stock:importUpdate')){
                this.importTypes.push(
                    {
                        label:this.msg('更新订单资料'),
                        value:1
                    }
                )
            }
            if(this.hasPermission('stock:importKucun')){
                this.importTypes.push(
                    {
                        label:this.msg('新增现货仓储'),
                        value:4
                    }
                )
            }
            if(this.hasPermission('stock:importKucun')){
                this.importTypes.push(
                    {
                        label:this.msg('新增库存资料'),
                        value:2
                    }
                )
            }
            if(this.hasPermission('stock:importChuhuo')){
                this.importTypes.push(
                    {
                        label:this.msg('导入快递单号'),
                        value:5
                    }
                )
            }
            if(this.hasPermission('stock:importChuhuo')){
                this.importTypes.push(
                    {
                        label:this.msg('新增海快资料'),
                        value:6
                    }
                )
            }
            this.getCompanys();
        },
        getCompanys(){
            this.$axios.get(this.$kit.api.getExpCompany)
                .then((result)=> {
                    if(result.status){
                        this.expCompanys = result.data;
                    }
                });
        },
        initColumn(){//初始化列表权限
            let newCol = [
                {
                    colName: 'code',    //库存需要的字段名
                    needName: '订单编号',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark: '*必填项',
                    primision:this.hasPermission('importExcel:code')
                },
                {
                    colName: 'expCompany',    //库存需要的字段名
                    needName: '配送方式',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'配送方式指：7-11|全家|OK|萊爾富|黑貓|新竹|聚鼎等，导入店配和线下都为必填项 ，且需填写正确有效的配送方式;详情可点击上方有效配送方式进行查看',
                    primision:this.hasPermission('importExcel:expCompany')
                },
                {
                    colName: 'expCode',    //库存需要的字段名
                    needName: '查询码',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:expCode')
                },
                {
                    colName: 'expPageCode',    //库存需要的字段名
                    needName: '面单码',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:expPageCode')
                },
                {
                    colName: 'shopName',    //库存需要的字段名
                    needName: '店铺名',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'导入店配为必填项',
                    primision:this.hasPermission('importExcel:shopName')
                },
                {
                    colName: 'receiver',    //库存需要的字段名
                    needName: '收件人',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'导入线下为必填项',
                    primision:this.hasPermission('importExcel:receiver')
                },
                {
                    colName: 'receiverTel',    //库存需要的字段名
                    needName: '收件人电话',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'导入线下为必填项',
                    primision:this.hasPermission('importExcel:receiverTel')
                },
                {
                    colName: 'receiverAddr',    //库存需要的字段名
                    needName: '收件人地址',   //库存需要的字段显示名 
                    targetName: '',  //excel的表头名
                    remark:'导入线下为必填项',
                    primision:this.hasPermission('importExcel:receiverAddr')
                },
                {
                    colName: 'receiverShopName',    //库存需要的字段名
                    needName: '收件人门市名',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:receiverShopName')
                },
                {
                    colName: 'receiverShopCode',    //库存需要的字段名
                    needName: '收件人门市编号',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:receiverShopCode')
                },
                {
                    colName: 'transport',    //库存需要的字段名
                    needName: '运输方式',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'海快|空运|新航线；供参考，实际以出货为准',
                    primision:this.hasPermission('importExcel:expCompany')
                },
                {
                    colName: 'productTitle',    //库存需要的字段名
                    needName: '货物标题',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark: '方便找货',
                    primision:this.hasPermission('importExcel:productTitle')
                },
                {
                    colName: 'productName',    //库存需要的字段名
                    needName: '货物品名',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark: '导入店配和线下为必填项',
                    primision:this.hasPermission('importExcel:productName')
                },
                {
                    colName: 'productSpec',    //库存需要的字段名
                    needName: '货物规格',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark: '',
                    primision:this.hasPermission('importExcel:productSpec')
                },
                {
                    colName: 'productQty',    //库存需要的字段名
                    needName: '货物件数',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark: '货物的总数量',
                    primision:this.hasPermission('importExcel:productQty')
                },
                {
                    colName: 'productPrice',    //库存需要的字段名
                    needName: '价格',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark: '导入线下为必填项',
                    primision:this.hasPermission('importExcel:productPrice')
                },
                {
                    colName: 'variationSku',    //库存需要的字段名
                    needName: '货号',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark: '',
                    primision:this.hasPermission('importExcel:productSpec')
                },
                {
                    colName: 'sku',    //库存需要的字段名
                    needName: 'SKU',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark: '',
                    primision:this.hasPermission('importExcel:productSpec')
                },
                {
                    colName: 'expCod',    //库存需要的字段名
                    needName: '配送类别',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'请输入：纯配送|货到付款',
                    primision:this.hasPermission('importExcel:expCod')
                },
                {
                    colName: 'expGoodType',    //库存需要的字段名
                    needName: '货物类型',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'请输入：普货|特货、普|特',
                    primision:this.hasPermission('importExcel:expGoodType')
                },
                {
                    colName: 'productVolumeStr',    //库存需要的字段名
                    needName: '货物体积',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark: '长宽高，如：2*3*1，‘*’分割，单位cm', 
                    primision:this.hasPermission('importExcel:productVolumeStr')
                },
                {
                    colName: 'orderCode',    //库存需要的字段名
                    needName: '店铺单号',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:orderCode')
                },
                {
                    colName: 'innerExpCode',    //库存需要的字段名
                    needName: '快递单号',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:innerExpCode')
                },
                {
                    colName: 'shelfCode',    //库存需要的字段名
                    needName: '架位号',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:shelfCode')
                },
                {
                    colName: 'detailShelfCode',    //库存需要的字段名
                    needName: '货物架位号',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:detailShelfCode')
                },
                {
                    colName: 'orderBagCode',    //库存需要的字段名
                    needName: '包号',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:orderBagCode')
                },
                {
                    colName: 'expPkgCode',    //库存需要的字段名
                    needName: '袋号',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:expPkgCode')
                },
                {
                    colName: 'weightConsign',    //库存需要的字段名
                    needName: '重量',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:weightConsign')
                },
                {
                    colName: 'weightCustPkg',    //库存需要的字段名
                    needName: '包重',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:weightCustPkg')
                },
                {
                    colName: 'weightPkg',    //库存需要的字段名
                    needName: '袋重',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:weightPkg')
                },
                {
                    colName: 'expFlight',    //库存需要的字段名
                    needName: '班次',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:expFlight')
                },
                {
                    colName: 'expZhuanYun',    //库存需要的字段名
                    needName: '转运信息',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:expZhuanYun')
                },
                {
                    colName: 'expDaiYan',    //库存需要的字段名
                    needName: '待验信息',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:expDaiYan')
                },
                {
                    colName: 'expQingGuan',    //库存需要的字段名
                    needName: '清关信息',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:expQingGuan')
                },
                {
                    colName: 'expTiHuo',    //库存需要的字段名
                    needName: '提货信息',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:expTiHuo')
                },
                {
                    colName: 'remark1',    //库存需要的字段名
                    needName: '报单备注',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:remark1')
                },
                {
                    colName: 'remark2',    //库存需要的字段名
                    needName: '货单备注',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:remark2')
                },
                {
                    colName: 'remark3',    //库存需要的字段名
                    needName: '流程备注',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:remark3')
                },
                {
                    colName: 'createrName',    //库存需要的字段名
                    needName: '客户账号',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:createrName')
                },
                {
                    colName: 'orderPlatform',    //库存需要的字段名
                    needName: '订单卖场',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:orderPlatform')
                },
                {
                    colName: 'inStoreCode',    //库存需要的字段名
                    needName: '入仓码',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:inStoreCode')
                },
                {
                    colName: 'amtInNeed',    //库存需要的字段名
                    needName: '回款金额',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:amtInNeed')
                },
                {
                    colName: 'amtLipei',    //库存需要的字段名
                    needName: '理赔金额',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'台币',
                    primision:this.hasPermission('importExcel:amtLipei')
                },
                {
                    colName: 'amtKesu',    //库存需要的字段名
                    needName: '客诉件金额',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'台币',
                    primision:this.hasPermission('importExcel:amtLipei')
                },
                {
                    colName: 'amtOther',    //库存需要的字段名
                    needName: '附加费用',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'人民币',
                    primision:this.hasPermission('importExcel:amtOther')
                },
                {
                    colName: 'amtOther1',    //库存需要的字段名
                    needName: '附加费用1',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'台币',
                    primision:this.hasPermission('importExcel:amtOther1')
                },
                {
                    colName: 'finance1',    //库存需要的字段名
                    needName: '财务备注1',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:finance1')
                },
                {
                    colName: 'finance2',    //库存需要的字段名
                    needName: '财务备注2',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:finance2')
                },
                {
                    colName: 'finance3',    //库存需要的字段名
                    needName: '财务备注3',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:finance3')
                },
                {
                    colName: 'buyLink',    //库存需要的字段名
                    needName: '采购链接',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:shelfCode')
                },
                {
                    colName: 'declareCode',    //库存需要的字段名
                    needName: '申报单号',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:shelfCode')
                },
                {
                    colName: 'declareUser',    //库存需要的字段名
                    needName: '申报人名',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:shelfCode')
                },
                {
                    colName: 'declareTel',    //库存需要的字段名
                    needName: '申报电话',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:shelfCode')
                },
                {
                    colName: 'declareCard',    //库存需要的字段名
                    needName: '申报证号',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:shelfCode')
                },
                {
                    colName: 'declareAddress',    //库存需要的字段名
                    needName: '申报地址',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:shelfCode')
                },
                {
                    colName: 'declareQty',    //库存需要的字段名
                    needName: '申报数量',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:shelfCode')
                },
                {
                    colName: 'declarePrice',    //库存需要的字段名
                    needName: '申报单价',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:shelfCode')
                },
                {
                    colName: 'isAfterTax',    //库存需要的字段名
                    needName: '是否包税',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:shelfCode')
                },
                {
                    colName: 'weightGross',    //库存需要的字段名
                    needName: '毛重',   //库存需要的字段显示名
                    targetName: '',  //excel的表头名
                    remark:'',
                    primision:this.hasPermission('importExcel:shelfCode')
                },
            ]
            this.colNames.splice(0,this.colNames.length);
            newCol.forEach(col => {
                if(col.primision){
                    this.colNames.push(col);
                }
            })
        },
        doSelectFile(){  //点击选择文件
            this.$refs.fileArea.innerHTML = "";
            var input = document.createElement("input");
            input.type="file";
            var _this = this;
            input.onchange=this.getUploadFile;    
            this.$refs.fileArea.appendChild(input);
            input.click();
        },
        getUploadFile(e) {  //解析上传的excel
            let that = this;

            this.excelData = null
            //拿到所导入文件的名字
            let fileName = e.target.files[0];
            //定义reader，存放文件读取方法
            let reader = new FileReader();
            //启动函数
            reader.readAsBinaryString(fileName)
            //onload在文件被读取时自动触发
            reader.onload = (e) => {
                //workbook存放excel的所有基本信息
                let workbook = XLSX.read(e.target.result, {type: 'binary'})
                //定义sheetList中存放excel表格的sheet表，就是最下方的tab
                let sheetList = workbook.SheetNames
                //存放json数组格式的表格数据
                let worksheet = workbook.Sheets[workbook.SheetNames[0]];
                let json = XLSX.utils.sheet_to_json(worksheet, {header:1})

                that.clearSelect();
                var heads = json[0];
                that.excelData = json.splice(1);
                that.excelHeads = heads;
                for(var t=0;t<heads.length;t++){
                    var title = heads[t];
                    if(!that.importTemplate && title){
                        var col = null;
                        if(title == '代收金额'){
                            col = that.colNames.find(c => c.needName == '价格');
                            if(col){
                                that.$refs.colTable.toggleRowSelection(col);
                                col.targetName = '代收金额';
                            }
                        }else{
                            col = that.colNames.find(c => c.needName == title);
                            if(col){
                                that.$refs.colTable.toggleRowSelection(col);
                                col.targetName = title;
                            }
                        }
                    }

                }
                that.$refs.fileArea.innerHTML = "";
            };
        },
        selectChanged(configId){  //选择模板

            try{
                var tableRef = this.$refs.colTable;
                tableRef.clearSelection();
                
                if(!configId){
                    return;
                }
                var template = this.templates.find(t => t.id == configId)
                var config = JSON.parse(template.config); 

                var table = tableRef.data;

                this.colNames.forEach(col => {

                    var cfg = config.find(c => c.colName == col.colName);
                    if(cfg){
                        tableRef.toggleRowSelection(col, true);
                        col.targetName = cfg.targetName; 
                    }else{
                        tableRef.toggleRowSelection(col, false);
                        col.targetName = null;
                    }

                });

            }catch(err){
                console.error(err);
            }

        },
        changeStrategy(){
            if(this.strategy != 3){
                this.orderOrigin = null;
            }
        },
        selectHeadChanged(headIndex, row, rowIndex){
            try{
                if(rowIndex>-1){
                    var tableRef = this.$refs.colTable;
                    if(row.targetName){
                        var col = this.colNames[rowIndex];
                        tableRef.toggleRowSelection(col, true);
                    }else{
                        tableRef.toggleRowSelection(col, false);
                    }
                }
            }catch(err){
                console.error(err);
            }
        },
        deleteTemplate(e, item){
            e.stopPropagation();

            var _this = this;

            this.$alert(this.msg('确定要删除模板吗？'), {
                confirmButtonText: this.msg('确定'),
                callback: action => {
                    _this.$axios.get(_this.$kit.api.stock.configDelete+'?id='+item.id)
                        .then((result) => {
                            if(result.status){
                                _this.getTemplates();
                            }
                        });
                }
            });

        },
        doDownloadTpl(){  //下载一个excel模板，
            var selectes = this.$refs.colTable.selection;
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            var selectesTitles = selectes.map(c => c.targetName || c.needName);
            var titles = [selectesTitles, ['','','','','','']];
 
            var filename = this.importTemplate? this.templates.find(t => t.id == this.importTemplate).name+".xlsx" : '模板.xlsx';

            var wb = XLSX.utils.book_new();
            var ws = XLSX.utils.aoa_to_sheet(titles);

            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, filename);
        },
        doDownloadDefaultTpl(){//下载默认模板，发货快模板
            window.open(this.$kit.api.tplDownload,"_blank")
        },
        getTemplates(){
            this.$axios.get(this.$kit.api.stock.configList)
                .then((result) => {
                    if(result.status){
                        this.templates = result.data;
                    }
                });
        },
        doUpload(){

            //构建数据
            var selectes = this.$refs.colTable.selection;
            if(selectes.length==0){
                this.$message(this.msg('至少选择一个字段'));
                return;
            }

            if(!this.excelData || this.excelData.length == 0){
                this.$message(this.msg('请上传一个内容不为空的excel文件'));
                return;
            }

            if(this.strategy && this.strategy == 3){
                if(!this.orderOrigin){
                    this.$message(this.msg('请选择订单来源'));
                    return;
                }   
            }

            if(!this.strategy){
                this.$message(this.msg('请选择导入类型'));
                return;
            }

            var cols = [];
            var datas = [];
            for(let j =0; j<this.excelData.length; j++){
                let row = this.excelData[j];

                var line = [];

                if(row.length == 0){
                    continue;
                }

                for(let i = 0; i<this.excelHeads.length; i++){
                    let title = this.excelHeads[i];
                    if(title){
                        let col = selectes.find(s => s.targetName == title);

                        if(col){
                            if(j==0){
                                cols.push(col.colName);
                            }
                            let cell =  row[i];
                            if(!cell && cell !== 0){
                                cell = null;
                            }
                            line.push(cell);
                        }
                    }
                  
                }

                datas.push(line);
            }
            if(cols.length <= 0 && datas.length <= 0){
                this.$message(this.msg('无数据'));
                return;
            }

            let notNull_dp = true;//店配必填
            let notNull_xx = true;//线下必填
            let notNull_hk = true;//海快必填
            let noImport = true;// 店配不能导黑猫和新竹
            let errExpcom = true;//出货资料，配送方式必须能识别到
            let shopCode = true;//导入线下，7-11、全家、OK、萊爾富必须要有门市编号
            let telLength = true;//导入线下，7-11、全家、OK、萊爾富时，电话号码限制为10位纯数字；其它为10-12位的纯数字
            let notUpdate = true;//导入更新，不能更新国内快递单号和货物架位号
            let transportOk = true;//运输方式

            let code = cols.indexOf('code');//订单编号
            let productName = cols.indexOf('productName');//品名
            let shopName = cols.indexOf('shopName');//店铺名
            let expCompany = cols.indexOf('expCompany');//配送方式
            let transport = cols.indexOf('transport');//运输方式
            let receiver = cols.indexOf('receiver');//收件人
            let receiverTel = cols.indexOf('receiverTel');//收件人电话
            let receiverAddr = cols.indexOf('receiverAddr');//收件人地址
            let productPrice = cols.indexOf('productPrice');//价格
            let receiverShopCode = cols.indexOf('receiverShopCode');//收件门市
            let isAfterTax = cols.indexOf('isAfterTax');//是否包税
            let declareQty = cols.indexOf('declareQty');//申报数量
            let declarePrice = cols.indexOf('declarePrice');//申报单价
            let declareCard = cols.indexOf('declareCard');//申报证号
            let declareUser = cols.indexOf('declareUser');//申报人名
            let declareTel = cols.indexOf('declareTel');//申报电话
            let declareAddress = cols.indexOf('declareAddress');//申报地址

            let reg = new RegExp("^[0-9]*$");

            if(this.orderOrigin == 1){//店配
                //必填项验证
                if(code == -1 || productName == -1 || shopName == -1 || expCompany == -1){
                    notNull_dp = false;
                }
            }
            if(this.orderOrigin == 2){//线下
                //必填项验证
                if(code == -1 || receiver == -1 || receiverTel == -1
                    || receiverAddr == -1 || expCompany == -1 || productName == -1
                    || productPrice == -1){
                    notNull_xx = false;
                }
            }
            if(this.strategy == 6){
                //必填项验证
                if(code == -1 || receiver == -1 || receiverTel == -1
                    || receiverAddr == -1 || expCompany == -1 || productName == -1
                    || transport == -1 || isAfterTax == -1
                    || declareQty == -1 || declarePrice == -1 || declareCard == -1
                    || declareUser == -1 || declareTel == -1 || declareAddress == -1
                    ){
                    notNull_hk = false;
                }
            }
            for (let index = 0; index < datas.length; index++) {
                const e = datas[index];
                let company =  e[expCompany]+"";
                if(this.orderOrigin == 1){
                    //店配必填 
                    if((!e[code] || !e[productName] || !e[shopName] || !company)){
                        notNull_dp = false;
                        break;
                    }
                    // 店配不能导黑猫和新竹
                    if((company.indexOf('卖家宅配') > -1 
                        || company.indexOf('賣家宅配') > -1 || company.indexOf('新竹') > -1
                        )){
                        noImport = false;
                        break;
                    }
                }
                if(this.orderOrigin == 2){
                    //线下必填 
                    if((!e[code] || !e[receiver] || !e[receiverTel]
                        || !company || !e[productName])){
                        notNull_xx = false; 
                        break;
                    }
                    if(!e[productPrice]){
                        if(e[productPrice] !== 0){
                            notNull_xx = false; 
                            break;
                        }
                    }
                    if(company.indexOf('全家') == -1
                        && company.indexOf('7-11') == -1 && company.indexOf('711') == -1 && company.indexOf('7_11') == -1 && company.indexOf('7-ELEVEN') == -1
                        && company.indexOf('ok') == -1 && company.indexOf('OK') == -1
                        && company.indexOf('萊爾') == -1 && company.indexOf('莱尔') == -1
                        ){
                            //四大超商，导入线下，地址可以为空，其他配送方式不能为空
                            if(!e[receiverAddr]){
                                notNull_xx = false; 
                                break;
                            }
                        }
                }
                //新增海快，必填项
                if(this.strategy == 6){
                    if(!e[code] || !e[receiver] || !e[receiverTel]
                        || !company || !e[productName] || !e[receiverAddr]
                        || !e[transport] || !e[isAfterTax]
                        || !e[declareQty] || !e[declarePrice] || !e[declareCard]
                        || !e[declareUser] || !e[declareTel] || !e[declareAddress]
                        ){
                        notNull_hk = false; 
                        break;
                    }
                }
                //出货资料，配送方式必须能识别到
                if(this.strategy == 3 || this.strategy == 6){
                    //  if((company.indexOf('黑猫') == -1 && company.indexOf('黑貓') == -1 
                    //      && company.indexOf('卖家宅配') == -1 && company.indexOf('賣家宅配') == -1 
                    //      && company.indexOf('虾皮宅配') == -1 && company.indexOf('蝦皮宅配') == -1 
                    //      && company.indexOf('7-11') == -1 && company.indexOf('711') == -1 && company.indexOf('7_11') == -1 && company.indexOf('7-ELEVEN') == -1
                    //      && company.indexOf('ok') == -1 && company.indexOf('OK') == -1
                    //      && company.indexOf('萊爾') == -1 && company.indexOf('莱尔') == -1
                    //      && company.indexOf('全家') == -1 
                    //      && company.indexOf('新竹') == -1
                    //      && company.indexOf('大荣') == -1
                    //      && company.indexOf('聚鼎') == -1
                    //      && company.indexOf('海快') == -1
                    //      && company.indexOf('海快虾宅') == -1
                    //      && company.indexOf('海快蝦宅') == -1
                    //      && company.indexOf('东风') == -1
                    //      && company.indexOf('露天拍卖') == -1
                    //      && company.indexOf('无配送') == -1 && company.indexOf('無配送') == -1
                    //      && company.indexOf('自提') == -1
                    //      && company.indexOf('黑貓宅急便') == -1 && company.indexOf('黑猫宅急便') == -1 && company.indexOf('宅急便') == -1
                    //      && company.indexOf('蝦皮店到店') == -1 && company.indexOf('店到店') == -1 
                    //      && company.indexOf('菲律宾') == -1 && company.indexOf('J&TExpres') == -1 
                    //      && company.indexOf('店到家宅配') == -1 && company.indexOf('香港件') == -1 
                    //      && company.indexOf('宅配通') == -1 
                    //      && company.indexOf('嘉里快递') == -1  && company.indexOf('嘉里快遞') == -1 
                    //      && company.indexOf('配送方式') == -1 
                    //      )){
                    //      errExpcom = false;
                    //      break;
                    //  }
                    let hasCom = -1;
                    this.expCompanys.forEach(e => {
                       if(company == e.label || company == '配送方式'){
                           hasCom = 1;
                       }
                    });
                    if(hasCom == -1){
                       errExpcom = false;
                       break;
                    }
                }
                //导入线下，7-11、全家、OK、萊爾富必须要有门市编号
                //导入线下，7-11、全家、OK、萊爾富时，电话号码限制为10位纯数字；其它为10-12位的纯数字
                if(this.orderOrigin == 2){
                    if( company.indexOf('全家') > -1
                        || (company.indexOf('7') > -1 && company.indexOf('11') > -1)
                        || company.indexOf('ok') > -1 || company.indexOf('OK') > -1
                        || company.indexOf('萊爾') > -1 || company.indexOf('莱尔') > -1
                        ){
                            if(receiverShopCode == -1){
                                shopCode = false;
                            }else{
                                if(!e[receiverShopCode]){
                                    shopCode = false;
                                }else{
                                    if((e[receiverShopCode]+'').trim().length <= 0){
                                        shopCode = false;
                                    }
                                }
                            }

                            if(receiverTel == -1){
                                telLength = false;
                            }else{
                                if(!e[receiverTel] || (e[receiverTel] != '寄件人电话' && (!reg.test((e[receiverTel]+'').trim()) || (e[receiverTel]+'').trim().length != 10))){
                                    telLength = false;
                                }
                            }

                        }else{
                            if(receiverTel == -1){
                                telLength = false;
                            }else{
                                if(!e[receiverTel] || (e[receiverTel] != '寄件人电话' && (!reg.test((e[receiverTel]+'').trim()) || (e[receiverTel]+'').trim().length < 10 || (e[receiverTel]+'').trim().length > 12))){
                                    telLength = false;
                                }
                            }
                        }
                }
                //导入更新，不能更新国内快递单号和货物架位号
                if(this.strategy == 1 && (cols.indexOf('innerExpCode') > -1 || cols.indexOf('detailShelfCode') > -1)){
                    notUpdate = false;
                }
                let transport1 = e[transport];
                if(transport1){
                    if(transport1.indexOf('海快') == -1 && transport1.indexOf('空运') == -1 && transport1.indexOf('新航线') == -1){
                        transportOk= false;
                    }
                }
            }
            if(this.orderOrigin == 1 && !notNull_dp){
                this.$message('导入店配，订单编号、品名、店铺名、配送方式不能为空');
                return;
            }else if(this.orderOrigin == 2 && !notNull_xx){
                this.$message('导入线下，订单编号、收件人、收件人电话、收件人地址、配送方式、品名不能为空,价格不能小于0');
                return;
            }
            if(!notNull_hk){
                this.$message('新增海快，订单编号、收件人、收件人电话、收件人地址、配送方式、品名、代收金额、运输方式、是否包税、申报数量、申报单价、申报证号、申报人名、申报电话、申报地址不能为空,价格不能小于0');
                return;
            }
            if(!noImport){
                this.$message(this.msg('资料中含线下订单，请删除后重新导入'));
                return;
            }
            if(!errExpcom){
                this.$message(this.msg('配送方式格式不正确，请修改后重新导入'));
                return;
            }
            if(!shopCode){
                this.$message(this.msg('收件信息不完善，门市编号必须准确！'));
                return;
            }
            if(!telLength){
                this.$message(this.msg('电话格式长度不正确'));
                return;
            }
            if(!notUpdate){
                this.$message(this.msg('不能导入更新货物号和货物架位号'));
                return;
            }
            if(!transportOk){
                this.$message(this.msg('运输方式不正确，请修改后重新导入'));
                return;
            }

            this.$axios.post(this.$kit.api.stock.doImport+"?t="+this.strategy+"&store="+(this.storeId?this.storeId:'')+"&origin="+(this.orderOrigin?this.orderOrigin:''), 
                {cols: cols, datas: datas})
                .then((result) => {
                    if(result && result.msg){
                        this.$message(result.msg);
                    }
                    if(result.status){
                        this.results = result.data;
                    }
                });
        },
        saveTemplate(){ 
            var _this = this;

            var selectes = this.$refs.colTable.selection;
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据在保存'));
                return;
            }

            var config = selectes.map(e =>  {
                    return {
                        colName: e.colName,
                        targetName: e.targetName
                    }
                })
            
            config = JSON.stringify(config);

            _this.$prompt(_this.msg('请输入模板名'), _this.msg('提示'), {
                confirmButtonText: _this.msg('确定'),
                cancelButtonText: _this.msg('取消'),
            }).then(({ value }) => {
                _this.axios.post(_this.$kit.api.stock.configAdd, {name: value, config: config})
                    .then( (result) => {
                        // _this.$message(result.msg);
                        if(result.status){
                            _this.getTemplates();
                        }
                    }).catch(function (error) {
                        console.error(error);
                    });
            }).catch(() => {
                _this.$message({
                    type: 'info',
                    message: _this.msg('取消更新')
                });       
            });  
        },
        //清空选中模板
        clearSelect(){
            var tableRef = this.$refs.colTable;
            tableRef.clearSelection();
            this.colNames.forEach(e => e.targetName = null)
        },
    }
}
</script>
<style lang="scss" scoped>

</style>