<!-- 预览详情--查看库存 -->
<template>
<div style="margin-top:-20px">
    <el-form ref="form" :model="stockDetail" label-width="80px" style="margin:0 20px">
        <el-row>
            <el-col :xs="24" :sm="12" :lg="12">
                <el-form-item :label="msg('标题:')">
                    <span>{{stockDetail.productTitle}}</span>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :xs="24" :sm="12" :lg="12">
                <el-form-item :label="msg('规格:')">
                    <span>{{stockDetail.productSpec}}</span>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="8" :lg="8">
                <el-form-item :label="msg('件数:')">
                    <span>{{stockDetail.productQty}}</span>
                </el-form-item>
            </el-col>
        </el-row>
        <!-- <div style="text-align:center; margin-top: 15px;">
            <el-button  size="mini" type="danger" v-on:click="$emit('close-dg')">{{msg('关闭')}}</el-button>
        </div> -->
    </el-form>
    <div class="table-wrap">
      <vxe-grid ref="dataTable" 
          style="width: 100%;"
          max-height="500"
          resizable border
          show-overflow highlight-hover-row
          highlight-current-row highlight-hover-column highlight-current-column
          :data="stockDetails"
          :columns="columns"
        ></vxe-grid>
    </div>
</div>
</template>

<script>
export default {
    name: 'storeEdit',
    data(){
        return {
            stockDetail:{
                id:null,
                productTitle:'',
                productSpec:'',
                productQty:0,
            },
            query:{
                length: 10000,
                start: 0,
                pageIndex:1,
                pageTotal: 0,
                productTitle:null,
                productSpec:null,
                type:1
            },
            stockDetails:[],
            columns:[],
        }
    },
    props:{
        detailId: Number
    },
    methods:{
        init(){
            this.$axios.post(this.$kit.api.stockDetail.getDetail+'?detailId='+this.detailId)
                .then((result) => {
                    if(result.status){
                        let detail = result.data;
                        this.stockDetail = detail;
                        this.stockDetail.id = this.detailId;
                        this.buildColumns();
                        this.list();
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        list(){
            this.$axios.post(this.$kit.api.stockDetail.getDetailList, this.stockDetail)
                .then((result) => {
                    console.log(result);
                    if(result.status){
                        this.stockDetails = result.data;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        buildColumns(){
            this.columns = [
                {type: 'checkbox', minWidth: 40},
                {field: 'id', title: this.msg('ID'), minWidth: 60, sortable: true},
                {field: 'storeType', title: this.msg('仓储类型'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                    slots: {
                            default: ({ row }) => {
                                let type = this.dicts.stockStoreType.find(d => d.value === row.storeType);
                                return [
                                    <el-tag type={type?type.type:''}>{type?type.label:row.storeType}</el-tag>
                                ];
                            },
                        }
                },
                {field: 'orderCode', title: this.msg('货单号'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'inStoreCode', title: this.msg('入仓码'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'specCode', title: this.msg('库存编号'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'stockShelfCode', title: this.msg('架位号'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'productQty', title: this.msg('件数'), minWidth: 40, sortable: true,
                    filters:[{data: {type: 'has', isCase: false, name: ''}}],
                    filterRender:{name: 'FilterComplex'}
                },
                {field: 'expExpireDate', title: this.msg('保管期限'), minWidth: 120, sortable: true,
                    filters:[{data: []}],
                    filterRender:{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                },
                {field: 'ownerName', title: this.msg('归属人'), minWidth: 80, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'stockStatus', title: this.msg('仓储状态'), minWidth: 60, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: 'storeName', title: this.msg('所在仓库'), minWidth: 80, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
            ]
        },
    }
}
</script>
<style lang="scss" scoped>

</style>