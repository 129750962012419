<!--预览详情新增-->
<template>
    <el-form ref="form" :model="stockDetail" label-width="100px" style="margin:0 20px">
        <el-row>
            <el-col :xs="24" :sm="24">
                <el-form-item :label="msg('商品标题')">
                    <el-input v-model.trim="stockDetail.productTitle" ></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :xs="24" :sm="24">
                <el-form-item :label="msg('商品规格')">
                    <el-input v-model.trim="stockDetail.productSpec" ></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :xs="24" :sm="12">
                <el-form-item :label="msg('数量')">
                    <el-input-number v-model.number="stockDetail.productQty" :min="1"></el-input-number>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12">
                <el-form-item :label="msg('价格(元)')">
                    <el-input type="number" v-model="stockDetail.productPrice" ></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <div style="text-align:center; margin-top: 15px;">
            <el-tag type="danger">添加详情不会更新货态，如有需要请自行检查货态是否正确；</el-tag>
        </div>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            <el-button  size="mini" @click="close()">{{msg('取消')}}</el-button>
        </div>
    </el-form>
</template>

<script>
export default {
    name: 'storeEdit',
    data(){
        return {
            stockDetail:{
                id:null,
                productTitle:null,
                productSpec:null,
                productQty:1,
                productPrice:0,
                stockId:0,
            },
        }
    },
    props:{
        id: Number
    },
    methods:{
        init(){
            this.stockDetail = {
                id:null,
                productTitle:null,
                productSpec:null,
                productQty:1,
                productPrice:0,
                stockId:this.id,
            }
        },
        onSubmit(){
            var _this = this;
            this.stockDetail.productPrice = this.stockDetail.productPrice*100;
            this.$axios.post(this.$kit.api.stockDetail.createDetail, this.stockDetail)
                .then(function(result){
                    let detailId = result.data;
                    _this.stockDetail.id = detailId;
                    if(result.status){
                        _this.stockDetail.productPrice = _this.stockDetail.productPrice/100;
                        _this.$emit("close-dg", {stockDetail: _this.stockDetail, type: true});
                        _this.$message(result.msg || _this.msg("添加成功"));
                    }else{
                        _this.$message(result.msg || _this.msg("添加失败"));
                    }
                });
        },
        close(){
            this.stockDetail.productPrice = this.stockDetail.productPrice/100;
            this.$emit("close-dg", {stockDetail: this.stockDetail, type: false});
        }
    }
}
</script>
<style lang="scss" scoped>

</style>