<!-- 详情修改-->
<template>
<div class="content-panel">
    <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
        <el-col :span="20">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('库存管理')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{msg('详情修改')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
    </el-row>
    <el-form ref="form" label-width="100px" style="margin-top:15px">
        <el-row>
            <el-col :xs="24" :sm="10">
                <el-form-item :label="msg('标题')">
                    <el-input v-model.trim="productTitle" :placeholder="msg('请输入标题')" size="medium ">
                        <el-button slot="append" icon="el-icon-circle-check" @click="titlePush">填充</el-button>
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="10">
                <el-form-item :label="msg('规格')">
                    <el-input v-model.trim="productSpec" :placeholder="msg('请输入规格')" size="medium ">
                        <el-button slot="append" icon="el-icon-circle-check" @click="specPush">填充</el-button>
                    </el-input>
                </el-form-item>
            </el-col>
            <!-- <el-col :xs="24" :sm="4">
                <div style="text-align:left;margin-left:20px;margin-top:2px;">
                    <el-button type="primary" size="small"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('提交')}}</span></el-button>
                </div>
            </el-col> -->
        </el-row>
        <el-row>
            <el-col :xs="24" :sm="10">
                <el-form-item :label="msg('SKU')">
                    <el-input v-model.trim="sku" :placeholder="msg('请输入SKU')" size="medium ">
                        <el-button slot="append" icon="el-icon-circle-check" @click="skuPush">填充</el-button>
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="10">
                <el-form-item :label="msg('货号')">
                    <el-input v-model.trim="variationSku" :placeholder="msg('请输入货号')" size="medium ">
                        <el-button slot="append" icon="el-icon-circle-check" @click="variationSkuPush">填充</el-button>
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4">
                <div style="text-align:left;margin-left:20px;margin-top:2px;">
                    <el-button type="primary" size="small"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('提交')}}</span></el-button>
                </div>
            </el-col>
        </el-row>
    </el-form>
    <div class="table-wrap">
        <vxe-grid ref="dataTable" :height="docHeight" 
            export-config
            resizable border="full"
            size="mini"
            show-overflow highlight-hover-row
            highlight-current-row highlight-hover-column highlight-current-column
            :data="datas"
            :columns="columns"
            @cell-click="checkRow"
        ></vxe-grid>
    </div>
</div>
</template>

<script>
import XEUtils from 'xe-utils';
export default {
    name: "StockDetailEdit",
    components: {
    },
    mounted(){
        this.init();
    },
    data(){
        return {
            datas: [],
            columns: [],
            ids:null,
            productTitle:null,
            productSpec:null,
            sku:null,
            variationSku:null,
        }
    },
    computed: {
        isMinScreen: { //是否小屏幕，浏览器
            get() {
                return this.$store.state.isMinScreen;
            }
        },
        docHeight: { //表格的高度
            get() {
                var height = this.$store.state.docHeight;
                return height - 165;
            }
        }
    },
    methods:{
        init(){
            this.ids = window.localStorage.getItem("detailEditIds");
            this.buildColumns();
            this.getData();
            this.productTitle = null
            this.productSpec = null
            this.sku = null
            this.variationSku = null
        },
        buildColumns(){
            this.columns = [
                {type: 'checkbox', minWidth: 40},
                {field: 'id', title: this.msg('ID'), minWidth: 60, sortable: true},
                {field: 'code', title: this.msg('货单号'), minWidth: 80, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'product_title', title: this.msg('原标题'), minWidth: 220, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'newTitle', title: this.msg('新标题'), minWidth: 220,
                    slots: {
                        // 使用渲染函数
                        default: ({ row }) => {
                            return [
                                <span class="id-cell"><i class="title-edit el-icon-edit"></i>&nbsp;{row.newTitle}</span>
                            ]
                        }
                    }
                },
                {field: 'product_spec', title: this.msg('原规格'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'newSpec', title: this.msg('新规格'), minWidth: 120,
                    slots: {
                        // 使用渲染函数
                        default: ({ row }) => {
                            return [
                                <span class="id-cell"><i class="spec-edit el-icon-edit"></i>&nbsp;{row.newSpec}</span>
                            ]
                        }
                    }
                },
                {field: 'sku', title: this.msg('原SKU'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'newSku', title: this.msg('新SKU'), minWidth: 120,
                    slots: {
                        // 使用渲染函数
                        default: ({ row }) => {
                            return [
                                <span class="id-cell"><i class="sku-edit el-icon-edit"></i>&nbsp;{row.newSku}</span>
                            ]
                        }
                    }
                },
                {field: 'variation_sku', title: this.msg('原货号'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'variation_sku', title: this.msg('新货号'), minWidth: 120,
                    slots: {
                        // 使用渲染函数
                        default: ({ row }) => {
                            return [
                                <span class="id-cell"><i class="vasku-edit el-icon-edit"></i>&nbsp;{row.newVariationSku}</span>
                            ]
                        }
                    }
                },
                {field: 'product_qty', title: this.msg('件数'), minWidth: 80, sortable: true,
                    filters:[{data: {type: 'has', isCase: false, name: ''}}],
                    filterRender:{name: 'FilterComplex'}
                },
            ]
        },
        getData(){
            this.$axios.post(this.$kit.api.stock.getDetailEditList, this.ids)
                .then((result) => {
                    if(result.status){
                        let ds = result.data;
                        this.datas = ds;
                    }
                });
        },
        //点击行，选中行
        checkRow({row, rowIndex, $event, column, columnIndex}){
            //如果点击checkbox 则不处理
            // console.log(columnIndex);
            let className = $event.target.className;
           
            if(columnIndex > 0){  //选择 
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }

            if(columnIndex == 4 ){
                if(className.indexOf('title-edit')>-1){
                    this.editTitle(row);
                }
            }else if(columnIndex == 6 ){//
                if(className.indexOf('spec-edit')>-1){
                    this.editSpec(row);
                }
            }else if(columnIndex == 8 ){//
                if(className.indexOf('sku-edit')>-1){
                    this.editSku(row);
                }
            }else if(columnIndex == 10 ){//
                if(className.indexOf('vasku-edit')>-1){
                    this.editVariationSku(row);
                }
            }
        },
        editTitle(row){
            this.$prompt('请输入标题', '提示', {
                closeOnClickModal:false,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                if(!value){
                    this.$message(this.msg('请输入标题'));
                    return;
                }
                row.newTitle = value;
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });       
            });
        },
        editSpec(row){
            this.$prompt('请输入规格', '提示', {
                closeOnClickModal:false,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                if(!value){
                    this.$message(this.msg('请输入规格'));
                    return;
                }
                row.newSpec = value;
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });       
            });
        },
        editSku(row){
            this.$prompt('请输入SKU', '提示', {
                closeOnClickModal:false,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                // if(!value){
                //     this.$message(this.msg('请输入SKU'));
                //     return;
                // }
                row.newSku = value;
                this.$refs.dataTable.loadData(this.datas);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });       
            });
        },
        editVariationSku(row){
            this.$prompt('请输入货号', '提示', {
                closeOnClickModal:false,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                // if(!value){
                //     this.$message(this.msg('请输入SKU'));
                //     return;
                // }
                row.newVariationSku = value;
                this.$refs.dataTable.loadData(this.datas);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });       
            });
        },
        titlePush(){//标题填充
            if(!this.productTitle){
                this.$message(this.msg('请输入需要填充的标题'));
                return;
            }
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length > 0){
                selectes.forEach(e => {
                    e.newTitle = this.productTitle;
                })
            }else{
                this.datas.forEach(e => {
                    e.newTitle = this.productTitle;
                })
            }
            this.productTitle = null;
        },
        specPush(){//规格填充
            if(!this.productSpec){
                this.$message(this.msg('请输入需要填充的规格'));
                return;
            }
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length > 0){
                selectes.forEach(e => {
                    e.newSpec = this.productSpec;
                })
            }else{
                this.datas.forEach(e => {
                    e.newSpec = this.productSpec;
                })
            }
            this.productSpec = null;
        },
        skuPush(){//SKU填充
            // if(!this.sku){
            //     this.$message(this.msg('请输入需要填充的SKU'));
            //     return;
            // }
            let sku = !this.sku?'':this.sku;
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length > 0){
                selectes.forEach(e => {
                    e.newSku = sku;
                    // if(sku){
                    //     e.newSku = sku;
                    // }else{
                    //     e.newSku = null;
                    // }
                })
            }else{
                this.datas.forEach(e => {
                    e.newSku = sku;
                    // if(sku){
                    //     e.newSku = sku;
                    // }else{
                    //     e.newSku = null;
                    // }
                })
            }
            this.$refs.dataTable.loadData(this.datas);
            this.sku = null;
        },
        variationSkuPush(){//货号填充
            // if(!this.variationSku){
            //     this.$message(this.msg('请输入需要填充的货号'));
            //     return;
            // }
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length > 0){
                selectes.forEach(e => {
                    e.newVariationSku = this.variationSku;
                })
            }else{
                this.datas.forEach(e => {
                    e.newVariationSku = this.variationSku;
                })
            }
            this.$refs.dataTable.loadData(this.datas);
            this.variationSku = null;
        },
        onSubmit(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();

            this.$confirm('如不勾选则默认提交全部数据，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let param = [];
                if(selectes && selectes.length > 0){
                    selectes.forEach(e => {
                        let detail = {
                            id:e.id,
                            productTitle:e.newTitle,
                            productSpec:e.newSpec,
                            sku:e.newSku,
                            variationSku:e.newVariationSku
                        }
                        param.push(detail);
                    })
                }else{
                    this.datas.forEach(e =>{
                        let detail1 = {
                            id:e.id,
                            productTitle:e.newTitle,
                            productSpec:e.newSpec,
                            sku:e.newSku,
                            variationSku:e.newVariationSku
                        }
                        param.push(detail1);
                    })
                }
                this.$axios.post(this.$kit.api.stock.updateDetail, param)
                .then((result) => {
                    if(result.status){
                        if(selectes.length > 0){
                            //删除选中的数据
                            selectes.forEach(e => {
                                this.datas = this.datas.filter(w => w.id != e.id);
                            })
                        }else{
                            //清空列表
                            this.datas = [];
                        }
                        this.productTitle = null;
                        this.productSpec = null;
                        this.sku = null;
                        this.variationSku = null;
                        this.$message(result.msg || this.msg('更新成功'));
                    }else{
                        this.$message(result.msg || this.msg('更新失败'));
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
            
        },
    }
}
</script>

<style lang="scss" scoped>

.table-wrap{
    width:100%;
    overflow:auto;
}
.title-edit{cursor:pointer; display:inline-block;}
.spec-edit{cursor:pointer; display:inline-block;}
</style>
