import {_axios as axios} from  "../../plugins/axios.js";
import kit from "../../plugins/kit.js"

import { Message, MessageBox  } from "element-ui";
import i18nKit from "../../plugins/I18N.js"
import Auth from "../../plugins/auth.js"

let api = kit.api;
let i18n = i18nKit.methods;
let auth = Auth.methods;

/**
 * 获取详情数据list
 * @param {*} query StockDetailListQuery 查询条件
 * @param {*} resultCall 如果有数据就会回调这个函数
 * @param {*} loadingChange 是否加载中的回调函数
 */
const getData = function(query, resultCall, loadingChange){

    if(loadingChange){
        loadingChange(true)
    }
    // console.log(_this.ids)

    axios.post(api.stockDetail.listByStock, 
        query
    ).then((result) => {
        if(loadingChange){
            loadingChange(false)
        }
        Message(result.msg);
        
        if(result.status){ 
            let ds = result.data.list;
            let total = result.data.total;
            ds.forEach(d => {
                d.details.forEach(dd => {
                    d['checked'] = dd.innerExpCode && dd.shelfCode;
                    if(!dd.storeId){
                        dd.storeId = 0;
                    }
                });
            });
            var resultList = [];
            for (let i = 0; i < ds.length; i++) {
                var newObj = {};
                newObj = deepCloneMore(ds[i], null);
                if (newObj.details != null && newObj.details.length > 0) {
                    for (let j = 0; j < newObj.details.length; j++) {
                        if (j == 0) {
                            newObj.detail = newObj.details[j];
                            newObj.detailIndex = 0;
                            resultList.push(newObj);
                        } else {
                            var tmp = {};
                            tmp = deepCloneMore(ds[i], null);
                            tmp.detail = newObj.details[j];
                            tmp.detailIndex = j;
                            resultList.push(tmp);
                        }
                    }
                } else {
                    newObj.detail = {};
                    resultList.push(newObj);
                }
            }
            if(resultCall){
                resultCall(resultList, total)
            }
        }
    }).catch(err => {
        if(loadingChange){
            loadingChange(false)
        }
    });
}

/**
 * 合并单元格
 * @param {*} dataTableRef this.$refs.dataTable 要合并的表格
 * @param {*} mergeFields : eg: mergeFields: ['code'],  //要合并的字段
 * @returns 
 */
const mergeRow = function(dataTableRef, mergeFields){
    var data = dataTableRef.getTableData();
    var visibleData = data.tableData;

    //页面刚刚初始化完成，无法获取到visiableData,所有默认去总数据里面取10条
    if (visibleData.length <= 1) {
        visibleData = data.visibleData.slice(0, 10);
    }
    //开始计算合并的位置
    var offset = dataTableRef.getRowIndex(visibleData[0]);

    const fields = mergeFields;
    var mergeCellResult = [];
    if (fields.length == 0) {
        return mergeCellResult;
    }
    var allColumns = dataTableRef.getColumns();
    var columnNames = allColumns.map(x => x.property);
    for (var i = 0; i < fields.length; i++) {
        var property = fields[i];
        var rowIndex = 0;
        var colIndexes = findAllOccurrences(columnNames, property);
        if (colIndexes.length <= 0) {
            continue;
        }
        while (rowIndex < visibleData.length) {
            var row = visibleData[rowIndex];
            const cellValue = row[property];
            var rowspan = 1;
            var startIndex = rowIndex;
            if (cellValue != undefined && cellValue != null) {
                let nextRow = visibleData[rowIndex + 1];
                while (nextRow && nextRow[property] == row[property]) {
                    rowIndex++;
                    nextRow = visibleData[rowIndex + 1];
                    rowspan++;
                }
            }
            if (rowspan > 1) {
                for (var p = 0; p < colIndexes.length; p++) {
                    mergeCellResult.push({row: offset + startIndex, col: colIndexes[p], rowspan: rowspan});
                }
                rowspan = 1;
            }
            rowIndex++;
        }
    }
    return mergeCellResult;
}

 /**
  * 根据列名，查找所有的索引。
  * @param {*} arr 
  * @param {*} item 
  * @returns 
  */
const findAllOccurrences = function(arr, item){
    var subArr = [];
    for(var i = 0; i < arr.length; i++){
        if(arr[i] === item){
            subArr.push(i);
        }
    }
    return subArr;
}

/**
 * 深度复制对象
 * @param {*} obj 
 * @param {*} result 
 */
const deepCloneMore = function(obj, result){
    var oClass = isClass(obj)
    if (result == null) {
        if (oClass === 'Object') {
            result = {}
        } else if (oClass === 'Array') {
            result = []
        } else {
            return obj
        }
    }
    for (var key in obj) {
        if(result[key]!=null) {
            continue;
        }
        var copy = obj[key]
        if (isClass(copy) === 'Object') {
            result[key] = deepCloneMore(copy)// 递归调用
        } else if (isClass(copy) === 'Array') {
            result[key] = deepCloneMore(copy)
        } else {
            result[key] = obj[key]
        }
    }
    return result
}

/**
 * 判断是否对象
 * @param {Object} o 
 * @returns 
 */
const isClass = function(o){
    if (o === null) return 'Null'
    if (o === undefined) return 'Undefined'
    return Object.prototype.toString.call(o).slice(8, -1)
}

/**
 * 获取该用户的仓库
 */
const getStores = function(resultCall){
    axios.post(
        api.store.listUsed
    ).then((result)=> {
        let store = null;
        if(result.status){
            let data = result.data;
            data.splice(0, 0, {id: 0, name: '默认仓库'});
            store = data;
        }else{
            store = [
                {id: 0, name: '默认仓库'},
                // {id: 1, name: '默认仓库1'},
            ]
        }
        if(resultCall){
            resultCall(store)
        }
    });
}

/**
 * 更新保单备注，更新货单备注
 * @param {*} stock 
 * @param {*} type 1-报单备注, else-货单备注
 * @param {*} refreshList 
 * @returns 
 */
const updateRemark = function(stock, type, refreshList){
    if(!auth.hasPermission("stock:detailEdit")){
        Message(i18n.msg('无操作权限'));
        return;
    }

    let remark1 = stock.remark1;
    let remark2 = stock.remark2;

    let label = "";
    if(type == 1){
        label = '请输入报单备注（'+remark1+'）';
    }else{
        label = '请输入货单备注（'+remark2+'）';
    }

    MessageBox.prompt(label, i18n.msg('提示'), {
        confirmButtonText: i18n.msg('确定'),
        cancelButtonText: i18n.msg('取消'),
    }).then(({ value }) => {
        axios.post(
            api.stock.updateRemark
                +"?t="+type
                +"&id="+stock.id, value?value:''
        ).then((result) => {
            if(result.status){
                if(refreshList){
                    refreshList();
                }
                Message(result.msg);
            }else{
                Message(i18n.msg('已打单不能更新货单备注'));
            }
        });
    }).catch(() => {
        Message({
            type: 'info',
            message: '取消输入'
        });
    });
}

/**
 * 更新快递单号，更新架位号
 * @param {*} stock {id}
 * @param {*} stockDetail {id, storeId, innerExpCode, shelfCode}
 * @param {*} username 当前登录的用户名
 * @param {*} type 0-快递单号，1-货架号
 * @param {*} refreshList 刷新表格的回调
 * @returns 
 */
const updateCode = function(stock, stockDetail,username, type, refreshList){
    if(!auth.hasPermission("stock:detailEdit")){
        Message(i18n.msg('无操作权限'));
        return;
    }
    if(!stockDetail.storeId && stockDetail.storeId != 0){
        Message(i18n.msg('请选择仓库'));
        return;
    }
    axios.post(
        api.stock.getStockById+"?id="+stock.id
    ).then((result) => {
        let resultStock = result.data;
        if(resultStock.status == 20){
            Message("请先删除快递单号！");
            return;
        }
        if(resultStock.status >= 23 || resultStock.status == 10){
            Message("已打单和已入仓不能更新");
            return;
        }
        if(resultStock.status < 23 && resultStock.status != 10 || username != "admin"){
            let label = "";
            if(type === 0){
                label = i18n.msg('请输入快递单号')+"("+stockDetail.innerExpCode+")"
            }else{
                label = i18n.msg('请输入货架号')+"("+stockDetail.shelfCode+")"
            }

            MessageBox.prompt(label, i18n.msg('提示'), {
                closeOnClickModal:false,
                confirmButtonText: i18n.msg('确定'),
                cancelButtonText: i18n.msg('取消'),
            }).then(({ value }) => {
                axios.post(api.stockDetail.updateDetailCode
                    +"?t="+type
                    +"&id="+stock.id
                    +"&detailId="+stockDetail.id
                    +"&code="+(value?value:"")
                ).then((result) => {
                    // if(result.status){
                    //     }
                    if(refreshList){
                        refreshList();
                    }
                    Message(result.msg);
                });
            }).catch(() => {
                Message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        }else{
            Message("已打单和已入仓不能更新");
        }
    });
}

/**
 * 更新SKU, 更新货号
 * @param {*} stock {id}
 * @param {*} stockDetail {id, sku, variationSku} 
 * @param {*} type 0-SKU,1-货号
 * @param {*} refreshList 刷新表格的回调
 * @returns 
 */
const updateVariationSku = function(stock, stockDetail, type, refreshList){
    if(!auth.hasPermission("stock:updateSku")){
        Message(i18n.msg('没有权限或非超级会员'));
        return;
    }
    let label = "";
    if(type === 0){
        label = '请输入SKU'+"("+stockDetail.sku+")"
    }else{
        label = '请输入货号'+"("+stockDetail.variationSku+")"
    }
    MessageBox.prompt(label, i18n.msg('提示'), {
        closeOnClickModal:false,
        confirmButtonText: i18n.msg('确定'),
        cancelButtonText: i18n.msg('取消'),
    }).then(({ value }) => {
        axios.post(api.stockDetail.updateDetailVariationSku
            +"?detailId="+stockDetail.id
            +"&t="+type
            +"&id="+stock.id
            +"&sku="+(value?value:"")
        ).then((result) => {
            if(result.status && refreshList){
                refreshList();
            }
            Message(result.msg);
        });
    }).catch(() => {
        Message({
            type: 'info',
            message: '取消输入'
        });
    });
}

/**
 * 更新成本
 * @param {*} stockId 
 * @param {*} stockDetailId 
 * @param {*} cost 原先的成本
 * @param {*} type 1-成本
 * @param {*} refreshList 刷新表格的回调
 * @returns 
 */
const updateCost = function(stockId, stockDetailId, cost, type, refreshList){
    if(!auth.hasPermission("stock:updateSku")){
        Message(i18n.msg('没有权限或非超级会员'));
        return;
    }
    let label = '请输入成本(单位:元)'+"("+cost+")"
    MessageBox.prompt(label, i18n.msg('提示'), {
        closeOnClickModal:false,
        confirmButtonText: i18n.msg('确定'),
        cancelButtonText: i18n.msg('取消'),
    }).then(({ value }) => {
        var patten = /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g;
        if(value && !patten.test(value)){
            Message(i18n.msg('格式不正确，只能输入数字'));
            return;
        }
        if(value){
            value = (value*100).toFixed(0);
        }
        // let reg = new RegExp("^[0-9]*$");
        // if(value && !reg.test(value)){
        //     Message(this.msg('格式不正确，只能输入数字'));
        //     return;
        // }
        axios.post(api.stockDetail.updateDetailCost
            +"?detailId="+stockDetailId
            +"&id="+stockId
            +"&cost="+(value?value:null)
        ).then((result) => {
            Message(result.msg);
            if(result.status && refreshList){
                refreshList();
            }
        });
    }).catch(() => {
        Message({
            type: 'info',
            message: '取消输入'
        });
    });
}

/**
 * 选择到货仓库
 * @param {*} detailId 
 * @param {*} storeId 
 * @param {*} type 1-选择到货仓库
 * @param {*} refreshList 刷新表格的回调
 * @returns 
 */
const selectedStore = function(detailId, storeId, type, refreshList){
    if(!auth.hasPermission("stock:detailEdit")){
        Message(i18n.msg('无操作权限'));
        return;
    }
    axios.post(api.stockDetail.updateDetailStore
        +"?detailId="+detailId
        +"&storeId="+storeId
    ).then((result) => {
        if(result.status && refreshList){
            refreshList();
        }
        Message(result.msg);
    });
}

/**
 * 删除快递单号
 * @param {*} vue 
 * @param {*} rowIndex 
 * @param {*} detailIndex 
 * @returns 
 */
const deleteInnerCode = function(detailId, refreshList){
    if(!auth.hasPermission("stock:detailEdit")){
        Message(i18n.msg('无操作权限'));
        return;
    }
    MessageBox.confirm(i18n.msg('确定要清除快递单号和货架号吗？'), i18n.msg('提示'), {
        confirmButtonText: i18n.msg('确定'),
        cancelButtonText: i18n.msg('取消'),
        type: 'warning'
    }).then(() => {
        axios.get(
            api.stockDetail.deleteInnerCode+"?detailId="+detailId
        ).then((result) => {
            if(result.status){
                if(refreshList){
                    refreshList();
                }
                Message(result.msg || i18n.msg("清除成功"));
            }else{
                Message(result.msg || i18n.msg("清除失败"));
            }
        });
    }).catch((err) => {
        // console.log(err);
        Message({
            type: 'info',
            message: '取消'
        });
    });
}

/**
 * 点到
 * @param {*} vue 
 * @param {*} rowIndex 
 * @param {*} detailIndex 
 * @param {*} markVal 
 * @returns 
 */
const updateMark = function(stockId, stockDetailId, markVal, refreshList){
    if(!auth.hasPermission("stock:detailEdit")){
        Message(i18n.msg('无操作权限'));
        return;
    }
    let label = "";
    let mark = -1;
    if(markVal === 1){
        mark = 1;
        label = i18n.msg('确定要点到吗')
    }else{
        mark = 0;
        label = i18n.msg('确定要取消点到吗')
    }

    MessageBox.confirm(label, i18n.msg('点到确认'), {
        confirmButtonText: i18n.msg('确定'),
        cancelButtonText: i18n.msg('取消'),
    }).then(() => {
        axios.post(api.stockDetail.updateDetailMark
            +"?t="+mark
            +"&id="+stockId
            +"&detailId="+stockDetailId
        ).then((result) => {
            if(result.status && refreshList){
                refreshList();
            }
            Message(result.msg);
        });
    }).catch(() => {
        if(refreshList){
            refreshList();
        }
        Message({
            type: 'info',
            message: i18n.msg('取消'),
        });
    });
}

/**
 * 新增一条详情
 * @param {*} stockId 
 * @param {*} changeAddDetailVisiable function(visiable) 
 * @returns 
 */
const addDetail = function(stockId, changeAddDetailVisiable){
    if(!auth.hasPermission("stock:detailEdit")){
        Message(i18n.msg('无操作权限'));
        return;
    }
    axios.post(
        api.stock.selectStatusById+"?stockId="+stockId
    ).then((result) => {
        if(result.status){
            let data = result.data;
            if(data){
                if(data.status >= 13 && data.status <= 23 && data.status != 20){
                    changeAddDetailVisiable(true);
                }else{
                    if(auth.hasPermission("stock:detailAdd")){
                        changeAddDetailVisiable(true);
                    }else{
                        Message(i18n.msg('该订单不能新增'));
                    }
                }
            }else{
                Message(i18n.msg('无效数据'));
            }
        }
    });
}

/**
 * 删除详情
 * @param {*} vue 
 * @param {*} stock 
 * @param {*} stockDetail 
 */
const deleteDetail = function(stock,stockDetail, refreshList){
    if(!auth.hasPermission("stock:detailEdit")){
        Message(i18n.msg('无操作权限'));
        return;
    }
    // console.log(stock.details)
    if(stock.details.length <= 1){
        Message(i18n.msg("必须保留一条详情"));
        return;
    }
    MessageBox.confirm(i18n.msg('是否要删除?'), i18n.msg('提示'), {
        confirmButtonText: i18n.msg('确定'),
        cancelButtonText: i18n.msg('取消'),
        type: 'warning'
    }).then(() => {
        axios.post(
            api.stock.selectStatusById+"?stockId="+stock.id
        ).then((result) => {
            if(result.status){
                let data = result.data;
                if(data){
                    if(data.status >= 13 && data.status <= 23){
                        doDelete(stockDetail.id, refreshList);
                    }else{
                        if(auth.hasPermission("stock:detailAddDelete")){
                            doDelete(stockDetail.id, refreshList);
                        }else{
                            Message(i18n.msg('该订单不能删除'));
                        }
                    }
                }else{
                    Message(i18n.msg('无效数据'));
                }
            }
        });
    }).catch((err) => {
      // console.log(err);
    });
}

/**
 * 执行删除详情的请求
 */
const doDelete = function(detailId, refreshList){
    axios.post(
        api.stockDetail.deleteDetail+'?id='+detailId
    ).then((result)=>{
        if(result.status){
            if(refreshList){
                refreshList()
            }
            Message(result.msg || i18n.msg("删除成功"));
        }else{
           Message(result.msg || i18n.msg("删除失败"));
        }
    });
}

/**
 * 打开采购链接
 * @param {*} stock 
 * @param {*} stockDetail 
 * @param {*} callback 
 * @returns 
 */
const openBuyLink = function(stock, stockDetail, callback){
    if(!auth.hasPermission("stock:detailPurchase")){
        Message(i18n.msg('没有权限或非超级会员，不能进行快捷采购！'));
        return;
    }

    //根据详情标题，搜索采购链接表，查找对应链接
    //如果找到，则打开新链接
    //如果没找到，则提示输入链接
    axios.post(
        api.stockDetail.openBuyLink+"?id="+stock.id+"&detailId="+stockDetail.id,
        stockDetail
    ).then((result) => {
        if(result.status){
            window.open(result.data,"_blank")
            //弹出一个新页面
            if(callback){
                callback();
            }
        }else{
            MessageBox.alert(i18n.msg('未匹配到采购链接，请自行输入链接后再次采购'), i18n.msg('提示'), {
                confirmButtonText: i18n.msg('确定'),
            });
        }
    });
}

/**
 * 保存采购单号
 * @param {*} stockId 
 * @param {*} stockDetail 
 * @param {*} refreshList 
 * @returns 
 */
const savePurchaseCode = function(stockId, stockDetail, refreshList){
    if(!auth.hasPermission("stock:detailPurchase")){
        Message(i18n.msg('没有权限或非超级会员，不能进行快捷采购！'));
        return;
    }

    if(!stockDetail.purchaseCode){
        Message(i18n.msg('采购单号不存在'));
        return;
    }
    if(!stockDetail.purchasePlatform){
        Message(i18n.msg('请选择采购平台'));
        return;
    }

    let cost = 0;
    let resCost = stockDetail.cost;
    if(resCost){
        cost = (resCost*100).toFixed(0);
    }

    MessageBox.confirm(i18n.msg('保存采购单号？'), i18n.msg('提示'), 
    {
        confirmButtonText: i18n.msg('确定'),
        cancelButtonText: i18n.msg('取消'),
    }).then(() => {
        axios.post(api.stockDetail.updateDetailPurchaseCode
            +"?code="+stockDetail.purchaseCode
            +"&type="+stockDetail.purchasePlatform
            +"&created="+stockDetail.purchaseTime
            +"&cost="+cost
            +"&id="+stockId
            +"&detailId="+stockDetail.id
        ).then((result) => {
            if(result.status && refreshList){
                refreshList();
            }
            Message(result.msg);
        });
    }).catch(() => {
        if(refreshList){
            refreshList();
        }
        Message({
            type: 'info',
            message: i18n.msg('取消'),
        });
    });
}

/**
 * 撤销已采购单号
 * @param {*} stockId 
 * @param {*} stockDetailId 
 * @param {*} refreshList 
 */
const rebackPurchaseCode = function(stockId, stockDetailId, reefreshList){

    MessageBox.confirm(i18n.msg('撤销已采购单号？'), i18n.msg('提示'), {
        confirmButtonText: i18n.msg('确定'),
        cancelButtonText: i18n.msg('取消'),
    }).then(() => {
        axios.post(api.stockDetail.rebackDetailPurchaseCode
            +"?id="+stockId
            +"&detailId="+stockDetailId
        ).then((result) => {
            if(result.status){
                if(reefreshList){
                    reefreshList()
                }
                Message(result.msg);
            }else{
                Message(i18n.msg('请先删除快递单号'));
            }
        });
    }).catch(() => {
        if(reefreshList){
            reefreshList()
        }
        Message({
            type: 'info',
            message: i18n.msg('取消'),
        });
    });
}

/**
 * 图片上传的回调函数
 * 图片上传成功后将id设置到详情中
 * @param {*} result 
 * @param {*} rowIndex 
 * @param {*} detailIndex 
 */
const uploadProductPhotoCallback = function(
    res,
    detailId,
    callback
){
    if(res.status){
        //保存图片ID
        axios.post(
            api.stock.detailThumb+'?detailId='+detailId+'&thumbId='+res.data
        ).then((result) => {
            if(result.status){
                if(callback){
                    callback()
                }
                Message("上传成功");
            }else{
                Message(i18n.msg('上传失败'));
            }
        });
    }else{
        Message("上传失败");
    }
}

const OrderService = {
    getData: getData,
    mergeRow: mergeRow,
    getStores: getStores,
    updateRemark: updateRemark,
    updateCode: updateCode,
    updateVariationSku: updateVariationSku,
    updateCost: updateCost,
    selectedStore:selectedStore,
    deleteInnerCode:  deleteInnerCode,
    updateMark: updateMark,
    addDetail: addDetail,
    deleteDetail: deleteDetail,
    openBuyLink: openBuyLink,
    savePurchaseCode:savePurchaseCode,
    rebackPurchaseCode:rebackPurchaseCode,
    uploadProductPhotoCallback: uploadProductPhotoCallback
}


export default OrderService