<!-- 预览详情-->
<template>
    <div class="content-panel">
        <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
            <el-col :span="20">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('库存管理')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{msg('预览详情')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
          </el-row>
        <div class="table-wrap">
            <vxe-grid class="data-table" ref="dataTable" :height="docHeight"
                resizable border
                align="center"
                :data="datas"
                :loading="loading"
                :mergeCells="mergeCells"
                @scroll="scrollCallback"
            >
                <vxe-column field="code" min-width="100">
                    <template #header>
                      {{msg('基本资料')}}
                    </template>
                    <template #default="{ row, rowIndex }">
                        <div className="code">{{row.code}}</div>
                        <div className="shopName">{{row.shopName}}</div>
                        <div className="receiver">{{row.receiver}}</div>
                        <div className="expCompany">{{row.expCompany}}</div>
                        <div className="payTime">{{row.shopPayTime}}</div>
                        <div className="created">{{row.created}}</div>
                    </template>
                </vxe-column>
                <vxe-column field="productThumb" min-width="120">
                    <template #header>
                        {{msg('商品图')}}
                    </template>
                    <template #default="{ row, rowIndex }">
                        <el-image
                            style="width: 120px; height: 120px" :src="imgUrl+row.detail.productThumb"
                            :preview-src-list="[imgUrl+row.detail.productThumb]"
                            fit="contain">
                        </el-image>
                        <el-upload
                            class="xm-upload"
                            :action="upload"
                            :show-file-list="false"
                            :with-credentials="true"
                            :on-success="(res) => handUploadSuccess(res, rowIndex, row.detailIndex)"
                        >
                            <el-button type="success" size="small" title="上传图片" icon="el-icon-picture" circle></el-button>
                        </el-upload>
                    </template>
                </vxe-column>
                <vxe-column field="productTitle" min-width="100">
                    <template #header>
                        {{msg('商品标题')}}
                    </template>
                    <template #default="{ row, rowIndex }">
                        <el-link :href="detailId+'?id='+row.detail.id" target="_blank">{{row.detail.productTitle}}</el-link>
                    </template>
                </vxe-column>
                <vxe-column min-width="120">
                    <template #header>
                      {{msg('规格/SKU/货号')}}
                    </template>
                    <template #default="{ row, rowIndex }">
                        <span>{{row.detail.productSpec}}</span>
                        <br/>
                        <span style="color:#409eff;">{{row.detail.sku}}</span>
                        <el-link :underline="false" type="primary" @click="updateVariationSku(rowIndex, row.detailIndex,0)" title="更新SKU">
                            <i class="el-icon-edit-outline"></i>
                        </el-link>
                        <br/>
                        <span style="color:#409eff;">{{row.detail.variationSku}}</span>
                        <el-link :underline="false" type="danger" @click="updateVariationSku(rowIndex, row.detailIndex,1)" title="更新货号">
                            <i class="el-icon-edit"></i>
                        </el-link>
                    </template>
                </vxe-column>
                <vxe-column min-width="70">
                    <template #header>
                        {{msg('数量/价格/成本')}}
                    </template>
                    <template #default="{ row, rowIndex }">
                        <div class="spec"><span>{{row.detail.productQty}}</span>件</div>
                        <div>${{row.detail.productPrice || 0}}</div>
                        <el-link type="primary" @click="checkStore(rowIndex, row.detailIndex)">查看库存</el-link>
                        <br/>
                        <span style="color:#409eff;">{{row.detail.cost}}</span>
                        <el-link :underline="false" type="danger" @click="updateCost(rowIndex, row.detailIndex,1)" title="更新成本">
                            <i class="el-icon-edit"></i>
                        </el-link>
                    </template>
                </vxe-column>
                <vxe-column min-width="70">
                    <template #header>
                        {{msg('点到/操作')}}
                    </template>
                    <template #default="{ row, rowIndex }">
                        <el-checkbox v-model="row.detail.mark"
                            @change="(val) => updateMark(rowIndex, row.detailIndex, val)"
                            :true-label="1"
                            :false-label="0">
                        </el-checkbox>
                        {{msg('点到')}}
                        <br/>
                        <div style="margin-top:5px;margin-bottom: -10px;">
                            <el-button type="success" size="mini" title="新增" @click="addDetail(row)" icon="el-icon-circle-plus-outline">新增</el-button>
                        </div>
                        <br/>
                        <el-button type="danger" size="mini" title="删除" @click="deleteDetail(row,row.detail)" icon="el-icon-delete">删除</el-button>
                    </template>
                </vxe-column>
                <vxe-column min-width="160">
                    <template #header>
                        {{msg('货物号/架位号')}}
                    </template>
                    <template #default="{ row, rowIndex }">
                        <el-select v-model="row.detail.storeId"  placeholder="选择到货仓库" size="mini"
                            @change="(val)=>selectedStore(rowIndex, row.detailIndex,0,val)"
                        >
                            <el-option
                                v-for="item in stores"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                        <span>{{row.detail.upperDate}}</span>
                        <br/>
                        {{row.detail.innerExpCode}}
                        <el-link :underline="false" type="primary" @click="updateCode(rowIndex, row.detailIndex,0)" title="更新快递单号">
                            <i class="el-icon-edit-outline"></i>
                        </el-link>
                        <div style="margin-top:5px;">{{row.detail.shelfCode}}</div>
                        <el-link v-if="hasPermission('stock:updateDetailShelfCode')" :underline="false" type="danger" @click="updateCode(rowIndex, row.detailIndex,1)"
                                title="更新架位号">
                            <i class="el-icon-edit"></i>
                        </el-link>
                        <br/>
                        <el-link :underline="false" type="danger" @click="deleteInnerCode(rowIndex, row.detailIndex)">
                            <i class="el-icon-delete"></i>
                        </el-link>
                        <br/>
                        <el-link style="margin-top:8px;word-break: break-all;" type="danger" disabled>{{msg('提示：需要分包的快递请在后面加好备注说明')}}</el-link>
                    </template>
                </vxe-column>
                <vxe-column min-width="160">
                    <template #header>
                        {{msg('采批')}}
                    </template>
                    <template #default="{ row, rowIndex }">
                        <el-tag :type="row.detail.purchaseStatus==1?'success':'danger'">{{row.detail.purchaseStatus==1?'已采购':'未采购'}}</el-tag>
                        <div style="margin-top:10px" v-if="row.detail.purchaseStatus!=1">
                            <el-input size="mini" placeholder="输入采购链接" v-model="row.detail.purchaseLink">
                                <el-button slot="prepend" size="mini" icon="el-icon-shopping-cart-full" @click="openBuyLink(rowIndex, row.detailIndex)">{{msg('采购')}}</el-button>
                            </el-input>
                        </div>
                        <div style="margin-top:10px" v-if="row.detail.purchaseStatus==1">
                            <span>{{row.detail.purchaseTime}}</span>
                        </div>
                        <div style="margin-top:10px">
                            <el-input size="mini" placeholder="采购单号" v-model="row.detail.purchaseCode">
                            </el-input>
                        </div>
                        <div style="margin-top:10px">
                            <el-select v-model="row.detail.purchasePlatform" placeholder="平台" size="mini">
                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div style="margin-top:10px">
                            <el-button size="mini" type="success" @click="savePurchaseCode(rowIndex, row.detailIndex)">{{msg('保存')}}</el-button>
                            <el-button v-if="row.detail.purchaseStatus==1" size="mini" type="danger" @click="rebackPurchaseCode(rowIndex, row.detailIndex)">{{msg('撤销')}}</el-button>
                        </div>
                    </template>
                </vxe-column>
                <vxe-column field="code" min-width="80">
                    <template #header>
                        {{msg('总额/状态/货态')}}
                    </template>
                    <template #default="{ row, rowIndex }">
                        <div class="amtOrder">${{row.amtOrder || 0}}</div>
                        <el-tag type="danger">{{row.track || msg('准备出货')}}</el-tag>
                        <br />
                        <el-tag style="margin: 5px 0px;" type="success">
                            {{dicts.stockStatus.find(e => e.value === row.status).label || row.status}}
                        </el-tag>
                        <div class="shipDate">{{row.shopDayToShip}}</div>
                    </template>
                </vxe-column>
                <vxe-column field="code" min-width="80">
                    <template #header>
                        {{msg('买家备注/卖家备注')}}
                    </template>
                    <template #default="{ row, rowIndex }">
                        <span>{{row.shopSellerMsg}}</span>
                        <br/>
                        <span>{{row.note}}</span>
                    </template>
                </vxe-column>
                <vxe-column field="code" min-width="80">
                    <template #header>
                        {{msg('报单备注/货单备注')}}
                    </template>
                    <template #default="{ row, rowIndex }">
                        <div>{{row.remark1}}</div>
                        <el-link :underline="false" type="primary" @click="updateRemark(rowIndex,1)" title="更新报单备注">
                            <i class="el-icon-edit-outline"></i>
                        </el-link>
                        <div style="margin-top:5px;">{{row.remark2}}</div>
                        <el-link :underline="false" type="danger" @click="updateRemark(rowIndex, 2)" title="更新货单备注">
                            <i class="el-icon-edit"></i>
                        </el-link>
                    </template>
                </vxe-column>
            </vxe-grid>
        </div>
        <el-dialog :title="msg('新增')" :visible.sync="addDetailVisiable" :fullscreen="isMinScreen"  @opened="openAddDg"
                :close-on-click-modal="false"
                width="600px">
            <stock-detail-add ref="addDetailDg" :id="stockId"  @close-dg="closeAddDg"/>
        </el-dialog>
        <el-dialog :title="msg('查看库存')" :visible.sync="storeVisiable" :fullscreen="isMinScreen"  @opened="openStoreDg"
                :close-on-click-modal="false"
                width="950px">
            <stock-detail-check ref="storeDg" :detailId="checkId"  @close-dg="closeStoreDg"/>
        </el-dialog>
        <el-dialog :title="msg('采购单号')" :visible.sync="purchaseVisiable" :fullscreen="isMinScreen"  @opened="openPurchasDg"
                :close-on-click-modal="false"
                width="900px">
            <stock-detail-purchase ref="purchasDg" :idx="pruchaseId" :idx1="pruchaseId1"  @close-dg="closePurchasDg"/>
        </el-dialog>
    </div>
</template>

<script>
import StockDetailAdd from "./StockDetailAdd.vue";
import StockDetailCheck from "./StockDetailCheck.vue";
import StockDetailPurchase from "./StockDetailPurchase.vue";
import OrderService from '../order/OrderService';
export default {
    name: "StockDetail",
    components: {
        StockDetailAdd:StockDetailAdd,
        StockDetailCheck:StockDetailCheck,
        StockDetailPurchase:StockDetailPurchase
    },
    mounted(){
        this.user  = JSON.parse(localStorage.getItem("user"));
        this.init();
    },
    data(){
        return {
            loading: false,
            datas: [],
            mergeFields: ['code'],
            imgUrl: this.$kit.api.imgView,
            detailId: this.$kit.api.shopeeDetail1,
            ids: null,
            user:null,
            currIdx: null, //当前操作的单号
            detailIdx: null,
            addDetailVisiable:false,
            stockId:null,
            storeVisiable:false,
            checkId:null,//查看库存的详情ID
            upload: this.$kit.api.upload+"?type=stock/thumb&auth=1",
            stores:[],
            purchaseVisiable:false,
            pruchaseId:null,
            pruchaseId1:null,
            options:[
                {label:'淘宝',value:'taobao'},
                {label:'1688',value:'alibaba'},
                {label:'拼多多',value:'pdd'},
            ],
            mergeCells: []
        }
    },
    computed: {
        isMinScreen: { //是否小屏幕，浏览器
            get() {
                return this.$store.state.isMinScreen;
            }
        },
        docHeight: { //表格的高度
            get() {
                var height = this.$store.state.docHeight;
                return height - 165;
            }
        }
    },
    methods:{
        init(){
            this.ids = window.localStorage.getItem("viewDetailIds");
            this.getStores();
            this.getData();
        },
        mergeRow() {
            this.mergeCells = [];
            this.mergeCells = OrderService.mergeRow(
                this.$refs.dataTable, 
                this.mergeFields
            );
        },
        scrollCallback(scroll, event) {
            if (scroll.type != 'body' || !scroll.isY) {
                return;
            }
            this.mergeRow();
        },
        changeShelfCode(idx, idx1){
            // this.currIdx = idx;
            // this.detailIdx = idx1;

            // let data = this.datas[idx];
            // let detail = data.details[idx1];

            // let msg = "";
            // let type = -1;
            // if(detail.checked){
            //     msg = this.msg('确定需要点到吗');
            //     type = 2;
            // }else{
            //     type = 1;
            //     msg = this.msg('确定需要取消点到吗');
            // }

            // this.$prompt(msg, this.msg('提示'), {
            //     confirmButtonText: this.msg('确定'),
            //     cancelButtonText: this.msg('取消'),
            // }).then(({ value }) => {
            //     this.updateCode(data.id, detail.id, type);
            // }).catch(() => {
            //     this.$message({
            //         type: 'info',
            //         message: '取消输入'
            //     });
            //     data.checked = true;
            // });

        },
        getData(){
            var idArr = this.ids.split(",");
            let query =  {ids:idArr.map(d => d*1)};
            
            let _this = this;
            OrderService.getData(
                query, 
                (resultData, total) => { 
                    _this.datas = resultData;
                    _this.$nextTick(() => {
                        _this.mergeRow();
                    });
                },
                isLoad => {
                    _this.loading = isLoad;
                }
            );
        },
        getStores(){
            OrderService.getStores((resultData) => {
                this.stores = resultData;
            });
        },
        //更新保单备注，更新货单备注
        updateRemark(rowIndex, type){
            OrderService.updateRemark(
                this.datas[rowIndex],
                type,
                this.getData
            );
        },
        //更新快递单号，更新架位号
        updateCode(rowIndex, detailIndex, type){
            OrderService.updateCode(
                this.datas[rowIndex],
                this.datas[rowIndex].details[detailIndex],
                this.user.username,
                type,
                this.getData               
            );
        },
        //更新SKU, 更新货号
        updateVariationSku(rowIndex, detailIndex, type){
            OrderService.updateVariationSku(
                this.datas[rowIndex],
                this.datas[rowIndex].details[detailIndex],
                type,
                this.getData
            );
        },
        //更新成本
        updateCost(rowIndex, detailIndex, type){
            let stock = this.datas[rowIndex];
            let stockDetail = stock.details[detailIndex];
            OrderService.updateCost(
                stock.id,
                stockDetail.id,
                stockDetail.cost,
                type,
                this.getData
            );
        },
        //选择到货仓库
        selectedStore(rowIndex, detailIndex, type, storeId){
            OrderService.selectedStore(
                this.datas[rowIndex].details[detailIndex].id,
                storeId, 
                type,
                this.getData 
            );
        },
        //删除快递单号
        deleteInnerCode(rowIndex,detailIndex){
            OrderService.deleteInnerCode(
                this.datas[rowIndex].details[detailIndex].id,
                this.getData
            );
          
        },
        //点到
        updateMark(rowIndex, detailIndex, markVal){
            let stock = this.datas[rowIndex];
            let stockDetail = stock.details[detailIndex];
            OrderService.updateMark(
                stock.id,
                stockDetail.id,
                markVal,
                this.getData
            );
        },
        //新增一条详情
        addDetail(stock){
            this.stockId = stock.id;
            OrderService.addDetail(
                this.stockId,
                visiable => {
                    this.addDetailVisiable = visiable;
                }
            )
        },
        openAddDg(){
            this.$nextTick(() => {
                this.$refs.addDetailDg.init();
            });
        },
        openPurchasDg(){
            this.$nextTick(() => {
                this.$refs.purchasDg.init();
            });
        },
        closePurchasDg(data){
            console.log(data);
            this.purchaseVisiable = false;
            let idx = data.idx;
            let idx1 = data.idx1;
            let data1 = this.datas[idx];
            data1.details[idx1].purchaseCode = data.code;
            data1.details[idx1].purchaseTime = data.created;
            data1.details[idx1].purchasePlatform = data.type;
            data1.details[idx1].cost = data.price;
            this.savePurchaseCode(idx,idx1);
        },
        openStoreDg(){
            this.$nextTick(() => {
                this.$refs.storeDg.init();
            });
        },
        closeAddDg(data){
          let type = data.type;
          let detail = data.stockDetail;
          if(type){
              this.getData();
          }
          this.addDetailVisiable = false;
        },
        deleteDetail(stock,stockDetail){//删除详情
            OrderService.deleteDetail(stock, stockDetail, this.getData);
        },
        //打开采购链接
        openBuyLink(rowIndex, detailIndex){
            let stock = this.datas[rowIndex];
            let stockDetail = stock.details[detailIndex];
            OrderService.openBuyLink(
                stock,
                stockDetail,
                () => {
                    this.purchaseVisiable = true;
                    this.pruchaseId = rowIndex;
                    this.pruchaseId1 = detailIndex;
                }
            );
        },
        //保存采购单号
        savePurchaseCode(rowIndex, detailIndex){
            let stock = this.datas[rowIndex];
            let stockDetail = stock.detail;
            // console.log(stockDetail)
            OrderService.savePurchaseCode(
                stock.id,
                stockDetail,
                this.getData
            );
        },
        //撤销已采购单号
        rebackPurchaseCode(rowIndex, detailIndex){
            let stock = this.datas[rowIndex];
            let stockDetail = stock.details[detailIndex];
            OrderService.rebackPurchaseCode(
                stock.id,
                stockDetail.id,
                this.getData
            );
        },
        updateReport(idx, idx1, val){//报单
            // if(!this.hasPermission("stock:detailEdit")){
            //     this.$message(this.msg('无操作权限'));
            //     return;
            // }
            // let data = this.datas[idx];
            // let detail = data.details[idx1];
            // let label = "";
            // let mark = -1;
            // if(val === 1){
            //     mark = 1;
            //     label = this.msg('确定要报单吗')
            // }else{
            //     mark = 0;
            //     label = this.msg('确定要取消报单吗')
            // }

            // this.$confirm(label, this.msg('报单确认'), {
            //     confirmButtonText: this.msg('确定'),
            //     cancelButtonText: this.msg('取消'),
            // }).then(() => {
            //     this.$axios.post(this.$kit.api.stock.updateDetailReport
            //         +"?t="+mark
            //         +"&id="+data.id
            //         +"&detailId="+detail.id
            //     ).then((result) => {
            //         if(result.status){
            //             this.getData();
            //         }
            //         this.$message(result.msg);
            //     });
            // }).catch(() => {
            //     this.getData();
            //     this.$message({
            //         type: 'info',
            //         message: this.msg('取消'),
            //     });
            // });
        },
        checkStore(idx, idx1){
            let data = this.datas[idx];
            let detail = data.details[idx1];
            this.checkId = detail.id;
            this.storeVisiable = true;
        },
        closeStoreDg(){
            this.storeVisiable = false;
        },
      // beforeUpload(thumbId){
      //     //删除文件
      //     if(thumbId && thumbId > 0){
      //         this.$axios.get(this.$kit.api.attachment.delete+'?ids='+thumbId+'&type=product')
      //             .then((result) => {
      //                 if(result.status){
      //                     // console.log("删除原图片");
      //                 }else{
      //                     // console.log("删除原图片失败");
      //                 }
      //             });
      //     }
      // },
        //图片上传的回调函数
        //图片上传成功后将id设置到详情中
        handUploadSuccess(result, rowIndex, detailIndex){
            let stock = this.datas[rowIndex];
            let detail = stock.details[detailIndex];
            OrderService.uploadProductPhotoCallback(
                result,
                detail.id,
                ()=>{
                    detail.productThumb = result.data;
                    stock.detail.productThumb = result.data;
                    // this.datas[rowIndex].details[detailIndex].productThumb = res.data;
                    // this.datas[rowIndex].detail.productThumb = res.data;
                }    
            );
        },
    }
}
</script>

<style lang="scss" scoped>

.data-table{
  min-width:1200px;
  width:99%;
  border-collapse: collapse;
  font-size:12px;
  border-top: 1px solid #bec5d6;
  border-left: 1px solid #bec5d6;
}

.data-table th{font-size:14px; text-align:center;  color: #5f6267;}
.data-table th, .data-table td{
  border-bottom: 1px solid #bec5d6;
  border-right: 1px solid #bec5d6;
  padding: 5px;
}

.data-table td{
  font-size:12px; color:#606266; text-align:center;
}

.data-table .shopName{
  color: #409eff;
}

.data-table .payTime,
.data-table .created,
.data-table .amtOrder
.data-table .shipDate{
  color:#909399;
}
.data-table .spec span,
.data-table .track{
  color:#F56C6C;
}

.table-wrap{
  width:100%;
  overflow:auto;
}

@media screen and (min-width: 1200px) {
  body {
    overflow:auto;
  }
}


.th1 {width: 100px;}
.th2 {width: 120px;}
.th3 {width: 120px;}
.th4 {width: 120px; word-break: break-all;}
.th5 {width: 70px;}
.th6 {width: 70px;}
.th7 {width: 60px; word-break: break-all;}
.th8 {width: 160px; word-break: break-all;}
.th9 {width: 160px; word-break: break-all;}
.th10 {width: 80px; word-break: break-all;}
.th11 {width: 80px; word-break: break-all;}
.th12 {width: 80px; word-break: break-all;}

.data-table::v-deep(.vxe-table--render-default .vxe-body--column.col--ellipsis>.vxe-cell,
.vxe-table--render-default .vxe-footer--column.col--ellipsis>.vxe-cell,
.vxe-table--render-default .vxe-header--column.col--ellipsis>.vxe-cell){
  max-height:none;
}
.data-table::v-deep(.vxe-table--render-default .vxe-body--column.col--ellipsis>.vxe-cell){
  white-space: normal;
}
</style>
