<!--出仓入仓-->
<template>
    <div class="inout-box">
        <el-row class="row-wrap" type="flex">
            <!-- 
                <el-popover 
                    v-model="storeVisible"
                    placement="bottom"
                    trigger="click">
                    <ul class="store-list">
                        <li class="store-item" v-for="store in stores" 
                            :key="store.key" @click="selectedStore(store)">{{store.name}}</li>
                    </ul>
                    <el-link slot="reference" :underline="false">
                        <span style="white-space: nowrap;display:inline-block; margin-right:4px;">仓库<i class="el-icon-arrow-down"></i></span>{{store? store.name : ""}}
                    </el-link>
                </el-popover> 
            -->

            <el-checkbox-group class="ctrl" v-model="ctrls" style="margin-left:15px;" :fill="'#67C23A'"  @change="selectCtrl">
                <el-checkbox v-for="(ctrl, idx) in ctrlOptions" :key="idx"  
                        :disabled="ctrl.disable" :label="ctrl.value"  border 
                        size="mini" v-show="hasPermission(ctrl.permission)">
                    {{ctrl.label}}
                    <span :class="arrowIcon" v-if="ctrl.value == 0 || ctrl.value == 'o'"
                        style="display:inline-block; margin-left:8px;"
                        v-show="ctrls.indexOf(0)>-1 || ctrls.indexOf('o')>-1"
                        @click.prevent.stop="changeSettingVisible('settingEcVisiable', 'arrowIcon', !settingEcVisiable)"
                    ></span>
                    <span :class="settingPrintTicketArrowIcon" v-if="ctrl.value == 4 || ctrl.value == 'm' || ctrl.value == 'n'"
                        style="display:inline-block; margin-left:8px;"
                        v-show="ctrls.indexOf(4)>-1 || ctrls.indexOf('m')>-1 || ctrls.indexOf('n')>-1"
                        @click.prevent.stop="changeSettingVisible('settingPrintTicketVisible', 'settingPrintTicketArrowIcon', !settingPrintTicketVisible)"
                    ></span>
                    <span :class="autoFillPkgCodeArrowIcon" v-if="ctrl.value == 2"
                        style="display:inline-block; margin-left:8px;"
                        v-show="ctrls.indexOf(2)>-1"
                        @click.prevent.stop="changeSettingVisible('autoFillPkgCodeVisible','autoFillPkgCodeArrowIcon',!autoFillPkgCodeVisible)"
                    ></span>
                </el-checkbox>
            </el-checkbox-group>
        </el-row>
        <transition name="el-zoom-in-top">
            <div v-if="ctrls.indexOf(0) > -1 || ctrls.indexOf('o') > -1" style="padding:10px 15px;" v-show="settingEcVisiable">
                <h5 style="margin:5px; padding:0px;">{{msg("电子秤连接")}}</h5>
                <el-row >
                    <el-col :xs="12" :sm="12" :md="5" style="padding: 10px 5px"> 
                        <el-select v-model="comConfigIdx" @change="selectEcConfig" 
                                    :placeholder="msg('选择配置')" class="query-select"  size="mini">
                                <el-option v-for="(cfg, idx) in ecConfigs" :key="idx"
                                    :label="cfg.label" :value="idx" 
                                ></el-option>
                        </el-select>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="5" style="padding: 10px 5px">
                        <el-input v-model="comPort" :placeholder="msg('COM端口')" size="mini"></el-input>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="8" style="padding: 10px 5px">
                        <el-input v-model="comExpress" :placeholder="msg('取值表达式')" size="mini"></el-input>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="6" style="padding: 10px 5px"> 
                        <el-button type="success" @click="connectCOM" size="mini">{{"连接电子秤"}}</el-button>
                        <el-button type="danger" @click="disconnectCOM"  size="mini" plain>{{"断开连接"}}</el-button>
                    </el-col>
                    <el-col :xs="24">
                        <el-alert :title="msg('新用户请开启浏览器端口')"
                            description="chrome://flags/#enable-experimental-web-platform-features" 
                            type="warning">
                        </el-alert>
                    </el-col>
                </el-row>
            </div>
        </transition>

        <transition name="el-zoom-in-top">
            <div v-if="ctrls.indexOf(2) > -1" style="padding:10px 15px;" v-show="autoFillPkgCodeVisible">
                <h4 style="margin:5px; padding:0px; color:666666; color:16px;">{{msg("自动填充袋号设置")}} 
                    <el-switch
                        v-model="enableAutoFillInputPkgCode"
                        :active-text="msg('规则开启')" :inactive-text="msg('规则禁用')"
                        :active-value="true" :inactive-value="false">
                    </el-switch>
                </h4>

                <el-row v-show="enableAutoFillInputPkgCode">
                    <el-col :xs="24" style="padding-left: 15px">
                        <span style="font-size:14px; color:#666666; margin-right:10px;">{{msg('袋号规则定义')}}</span>
                        <el-tag v-for="(tag, idx) in pkgRules" :key="idx"
                            :type="tag.type == 0 ? 'danger':''"
                            closable @close="buildPkgRule(idx)"
                            style="margin: 5px;"
                        >
                            {{tag.name}}({{tag.len}})
                        </el-tag>
                    </el-col>
                    <el-col :xs="24" style="padding: 15px">
                        <el-select v-model="selectedRuleType" placeholder="请选择" size="mini">
                            <el-option label="数字" value="数字"></el-option>
                            <el-option label="字母" value="字母"></el-option>
                        </el-select>
                        <el-input-number v-model="selectedRuleLength" :min="1" :max="10" size="mini" label="长度">
                        </el-input-number>
                        <el-button type="primary" @click="addPkgRule" plain size="mini" >添加</el-button>
                    </el-col>
                </el-row>

            </div>
        </transition>

        <transition name="el-zoom-in-top">
            <div v-if="ctrls.indexOf(4)>-1 || ctrls.indexOf('m')>-1 || ctrls.indexOf('n')>-1" style="padding:10px 15px;" v-show="settingPrintTicketVisible">
                <h5 style="margin:5px; padding:0px;">{{msg("货单打印设置")}}</h5>
                <el-row>
                    <el-col :xs="12" :sm="12" :md="5" style="padding: 10px 5px"> 
                        <el-select v-model="ticketPrint" @change="selectTicketPrint" 
                                    :placeholder="msg('选择配置')" class="query-select"  size="mini">
                                <el-option v-for="cfg in tpConfigs" :key="cfg.id"
                                    :label="cfg.label" :value="cfg.id" 
                                ></el-option>
                        </el-select>
                    </el-col>
                </el-row>
            </div>
        </transition>

        <el-form ref="form" class="my-form" :model="form" label-width="80px">
            <el-row :gutter="15">
                <el-row style="padding: 10px 10px;">
                    <el-col :xs="24" :sm="12" :md="12" :lg="12">
                        <el-form-item :label="msg('单号')" label-width="60px">
                            <el-input v-model.trim="form.code" ref="codeInputer" 
                                @keyup.native="doInputCode" size="small"
                                :placeholder="msg('订单编号/查询码/面单码/包号/袋号/快递单号')">
                                    <el-select v-model="form.codeType" slot="append" clearable style=" width: 130px;" placeholder="扫描快递选择">
                                    <!-- <el-option :label="msg('订单编号')" :value="0"></el-option>
                                    <el-option :label="msg('台湾物流单号')" :value="1" ></el-option>
                                    <el-option :label="msg('面单码')" :value="2" ></el-option>
                                    <el-option :label="msg('袋号')" :value="3" ></el-option>
                                    <el-option :label="msg('包号')" :value="4" ></el-option> -->
                                    <el-option :label="msg('快递单号')" :value="5" :disabled="!hasPermission('inout:selectExpCpde')"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="6" :md="6" :lg="6">
                        <el-form-item :label="msg('后缀')" label-width="60px">
                            <el-input v-model="form.suffixStr"
                                 size="small" :placeholder="msg('输入后缀')">
                                <el-checkbox slot="append" v-model="useSuffixStr">启用</el-checkbox>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-row>
            <transition name="el-zoom-in-top">
                <div v-show="ctrls.indexOf(7)>-1">
                    <el-row class="head-panel">
                        <el-form-item label-width="2px">
                            <span style="display:inline-block; margin-left:20px; color:#606266;">{{msg('无记录货物快录')}}</span>
                        </el-form-item>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="12" :md="9" :lg="8" style="margin-top:5px;">
                            <el-form-item :label="msg('货物-来源')" label-width="80px" size="mini">
                                 <el-select v-model="form.orderOrigin"
                                    :placeholder="msg('请选择')" size="mini">
                                        <el-option :label="msg('店配订单')" :value="0" ></el-option>
                                        <el-option :label="msg('线下订单')" :value="1" ></el-option>
                                        <el-option :label="msg('退件订单')" :value="11" ></el-option>
                                        <el-option :label="msg('店配订单_虾皮')" :value="8" ></el-option>
                                        <el-option :label="msg('店配订单_露天')" :value="9" ></el-option>
                                        <el-option :label="msg('店配订单_奇摩')" :value="10" ></el-option>
                                        <el-option :label="msg('仓储订单')" :value="4" ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="6" style="margin-top:5px;">
                            <el-form-item :label="msg('归属人')" label-width="60px" size="mini">
                                <el-autocomplete size="mini" clearable
                                    v-model="form.username"
                                    :fetch-suggestions="searchUser"
                                    :highlight-first-item="true"
                                    :placeholder="this.msg('搜索归属人')"
                                    :trigger-on-focus="false"
                                    @select="selectUser"
                                    @clear="clearUsername"
                                    ref="userRef"
                                ></el-autocomplete>
                                <el-checkbox style="margin-left:10px" v-model="userLocked">锁定</el-checkbox>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="11" :md="8" style="margin-top:5px;">
                            <el-form-item :label="msg('配送方式')" label-width="120px" size="mini">
                                <el-select  clearable
                                    size="mini" :placeholder="msg('请选择配送方式')" v-model="form.expCompany" @clear="clreaExpCompany">
                                    <el-option v-for="item in expCompanys" :label="item.label" :value="item.value" :key="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </transition>
            <transition name="el-zoom-in-top">
                <div v-show="ctrls.indexOf(0)>-1 || ctrls.indexOf('o') > -1">
                    <el-row class="head-panel">
                        <el-form-item :label="msg('录重')" label-width="60px">
                            <span></span>
                        </el-form-item>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="8" style="margin-top:5px;">
                            <el-form-item :label="msg('重量')" class="weight-item" label-width="60px" size="mini">
                                <div style="white-space:nowrap;">
                                    <input class="weight-inputer" v-model="form.weight" />
                                    <span class="weight-unit">{{msg('千克')}}</span>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="10" style="margin-top:15px;">
                            <el-col>
                                <el-form-item :label="msg('体积 (长*宽*高)')" label-width="120px"  size="mini">
                                    <el-input v-model="form.volumeStr" @keyup.native="computeVol">
                                        <template slot="append">
                                            = {{form.volume? form.volume : 0}} CM³
                                        </template>
                                        <el-checkbox style="margin-left:5px;" slot="append" v-model="volumeChecked">{{msg('固定值')}}</el-checkbox>
                                        <el-checkbox style="margin-left:5px;" slot="append" v-model="form.volumeNeeded">{{msg('必填')}}</el-checkbox>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-col>
                    </el-row>
                </div>
            </transition>
            <transition name="el-zoom-in-top">
                <div v-show="ctrls.indexOf(1)>-1">
                    <el-row class="head-panel" >
                        <el-form-item :label="msg('上架')" label-width="60px">
                            <span></span>
                            <div class="checkbox-inputer">
                                <el-checkbox v-model="form.autoShelfCode" :label="msg('架位智能推荐')" size="mini" @change="shelfCodeChange1" :disabled="autoShelfCodeDis"
                                        :true-label="1" :false-label="0"
                                    >
                                </el-checkbox>
                                <el-checkbox v-model="form.pinbaoShelfCode" :label="msg('拼包推同架位')" size="mini" @change="shelfCodeChange2" :disabled="pinbaoShelfCodeDis"
                                        :true-label="1" :false-label="0"
                                    >
                                </el-checkbox>
                                <el-checkbox v-model="form.autoCheckShelfCode" :label="msg('架位智能识别')" size="mini"
                                        :true-label="1" :false-label="0"
                                    >
                                </el-checkbox>
                                <span style="font-size:15px;color: red;font-family: auto;margin-left:10px;">注意：默认识别两字母开头中间一个字符4个数字结尾的架位号，如：SY-0005；可在个人设置中进行设置</span>
                            </div>
                        </el-form-item>
                    </el-row>
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="8" style="margin-top:5px;">
                            <el-form-item :label="msg('架位号')" label-width="80px" size="mini">
                                <el-input v-model="form.shelfCode" >
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="8" style="margin-top:5px;">
                            <el-form-item label="" label-width="30px"  size="mini">
                                <el-checkbox v-model="form.hasSaveDay" :label="msg('保管期限')" 
                                    :true-label="1" :false-label="0"
                                    size="mini">
                                </el-checkbox>
                                <el-input v-model="form.saveDay" type="number" style="margin-left:5px; width:150px;">
                                    <template slot="append">天</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </transition>
            <transition name="el-zoom-in-top">
                <div v-show="ctrls.indexOf(2)>-1">
                    <el-row class="head-panel">
                        <el-form-item :label="msg('装袋')" label-width="60px">
                            <div class="checkbox-inputer">
                                <el-checkbox v-model="form.noSameWarning" 
                                    :label="msg('配送不一致提醒')" 
                                    :true-label="1" :false-label="0"
                                    size="mini">
                                </el-checkbox>
                                <el-select style="margin-left:6px; width:160px; " multiple v-show="form.noSameWarning"
                                    size="mini" :placeholder="msg('物流公司')" v-model="prevCompany">
                                    <el-option v-for="item in expCompanys" :label="item.label" :value="item.value" :key="item.value"></el-option>
                                </el-select>
                            </div>
                            
                            <div class="checkbox-inputer">
                                <el-checkbox v-model="form.gtTicket" :label="msg('超')" 
                                    :true-label="1" :false-label="0"
                                    size="mini" style="margin-right:5px;">
                                </el-checkbox>
                                <el-input v-model="form.maxTickets"  size="mini"
                                        style="margin: 6px 20px 0px 6px; width:180px; ">
                                    <template slot="append">票提醒</template>
                                </el-input>
                            </div>
                            <div class="checkbox-inputer">
                                <el-checkbox v-model="form.gtWeight" :label="msg('超')" size="mini"
                                        :true-label="1" :false-label="0"
                                    >
                                </el-checkbox>
                                <el-input v-model="form.maxWeight" size="mini"
                                        style="margin-left:5px; width:180px; margin-top:6px;" 
                                    >
                                    <template slot="append">KG提醒</template>
                                </el-input>
                            </div>
                             <div class="checkbox-inputer">
                                <el-checkbox v-model="form.gtSpecGood" :label="msg('特货提醒')" size="mini"
                                        :true-label="1" :false-label="0"
                                    >
                                </el-checkbox>
                            </div>
                            <div class="checkbox-inputer">
                                <el-checkbox v-model="form.declareTicket" :label="msg('超')" 
                                    :true-label="1" :false-label="0"
                                    size="mini" style="margin-right:5px;">
                                </el-checkbox>
                                <el-input v-model="form.maxDeclareTickets"  size="mini"
                                        style="margin: 6px 20px 0px 6px; width:180px; ">
                                    <template slot="append">票同证报关提醒</template>
                                </el-input>
                            </div>
                        </el-form-item>
                    </el-row>
                    <el-row :gutter="10" v-if="ctrls.indexOf(2)>-1">
                        <el-col :xs="24" :sm="8" style="margin-top:5px;">
                            <el-form-item :label="msg('批次')" label-width="80px" size="mini">
                                <el-input v-model="form.batchCode" @keyup.native="doInputPici">
                                    <el-button slot="append" icon="el-icon-circle-check" type="primary" @click="makeBatchCode()">点击生成</el-button>
                                </el-input>
                                <el-select style="width:350px" v-model="selectPc" placeholder="查看近期批次">
                                    <el-option
                                    v-for="(item,index) in batchs"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="6" style="margin-top:5px;">
                            <el-form-item :label="msg('袋号')" label-width="80px" size="mini">
                                <el-input v-model="form.pkgCode" @change="prevCompany = [];">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="6" style="margin-top:5px;">
                            <el-form-item :label="msg('运输方式')" label-width="80px" size="mini">
                                <el-select style="width:350px" v-model="form.transport" placeholder="请选择">
                                    <el-option
                                    v-for="(item,index) in dicts.transportTypeInout"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </transition>
            <transition name="el-zoom-in-top">
                <div v-show="ctrls.indexOf(0)>-1 || ctrls.indexOf(1)>-1 || ctrls.indexOf(2)>-1">
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="10" style="margin-top:15px;margin-bottom:5px;">
                            <el-form-item :label="msg('流程备注')" label-width="80px"  size="mini">
                                <el-input v-model="form.remark">
                                    <el-checkbox slot="append" v-model="form.remarkFixed">{{msg('固定值')}}</el-checkbox>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="10" style="margin-top:15px;margin-bottom:5px;">
                            <el-checkbox v-model="form.pkgExpCode" :label="msg('查询码')" size="mini"
                                            :true-label="1" :false-label="0"
                                        >
                                    </el-checkbox>
                        </el-col>
                    </el-row>
                </div>
            </transition>
            <transition name="el-zoom-in-top">
                <div v-show="ctrls.indexOf('r')>-1">
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="10" style="margin-top:15px;margin-bottom:5px;">
                            <el-form-item :label="msg('清关行')" label-width="80px"  size="mini">
                                <el-input v-model="form.qingGuan">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </transition>
            <transition name="el-zoom-in-top">
                <div v-show="ctrls.indexOf('t')>-1">
                    <el-row :gutter="10">
                        <el-col :xs="24" :sm="10" style="margin-top:15px;margin-bottom:5px;">
                            <el-form-item :label="msg('车次')" label-width="80px"  size="mini">
                                <el-input v-model="form.trainNumber">
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </transition>
            <transition name="el-zoom-in-top">
                <div v-show="ctrls.indexOf('p')>-1" class="video_div">
                    <div style="display: flex;">
                        <h5>摄像头画面</h5>
                        <!-- <span style="margin: 15px 10px;"><el-button @click="saveVideoImg()" type="primary" size="mini">截图</el-button></span>
                        <el-image 
                            style="width: 100px; height: 100px;margin: 15px 10px;"
                            :src="videoImgSrc" 
                            :preview-src-list="previewSrcList">
                        </el-image> -->
                    </div>
                    <video class="video_el" ref="videoElement" autoplay></video>
                </div>
            </transition>
        </el-form>
        
        <h5 style="margin:15px 15px; padding:0px;">{{msg("入仓出仓的结果")}}
            <el-button type="danger" size="mini" @click="datas = []">{{msg('清空结果')}}</el-button>
        </h5>

        <div class="table-wrap" v-if="ctrls.indexOf('k') > -1">
            <!-- <vxe-grid ref="dataTable1" height="350px" 
                export-config stripe
                resizable border="full"
                highlight-current-row highlight-hover-column highlight-current-column
                show-header-overflow show-overflow
                :data="datas1"
                :columns="columns1"
                :scroll-x="{gt: 20}"
                :scroll-y="{gt: 20}"
            ></vxe-grid> -->
            <el-table
                :data="datas1"
                border
                style="width: 100%;text-align:center">
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column prop="code" label="订单号" width="150"></el-table-column>
                <el-table-column prop="innerExpCode" label="快递单号" width="150"></el-table-column>
                <el-table-column prop="productThumb" label="商品图片" width="150">
                    <template slot-scope="scope">
                        <el-image  style="width: 100px; height: 100px" 
                            :src=imgUrl+scope.row.productThumb 
                            :preview-src-list=[imgUrl+scope.row.productThumb]
                        ></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="productTitle" label="商品标题" width="150"></el-table-column>
                <el-table-column prop="productSpec" label="规格" width="150"></el-table-column>
                <el-table-column prop="variationSku" label="货号" width="150"></el-table-column>
                <el-table-column prop="productQty" label="数量" width="150"></el-table-column>
                <el-table-column prop="remark1" label="报单备注" width="150"></el-table-column>
                <el-table-column prop="remark2" label="货单备注" width="150"></el-table-column>
            </el-table>
        </div>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" height="350px" 
                export-config stripe
                resizable border="full"
                highlight-current-row highlight-hover-column highlight-current-column
                show-header-overflow show-overflow
                :data="datas"
                :columns="columns"
                :scroll-x="{gt: 20}"
                :scroll-y="{gt: 20}"
            ></vxe-grid>
        </div>

        <div style="margin-top:15px;" ></div>
        
        <span v-show="ctrls.indexOf(4)>-1 || ctrls.indexOf(5)>-1" v-if="result && result['printDtos'] && result['printDtos'].length>0">
            <vue-easy-print tableShow ref="easyPrint">
                <template>
                    <print-tpl :ticket-setting="ticketSetting" :ctrls="printCtrls" :datas="result.printDtos" :printType="printType" :user="loginUser"></print-tpl>
                </template>
            </vue-easy-print>
            <div style="text-align:center; margin-top:10px;" >
                <el-button type="primary" icon="iconfont icon-dayin" size="mini"
                    @click="doPrint()" 
                >{{msg('打印')}}</el-button>
            </div>
        </span>

        <span v-show="ctrls.indexOf('m')>-1" v-if="result && result['printDtos'] && result['printDtos'].length>0">
            <vue-easy-print tableShow ref="easyPrint2">
                <template>
                    <print-tpl2 :ticket-setting="ticketSetting" :ctrls="printCtrls" :datas="result.printDtos" :printType="printType" :user="loginUser"></print-tpl2>
                </template>
            </vue-easy-print>
            <div style="text-align:center; margin-top:10px;" >
                <el-button type="primary" icon="iconfont icon-dayin" size="mini"
                    @click="doPrint()" 
                >{{msg('打印')}}</el-button>
            </div>
        </span>

        <span v-show="ctrls.indexOf('n')>-1" v-if="result && result['printDtos'] && result['printDtos'].length>0">
            <vue-easy-print tableShow ref="easyPrint3">
                <template>
                    <print-tpl3 :ticket-setting="ticketSetting" :ctrls="printCtrls" :datas="result.printDtos" :printType="printType" :user="loginUser"></print-tpl3>
                </template>
            </vue-easy-print>
            <div style="text-align:center; margin-top:10px;" >
                <el-button type="primary" icon="iconfont icon-dayin" size="mini"
                    @click="doPrint()" 
                >{{msg('打印')}}</el-button>
            </div>
        </span>

        <span v-show="ctrls.indexOf('u')>-1" v-if="result && result['printDtos'] && result['printDtos'].length>0">
            <vue-easy-print tableShow ref="easyPrint4">
                <template>
                    <print-tpl4 :ticket-setting="ticketSetting" :ctrls="printCtrls" :datas="result.printDtos" :printType="printType" :user="loginUser"></print-tpl4>
                </template>
            </vue-easy-print>
            <div style="text-align:center; margin-top:10px;" >
                <el-button type="primary" icon="iconfont icon-dayin" size="mini"
                    @click="doPrint()" 
                >{{msg('打印')}}</el-button>
            </div>
        </span>

        <span v-show="ctrls.indexOf('i')>-1" v-if="result && result['printDtos'] && result['printDtos'].length>0">
            <vue-easy-print tableShow ref="easyPrint6">
                <template>
                    <print-tpl6 :ticket-setting="ticketSetting" :ctrls="printCtrls" :datas="result.printDtos" :printType="printType" :user="loginUser"></print-tpl6>
                </template>
            </vue-easy-print>
            <div style="text-align:center; margin-top:10px;" >
                <el-button type="primary" icon="iconfont icon-dayin" size="mini"
                    @click="doPrint()" 
                >{{msg('打印')}}</el-button>
            </div>
        </span>

        <span v-show="ctrls.indexOf('v')>-1" v-if="result && result['printDtos'] && result['printDtos'].length>0">
            <vue-easy-print tableShow ref="easyPrint7">
                <template>
                    <print-tpl7 :ticket-setting="ticketSetting" :ctrls="printCtrls" :datas="result.printDtos" :printType="printType" :user="loginUser"></print-tpl7>
                </template>
            </vue-easy-print>
            <div style="text-align:center; margin-top:10px;" >
                <el-button type="primary" icon="iconfont icon-dayin" size="mini"
                    @click="doPrint()" 
                >{{msg('打印')}}</el-button>
            </div>
        </span>

        <span v-show="ctrls.indexOf('w')>-1" v-if="result && result['printDtos'] && result['printDtos'].length>0">
            <vue-easy-print tableShow ref="easyPrint8">
                <template>
                    <print-tpl8 :ticket-setting="ticketSetting" :ctrls="printCtrls" :datas="result.printDtos" :printType="printType" :user="loginUser"></print-tpl8>
                </template>
            </vue-easy-print>
            <div style="text-align:center; margin-top:10px;" >
                <el-button type="primary" icon="iconfont icon-dayin" size="mini"
                    @click="doPrint()" 
                >{{msg('打印')}}</el-button>
            </div>
        </span>

        <span v-show="ctrls.indexOf('s')>-1" v-if="result && result['printDtos'] && result['printDtos'].length>0">
            <vue-easy-print tableShow ref="easyPrint5">
                <template>
                    <print-tpl5 :ticket-setting="ticketSetting" :ctrls="printCtrls" :datas="result.printDtos" :printType="printType" :user="loginUser"></print-tpl5>
                </template>
            </vue-easy-print>
            <div style="text-align:center; margin-top:10px;" >
                <el-button type="primary" icon="iconfont icon-dayin" size="mini"
                    @click="doPrint()" 
                >{{msg('打印')}}</el-button>
            </div>
        </span>

        <span v-show="ctrls.indexOf('r')>-1" v-if="result && result['printDtos'] && result['printDtos'].length>0">
            <vue-easy-print tableShow ref="easyPrintZdy">
                <template>
                    <print-tpl-zdy :ticket-setting="ticketSetting" :ctrls="printCtrls" :datas="result.printDtos" :printType="printType" :user="loginUser"></print-tpl-zdy>
                </template>
            </vue-easy-print>
            <div style="text-align:center; margin-top:10px;" >
                <el-button type="primary" icon="iconfont icon-dayin" size="mini"
                    @click="doPrint()" 
                >{{msg('打印')}}</el-button>
            </div>
        </span>

        <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
            <audio controls="controls" preload ref="audioRef" @ended="endPlay" >
            </audio>
        </div>
    </div>

</template>

<script>
import vueEasyPrint from "vue-easy-print";
import PrintTpl from "./StockPrintTicketTpl.vue";
import PrintTpl2 from "./StockPrintTicketTpl2.vue";
import PrintTpl3 from "./StockPrintTicketTpl3.vue";
import PrintTpl4 from "./StockPrintTicketTpl4.vue";
import PrintTpl5 from "./StockPrintTicketTpl5.vue";
import PrintTpl6 from "./StockPrintTicketTpl6.vue";
import PrintTpl7 from "./StockPrintTicketTpl7.vue";
import PrintTpl8 from "./StockPrintTicketTpl8.vue";
import PrintTplZdy from "./StockPrintTicketTplZdy.vue";
import gtWeightMp3 from "../../assets/media/gtweight.mp3"; //超重
import gtQtyMp3 from "../../assets/media/gtqty.mp3";    //超票
import mixCompany from "../../assets/media/mixCompany.mp3"; //混装
import unexistPageMp3 from "../../assets/media/page_unexist.mp3";  //面单不存在
import SpecGoodMp3 from "../../assets/media/SpecGood.mp3"; //特货
import shelf from "../../assets/media/shelfcode.mp3";//架号
import NoRecord from "../../assets/media/NoRecord.mp3"; //记录不存在
import NoWeight from "../../assets/media/NoWeight.mp3"; //未录重
import NoReceive from "../../assets/media/NoReceive.mp3"; //未到齐
import OkMp3 from "../../assets/media/ok.mp3"; //成功
import PkgNo from "../../assets/media/PkgNo.mp3"; //袋号
import CheckWeight from "../../assets/media/CheckWeight.mp3"; //校重
import CancelPkg from "../../assets/media/CancelPkg.mp3"; //取消发货成功
import PageTimeOut from "../../assets/media/PageTimeOut.mp3"; //获取面单超时
import Cancel from "../../assets/media/Cancel.mp3"; //订单已取消
import RepSend from "../../assets/media/RepSend.mp3";//重复出货
import Warning from "../../assets/media/warning.wav";//包号已存在滴滴音
import NewUser from "../../assets/media/newUser.mp3";//新客户
import Arrears from "../../assets/media/Arrears.mp3";//已欠费
import yserr from "../../assets/media/yserr.mp3";//运输错误
import dingdanErr from "../../assets/media/DDYC.mp3";//订单异常
import chaoeBG from "../../assets/media/chaoeBG.mp3";//超额报关
import PlayWeight from "../../components/PlayWeight";
import { ComPort } from "../../components/ComPort";
import { Loading } from 'element-ui';
import axios from "axios";

export default {
    name: 'StockInOut',
    components:{
        PrintTpl : PrintTpl,
        PrintTpl2 : PrintTpl2,
        PrintTpl3 : PrintTpl3,
        PrintTpl4 : PrintTpl4,
        PrintTpl5 : PrintTpl5,
        PrintTpl6 : PrintTpl6,
        PrintTpl7 : PrintTpl7,
        PrintTpl8 : PrintTpl8,
        PrintTplZdy : PrintTplZdy,
        vueEasyPrint: vueEasyPrint,
    },
    data() {
        return {
            uploadUrl: this.$kit.api.upload+"?type=product",
            ctrlOptions: [
                {value: 7, label: this.msg("无记录货物快录"), disable: false,permission:'inout:fastInput'},
                {value: 0, label: this.msg("录重"), disable: false,permission:'inout:weight'},
                {value: 1, label: this.msg("上架"), disable: false,permission:'inout:upper'},
                {value: 2, label: this.msg("装袋"), disable: false,permission:'inout:package'},
                {value: 3, label: this.msg("包号出货"), disable: false,permission:'inout:pkgout'},
                {value: 4, label: this.msg("打印拣货单"), disable: false,permission:'inout:printTicket'},
                {value: 'm', label: this.msg("打印出货码"), disable: false,permission:'inout:printTicket'},
                {value: 'l', label: this.msg("打印查询码"), disable: false,permission:'inout:printTicket'},
                {value: 'n', label: this.msg("打印拣货单&出货码"), disable: false,permission:'inout:printTicket'},
                // {value: 'r', label: this.msg("打印临时出货码"), disable: false,permission:'inout:printTicket'},
                {value: 's', label: this.msg("打印拣货单&查询码"), disable: false,permission:'inout:printTicket'},
                {value: 'i', label: this.msg("打印自定义快递单"), disable: false,permission:'inout:printTicket'},
                {value: 'u', label: this.msg("打印拣货单&自定义快递单"), disable: false,permission:'inout:printTicket'},
                {value: 'v', label: this.msg("打印中通快递单"), disable: false,permission:'inout:printTicket'},
                {value: 'w', label: this.msg("打印拣货单&中通快递单"), disable: false,permission:'inout:printTicket'},
                {value: 5, label: this.msg("打印面单"), disable: false,permission:'inout:printPage'},
                {value: 'g', label: this.msg('打印报关单'), disable: false,permission:'inout:printDeclare'},
                {value: 'd', label: this.msg('代打包登记'), disable: true,permission:'inout:packer'},
                {value: 'f', label: this.msg('快递签收'), disable: false,permission:'inout:exporder'},
                {value: 6, label: this.msg("打印入仓标签"), disable: false,permission:'inout:printLabel'},
                {value: 8, label: this.msg("打包完成"), disable: false,permission:'inout:pkgFinish'},
                {value: 9, label: this.msg("催收面单"), disable: false,permission:'inout:uploadPage'},
                {value:'h', label: this.msg("一键发货"), disable: false,permission:'inout:getPage'},
                {value: 'a', label: this.msg("仓储贴单"), disable: false,permission:'inout:cangchu'},
                // {value: 'b', label: this.msg('简单播报模式'), disable: false,permission:'inout:simple'},
                {value: 'c', label: this.msg('取消装袋'), disable: false,permission:'inout:cancelPackage'},
                {value: 'e', label: this.msg('代贴单登记'), disable: false,permission:'inout:paster'},
                {value: 'j', label: this.msg('转配送'), disable: false,permission:'inout:hasSend'},
                {value: 'k', label: this.msg('详情图片'), disable: false,permission:'inout:printTicket'},
                {value: 'o', label: this.msg('核重'), disable: false,permission:'inout:weight'},
                {value: 'p', label: this.msg('实时拍照'), disable: false,permission:'inout:weight'},
                {value: 'q', label: this.msg('获取报关单号'), disable: false,permission:'inout:weight'},
                {value: 'r', label: this.msg('打印自定义面单'), disable: false,permission:'inout:printPage'},
                {value: 't', label: this.msg('车次'), disable: false,permission:'inout:weight'},
            ],
            ctrls: [],
            form: {
                code: null,     //单号
                weight: 0,      //重量
                volumeStr: null,    //体积
                volume: null,       //体积
                shelfCode: null,    //货架号
                hasSaveDay: 0,   //是否设置保存期限
                saveDay: null,      //保存时长
                noSameWarning: 0,    //不一样提醒，配送凡是不一样提醒
                gtTicket: 0,     //是否票数超过
                maxTickets: null,   //最大票数
                declareTicket:0, //同证报关提醒
                maxDeclareTickets:null,//同证报关最大票数
                gtWeight: 0,     //是否重量超过
                maxWeight: null,    //最大重量
                batchCode: null,    //批次
                pkgCode: null,      //袋号
                //pkg: 0,         //是否执行打包
                //allowPage: 0,   //是否通知上传面单
                // noRecordAndCreate: 0,     //无记录货物快录
                username: null,     //用户名
                userId: null,       //用户账号
                expCompany: null,   //台湾物流公司
                ifNonePage: 0,   //如果面单不存在是否需要打印货单
                addedWeight:  0, //附加重量
                addedPercentWeight: 0, //百分比的附加重量
                addedWeightType: 0, //附加重量类型：0-固定值，1-百分比
                remark: null, //流程备注
                qingGuan:null, //清关行
                remarkFixed:false, //流程备注是否固定
                codeType: null,  //单号类型：0-code， 1-exp_code, 2-exp_page_code, 3-exp_pkg_code, 4-order_bag_code, 5-innExpCode

                //0-店配，1-线下，4-仓储
                orderOrigin: null,
                gtSpecGood: 0,
                pkgExpCode:0,
                autoShelfCode:0,//智能架位推荐
                pinbaoShelfCode:0,//拼包推同架位
                autoCheckShelfCode:0,//架位智能识别
                cjweight:null,
                caiji:null,
                suffixStr:null,//后缀字符串
                transport:null,
                volumeNeeded:false,//体积是否必填
                videoImgBase:null,//摄像头截图
                trainNumber:null,//车次
            },
            // videoImgSrc:null,
            // previewSrcList:[],
            autoShelfCodeDis:false,
            pinbaoShelfCodeDis:false,
            pkgTicketTotal: {},  //每袋的总票数 {code: total, code:total}
            pkgCodeTotal:{},//保存录入的所有袋号对应的单号{pkgCode:[code,code]}
            isOverTitcketTotal: false,  //是否超过总票数
            overTicketMsg: null, //消息

            pkgWeightTotal: {}, //每袋的总重量{pkgCode:{code:1,code2:2},pkgCode:{code:1,code2:2}}
            isOverWeightTotal: false,  //是否超过总重量
            overWeightMsg: null, //消息
            imgUrl: this.$kit.api.imgView,
            prevCode: null,
            inputing: false,
            inputTimer: null,
            timerCount: 0,
            stock: null,  //查询到的
            result: null,

            volumeChecked:false,//体积固定值

            prevCompany: [], //上一家物流公司
            noSameWarningMsg: "", //配送不一致的提醒消息

            ecCom: null, //com端口对象

            //货单打印设置相关
            ticketPrint:null, //货单打印选中的设置
            tpConfigs:[], //货单配置列表
            ticketSetting: null,//货单配置的值

            printType:false, //是否打印二维码

            shelfCodeRules:[],//架位规则设置

            canvas:null,//存储截图的canvas元素
            video:null,
            imageUrl:null,

            //电子秤相关：
            comConfigIdx: null,        //配置
            comConfig: null,        //配置
            comPort: null,          //端口
            comExpress: null,       //表达式
            ecConfigs: [],          //配置列表
            playWeight: null,

            playList: [],  //播放列表
            playPosition: null,  //当前播放位置
            printCtrls: [], //0-打印面单， 1-打印货单，2-打印入仓标签， 3-打印货物图片，5-打印上架单, 6-打印报关单，7-打印查询码，8-打印自定义面单
            playStatus: false,
            
            //材积计算设置
            volumn1:null,
            volumn2:null,
            volumn3:null,

            //录重设置
            settingEcVisiable: true,
            arrowIcon: "el-icon-arrow-down",

            //打印面单设置
            settingPrintTicketVisible: true,
            settingPrintTicketArrowIcon: "el-icon-arrow-down",

            //袋号自动录入设置
            enableAutoFillInputPkgCode: false,
            autoFillPkgCodeVisible: true,
            autoFillPkgCodeArrowIcon: "el-icon-arrow-down",
            //{name: 数字，length:3, type:1},{name: 字母，length:3, type:0}
            pkgRules: [],  
            pkgRuleRegExp: null,
            pkgRuleLength: null,

            selectedRuleType: null,
            selectedRuleLength: null,

            videos:[unexistPageMp3, SpecGoodMp3, NoRecord, 
                NoWeight, OkMp3, NoReceive, CheckWeight,
                CancelPkg,PageTimeOut,Cancel,RepSend, Warning,
                NewUser,Arrears,yserr,dingdanErr,chaoeBG],
            
            isFull: true,  //全功能，半功能只播重量
            columns: [],
            columns1: [],
            datas1: [],
            datas: [],
            batchs:[],//批次
            selectPc:'',
            loading:null,
            userLocked:false,
            useSuffixStr:false,

            cacheCode:null,
            loginUser:null,
            expCompanys:[],

            savedBatchName: false,
        }
    },
    computed:{
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 100;
            }
        },
    },
    mounted(){
        this.loginUser = JSON.parse(localStorage.getItem("user"));
        // this.getStores();
        this.initConfigs();
        this.buildColumns();
        this.getPici();
        this.getCompanys();
        // this.getEcConfigs();
        // this.getTpConfigs();
        this.cacheCode = null;
    },
    methods:{
        buildColumns(){
            //status: 0-处理中，1-成功，2-失败
            this.columns=[
                {field: 'index', title: "#", sortable: true, maxWidth: 75, width: 75, minWidth: 75,
                    slots: {
                        default: ({ row }) => {
                            return row.index+1;
                        },
                    }
                },
                {field: 'codeLabel', title: this.msg('单号类型'), minWidth: 100, width: 100, sortable: true},
                {field: 'code', title: this.msg('单号'), minWidth: 140, width: 140,  sortable: true},
                // {field: 'status', title: this.msg('处理结果'), minWidth: 120, width: 120, sortable: true,
                //     slots: {
                //         default: ({ row }) => {
                //             let type = row.status === 0 ? "":(row.status===1?"success":"danger")
                //             let msg = row.status === 0 ? "失败":(row.status===1?"成功":"失败")
                //             return [
                //                 <el-tag type={type}>{msg}</el-tag>
                //             ];
                //         },
                //     }
                // },
                {field: 'msg', type:"html", minWidth: 300, title: this.msg('处理结果'), sortable: true},
                {field: 'remark', title: this.msg('操作信息'), minWidth: 180,  sortable: true},
            ];
        },
        selectedStore(store){ //选择仓库
            this.store = store;
            this.storeVisible = false;
        },
        makeBatchCode(){
            this.$axios.get(this.$kit.api.stock.makeBatchCode)
            .then( (result) => {
                if(result.status){
                    this.form.batchCode = result.data;
                    let batch = result.data.substring(0,3);
                    this.getPici(batch);
                    this.savedBatchName = false;
                }
            })
        },
        getCompanys(){
            this.$axios.get(this.$kit.api.getExpCompany)
                .then((result)=> {
                    if(result.status){
                        this.expCompanys = result.data;
                    }
                });
        },
        getPici(value){//获取最新的批次
            this.batchs = [];
            this.$axios.get(this.$kit.api.stock.getBatchList+"?batch="+value)
                .then( (result) => {
                    let data = result.data;
                    if(data){
                        data.forEach(e => {
                            this.batchs.push(
                                {
                                    label:e,
                                    value:e,
                                }
                            )
                        })
                    }
                })
        },
        // selectPici(){
        //     this.form.batchCode = this.selectPc;
        //     this.form.pkgCode = null;
        // },
        isShelfCode(shelfCode){//架位智能识别
            //如果个人设置了架位规则，首先已个人设置的为准，如果没有设置，已默认为准
            if(this.shelfCodeRules && this.shelfCodeRules.length > 0){
                let defaultLength = 0;
                this.shelfCodeRules.forEach(e => {
                    defaultLength += e.len;
                });
                let star = 0;
                let end = 0;
                let ok = true;
                if(shelfCode && shelfCode.length == defaultLength){
                    for (let index = 0; index < this.shelfCodeRules.length; index++) {
                        let e = this.shelfCodeRules[index];
                        star = end;
                        end = star+e.len;
                        let str = shelfCode.substring(star,end);
                        let regStr = "";
                        let len = e.len;
                        if(e.type == 0){
                            regStr = "[0-9]";
                        }else if(e.type == 1){
                            regStr = "[a-zA-Z]";
                        }else{
                            regStr = "[\\-]";
                        }
                        let reg = new RegExp(regStr);
                        if(reg.test(str) && len == str.length){
                            ok = true;
                        }else{
                            ok = false;
                            break;
                        }
                    }
                }else{
                    ok = false;
                }
                return ok;

                // let regStr = "";
                // let len = 0;
                // this.shelfCodeRules.forEach(r => {
                //     regStr += (r.type == 0?"[0-9]":"[a-zA-Z]");
                //     regStr += "{"+r.len+"}";
                //     len += r.len;
                // });
                // let reg = new RegExp(regStr);
                // if(reg.test(shelfCode) && len == shelfCode.length){
                //     return true;
                // }else{
                //     return false;
                // }
            }else{
                //规则：两个字母+‘-’+四个数字
                if(shelfCode && shelfCode.length == 7){
                    let star = shelfCode.substring(0,2);
                    let end = shelfCode.substring(3);
                    let numReg= /^[0-9]*$/;
                    let strReg= /^[A-Za-z]+$/;
                    if(strReg.test(star) && numReg.test(end)){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    return false;
                }
            }

        },
        selectCtrl(val){  //勾选顶部要操作的checkbox后执行的事件
            if(val.indexOf(2)>-1){  //勾选了：2-装袋
                let c = this.ctrlOptions.find( c => c.value == 3)
                let d = this.ctrlOptions.find( c => c.value == 'c')

                c.disable = true;
                d.disable = true;
            }else{
                if(this.ctrls.indexOf(3) == -1){
                    this.overTicketMsg = null;
                    this.overWeightMsg = null;
                }
                let c = this.ctrlOptions.find( c => c.value == 3)
                let d = this.ctrlOptions.find( c => c.value == 'c')
                c.disable = false;
                d.disable = false;
                this.clearPkg();
            }

            if(val.indexOf(3)>-1 || val.indexOf('c')>-1){ //勾选了：3-包号出货， 2/3是互斥的
                let c = this.ctrlOptions.find( c => c.value == 2)
                c.disable = true;
                //把装袋的设置全部清空
                this.clearPkg();
            }else{
                let c = this.ctrlOptions.find( c => c.value == 2)
                c.disable = false;
            }

            if(val.indexOf(4)>-1 || val.indexOf('m')>-1 || val.indexOf('n')>-1 || val.indexOf('u')>-1 || val.indexOf('i')>-1 || val.indexOf('v')>-1 || val.indexOf('w')>-1 || val.indexOf('s')>-1 || val.indexOf(5)>-1){//勾选了打印货单和面单，则不能勾选无记录快录
                let c = this.ctrlOptions.find( c => c.value == 7)
                c.disable = true;
                let d = this.ctrlOptions.find( c => c.value == 6)
                d.disable = true;
                let dd1 = this.ctrlOptions.find( c => c.value == 'd')
                dd1.disable = false;
            }else{
                let c = this.ctrlOptions.find( c => c.value == 7)
                c.disable = false;
                let d = this.ctrlOptions.find( c => c.value == 6)
                d.disable = false;
                let dd1 = this.ctrlOptions.find( c => c.value == 'd')
                dd1.disable = true;
            }

            if(val.indexOf(7)>-1 || val.indexOf(6)>-1){
                let c = this.ctrlOptions.find( c => c.value == 4)
                c.disable = true;
                let mm = this.ctrlOptions.find( c => c.value == 'm')
                mm.disable = true;
                let nn = this.ctrlOptions.find( c => c.value == 'n')
                nn.disable = true;
                let ss = this.ctrlOptions.find( c => c.value == 's')
                ss.disable = true;
                let d = this.ctrlOptions.find( c => c.value == 5)
                d.disable = true;
            }else{
                let c = this.ctrlOptions.find( c => c.value == 4)
                c.disable = false;
                let mm = this.ctrlOptions.find( c => c.value == 'm')
                mm.disable = false;
                let nn = this.ctrlOptions.find( c => c.value == 'n')
                nn.disable = false;
                let ss = this.ctrlOptions.find( c => c.value == 's')
                ss.disable = false;
                let d = this.ctrlOptions.find( c => c.value == 5)
                d.disable = false;
            }

            if(val.indexOf(5)>-1){ //勾选了：5/h是互斥的
                let c = this.ctrlOptions.find( c => c.value == 'h')
                c.disable = true;
            }else{
                 let d = this.ctrlOptions.find( c => c.value == 'h')
                d.disable = false;
            }
            
            if(val.indexOf('h')>-1){ //勾选了：5/h是互斥的
                let c = this.ctrlOptions.find( c => c.value == 5)
                c.disable = true;
            }else{
                let d = this.ctrlOptions.find( c => c.value == 5)
                d.disable = false;
            }

            if(val.indexOf(0)>-1){ //勾选了：0/o是互斥的
                let c = this.ctrlOptions.find( c => c.value == 'o')
                c.disable = true;
            }else{
                 let d = this.ctrlOptions.find( c => c.value == 'o')
                d.disable = false;
            }
            
            if(val.indexOf('o')>-1){ //勾选了：0/o是互斥的
                let c = this.ctrlOptions.find( c => c.value == 0)
                c.disable = true;
            }else{
                let d = this.ctrlOptions.find( c => c.value == 0)
                d.disable = false;
            }

            if(val.indexOf(4)>-1){ // 4 m n r s 是互斥的
                let mm = this.ctrlOptions.find( c => c.value == 'm')
                mm.disable = true;
                let nn = this.ctrlOptions.find( c => c.value == 'n')
                nn.disable = true;
                let ss = this.ctrlOptions.find( c => c.value == 's')
                ss.disable = true;
            }else{
                if(val.indexOf('m')>-1){
                    let mm = this.ctrlOptions.find( c => c.value == 4)
                    mm.disable = true;
                    let nn = this.ctrlOptions.find( c => c.value == 'n')
                    nn.disable = true;
                    let ss = this.ctrlOptions.find( c => c.value == 's')
                    ss.disable = true;
                }else{
                    if(val.indexOf('n')>-1){
                        let mm = this.ctrlOptions.find( c => c.value == 4)
                        mm.disable = true;
                        let nn = this.ctrlOptions.find( c => c.value == 'm')
                        nn.disable = true;
                        let ss = this.ctrlOptions.find( c => c.value == 's')
                        ss.disable = true;
                    }else{
                        if(val.indexOf('s')>-1){
                            let mm = this.ctrlOptions.find( c => c.value == 4)
                            mm.disable = true;
                            let nn = this.ctrlOptions.find( c => c.value == 'm')
                            nn.disable = true;
                            let nn1 = this.ctrlOptions.find( c => c.value == 'n')
                            nn1.disable = true;
                        }else{
                            let mm = this.ctrlOptions.find( c => c.value == 4)
                            mm.disable = false;
                            let nn = this.ctrlOptions.find( c => c.value == 'm')
                            nn.disable = false;
                            let nn1 = this.ctrlOptions.find( c => c.value == 'n')
                            nn1.disable = false;
                            let ss = this.ctrlOptions.find( c => c.value == 's')
                            ss.disable = false;
                        }
                    }
                }
                // let mm = this.ctrlOptions.find( c => c.value == 'm')
                // mm.disable = false;
                // let nn = this.ctrlOptions.find( c => c.value == 'n')
                // nn.disable = false;
            }

            if(val.indexOf(4)==-1 && val.indexOf('m')==-1 && val.indexOf('n')==-1 && val.indexOf('s')==-1 && val.indexOf(5)==-1 &&  val.indexOf('u')==-1 && val.indexOf('i')==-1 && val.indexOf('v')==-1 && val.indexOf('w')==-1){
                if(val.indexOf('d') > -1){
                    val.splice(val.indexOf('d'),1);
                }
            }

            if(val.indexOf(1) == -1){//没有勾选上架
                this.form.shelfCode = null;
                this.form.hasSaveDay = 0;
                this.form.saveDay = null;
                this.form.autoShelfCode = 0;
                this.form.pinbaoShelfCode = 0;
                this.form.autoCheckShelfCode = 0;
            }

            if(val.indexOf(7) == -1){//没有勾选无记录快录
                this.form.orderOrigin = null;
                this.form.username = null;
                this.form.userId = null;
                this.userLocked = false;
                this.form.expCompany = null;
            }

            //显示电子秤设置，并初始化电子秤设置，
            if(val.indexOf(0)>-1 || val.indexOf('o')>-1){
                //this.changeSettingEcVisiable(true);
                if(this.comConfigIdx == null){
                    this.setDefaultEcConfig();
                }
                // 初始化语音播报功能
                if(!this.playWeight){
                    this.playWeight = new PlayWeight();
                }

            }else{
                this.form.weight = 0;
                this.form.volumeStr = null;
                this.volumeChecked = false;
                this.form.volumeNeeded = false;
                this.form.cjweight = null;
                this.form.caiji = null;
            }

            //设置打印控制
            this.printCtrls = [];
            if(this.ctrls.indexOf(4)>-1 || this.ctrls.indexOf('m')>-1 || this.ctrls.indexOf('n')>-1 || this.ctrls.indexOf('u')>-1 || this.ctrls.indexOf('i')>-1 || this.ctrls.indexOf('v')>-1 || this.ctrls.indexOf('w')>-1 || this.ctrls.indexOf('s')>-1){
                this.printCtrls.push(1);
            }else{
                let idx = this.printCtrls.indexOf(1);
                if(idx>-1){
                    this.printCtrls.splice(idx, 1);
                }
            }

            if(this.ctrls.indexOf(5)>-1){
                this.printCtrls.push(0);
            
            }else{
                let idx = this.printCtrls.indexOf(0);
                if(idx>-1){
                    this.printCtrls.splice(idx, 1);
                }
            }

            if(this.ctrls.indexOf(6)>-1){
                this.printCtrls.push(2);
            }else{
                let idx = this.printCtrls.indexOf(2);
                if(idx>-1){
                    this.printCtrls.splice(idx, 1);
                }
            }

            if(this.ctrls.indexOf("g")>-1){
                this.printCtrls.push(6);
            }else{
                let idx = this.printCtrls.indexOf(6);
                if(idx>-1){
                    this.printCtrls.splice(idx, 1);
                }
            }

            if(this.ctrls.indexOf("l")>-1){
                this.printCtrls.push(7);
            }else{
                let idx = this.printCtrls.indexOf(7);
                if(idx>-1){
                    this.printCtrls.splice(idx, 1);
                }
            }

            if(this.ctrls.indexOf("r")>-1){
                this.printCtrls.push(8);
            }else{
                let idx = this.printCtrls.indexOf(8);
                if(idx>-1){
                    this.printCtrls.splice(idx, 1);
                }
            }

            if(this.ctrls.indexOf('b')>-1){
                this.isFull = false;
            }else{
                this.isFull = true;
            }

            //初始化摄像头
            if(this.ctrls.indexOf('p')>-1){
                //打开摄像头
                // 获取 video 元素
                this.video = this.$refs['videoElement'];
                this.canvas = null;
                var _this = this;
                // 检测浏览器是否支持 getUserMedia
                if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                    // 请求访问摄像头
                    navigator.mediaDevices.getUserMedia({ video: true })
                        .then(function(stream) {

                            // 将获取到的视频流赋值给 video 元素的 srcObject 属性
                            _this.video.srcObject = stream;

                        }).catch(function(error) {
                            console.error('访问摄像头失败:', error);
                        });
                } else {
                    console.error('浏览器不支持 getUserMedia.');
                }

            }else{
                if(this.video){
                    this.video.srcObject.getTracks()[0].stop();
                }
                this.canvas = null;
            }

        },
        shelfCodeChange1(){
            if(this.form.autoShelfCode == 1){
                this.form.pinbaoShelfCode = 0;
                this.pinbaoShelfCodeDis = true;
            }else{
                this.pinbaoShelfCodeDis = false;
            }
        },
        shelfCodeChange2(){
            if(this.form.pinbaoShelfCode == 1){
                this.form.autoShelfCode = 0;
                this.autoShelfCodeDis = true;
            }else{
                this.autoShelfCodeDis = false;
            }
        },
        saveVideoImg(){
            //保存摄像头截图
            // 创建一个canvas元素来存储截图
            if (!this.canvas) {
                this.canvas = document.createElement('canvas');
            }

            // 设置canvas尺寸与视频尺寸一致
            // this.canvas.width = this.video.videoWidth;
            // this.canvas.height = this.video.videoHeight;
            this.canvas.width = 1800;
            this.canvas.height = 1200;

            // 绘制当前视频画面到canvas上
            const context = this.canvas.getContext('2d');
            context.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);

            // 创建一个新的图像对象来显示截图
            const image = new Image();
            image.src = this.canvas.toDataURL();
            // this.videoImgSrc = this.canvas.toDataURL();
            // this.previewSrcList = [this.canvas.toDataURL()]
            let base = this.canvas.toDataURL('image/png');
            this.form.videoImgBase =  base;

            // var _this = this;
            // this.$axios.post(this.$kit.api.stock.saveVideoImg, base)
            // .then(function(result){
            //     if(result.status){
            //         _this.form.videoImgId = result.data;
            //     }else{
            //         this.$message(this.msg('保存失败'));
            //     }
            // });
        },
        clearPkg(){
            //把装袋的设置全部清空
            this.form.noSameWarning = 0;
            this.prevCompany = [];
            this.form.gtTicket = 0;
            this.form.maxTickets = null;
            this.form.declareTicket = 0;
            this.form.maxDeclareTickets = null;
            this.form.gtWeight = 0;
            this.form.maxWeight = null;
            this.form.gtSpecGood = 0;
            this.form.pkgExpCode = 0;
            this.form.pkgCode = null;
            this.form.transport = null;
            this.form.batchCode = null;
            this.form.remark = null;
        },
        changeSettingVisible(visibleVar, iconVar, visible){
            this[visibleVar] = visible;
            this[iconVar] = visible? 'el-icon-arrow-down':'el-icon-arrow-right';
        },
        setDefaultEcConfig(){
            //设置电子秤配置
            let isSelect = false;

            if(this.ecConfigs){
                for(let i=0; i<this.ecConfigs.length; i++){
                    let cfg = this.ecConfigs[i];
                    if(cfg.disabled){
                        this.comConfigIdx = cfg.id;
                        this.comConfig = cfg;
                        this.comPort = cfg.port;
                        this.comExpress = cfg.express+"";
                        isSelect = true;
                        break;
                    }
                }
            }

            if(!isSelect){
                let cfg = this.ecConfigs[0];
                this.comConfigIdx = cfg.id;
                this.comConfig = cfg;
                this.comPort = cfg.port;
                this.comExpress = cfg.express+"";
            }
        },
        initConfigs(){
            this.setDefaultSetting();
            this.$axios.get(this.$kit.api.user.configs+"?groups=ECSETTING,TPSETTING,ATTACH_WEIGHT,VOLUMN_FORMULA,PRINT_WARN")
                .then( (result) => {
                    if(result.status){
                        let datas = result.data;
                        if(!datas || datas.length==0){
                            return;
                        }

                        let attachs = [];

                        let ecs = datas['ECSETTING'];
                        if(ecs && ecs.length>0){
                            ecs.forEach(d => {
                                let val = JSON.parse(d.xvalue);
                                let cfg = {
                                    id: d.id,
                                    label: val.name,
                                    port: val.port,
                                    express: val.express,
                                    disabled: d.disabled
                                };
                                this.ecConfigs.push(cfg);
                            })
                        }

                        let tps = datas['TPSETTING'];
                        if(tps && tps.length>0){
                            tps.forEach(d => {
                                let tpg = {
                                    id:d.id,
                                    label:d.xlabel,
                                    xvalue:d.xvalue
                                }
                                this.tpConfigs.push(tpg);
                                if(d.disabled == 1){
                                    this.ticketPrint = d.id;
                                    this.ticketSetting = JSON.parse(d.xvalue);
                                }
                            })
                        }

                        let achs = datas['ATTACH_WEIGHT'];
                        if(achs && achs.length>0){
                            let attach = achs.find(a => a.disabled == 1 && a.xvalue);
                            if(attach == null){
                                attach = achs.find(a => a.xvalue);
                            }
                            if(attach){
                                let val = JSON.parse(attach.xvalue);   
                                this.form.addedWeight = val.addedWeight;
                                this.form.addedPercentWeight = val.addedPercentWeight;
                                this.form.addedWeightType = val.addedWeightType;
                            }
                        }

                        let volumns = datas['VOLUMN_FORMULA'];
                        if(volumns && volumns.length > 0){
                            let vol = volumns[0];
                            let volValue = JSON.parse(vol.xvalue);   
                            this.volumn1 = volValue.volumn1;
                            this.volumn2 = volValue.volumn2;
                            this.volumn3 = volValue.volumn3;
                        }else{
                            this.volumn1 = '长*宽*高/27000';
                            this.volumn2 = '长*宽/900';
                            this.volumn3 = '长/30';
                        }

                        let shelfCodes = datas['PRINT_WARN'];
                        if(shelfCodes && shelfCodes.length > 0){
                            let vol = shelfCodes[0];
                            let volValue = JSON.parse(vol.xvalue);   
                            this.shelfCodeRules = volValue.pkgRules;
                            this.printType = volValue.printType?true:false;
                        }
                    }
                });
        },

        setDefaultSetting(){
            this.ecConfigs.push({
                id: -1,
                label: this.msg("默认"),
                port: 9600,
                express: this.getEcVal,
                disabled: false,
            });

            let tpCfg = {
                invoice: true,
                invoiceHead: true,
                invoiceCompany: true,
                invoiceBarCode: true,
                invoiceInfo: true,
                invoiceCode: true,
                invoiceSender: true,
                invoiceReceiver: true,
                invoiceTel: true,
                invoiceAddr: true,
                pkgType: false,

                invoiceTable: true,
                invoiceTableName: true,
                invoiceTableQty: true,
                invoiceTablePrice: true,
                invoiceTableAmount: true,

                erweima: true,
                tiaoma: true,
                detail: true,
                detailCode: true,
                detailShelfCode: true,
                detailQty: true,
                detailTitle: true,
                detailSpec: true,
                detailPkg: true,
                detailDate: true,
                detailRemark1: true,
                detailRemark2: true,
                shopSellerMsg: true,
            };

            this.tpConfigs.push({
                id: -1,
                label: "默认",
                xvalue: JSON.stringify(tpCfg)
            });

            this.ticketPrint = -1;
            this.ticketSetting = tpCfg;

        },
        selectEcConfig(idx){ //选中配置
            let cfg = this.ecConfigs[idx];
            this.comConfig = cfg;
            this.comPort = cfg.port;
            this.comExpress = cfg.express+"";
        },
        selectTicketPrint(key){//选择货单打印配置
            this.tpConfigs.forEach(e => {
                if(e.id == key){
                    this.ticketSetting = JSON.parse(e.xvalue);
                }
            })
        },
        connectCOM(){ //连接电子秤
            if(!this.comConfig){
                this.$message("请先配置电子秤连接")
                return;
            }

            if(this.ecCom){
                this.$message("已连接，请断开后再试");
                return;
            }

            this.ecCom = new ComPort();
            this.ecCom.connect(this.comConfig.port*1);
            var _this = this;
            this.ecCom.addEventListener("rx", (e)=>{ _this.readFromCom(e); });
            this.ecCom.addEventListener("log",(e)=>{ _this.readLogFromCom(e); });
        },
        disconnectCOM(){
            if(this.ecCom){
                this.ecCom.disconnect();
                this.ecCom = null;
            }
        }, 
        readLogFromCom(e){
            // console.log(e);
            if(e.detail){
                this.$message(e.detail);
            }
        },
        readFromCom(e){ //从电子秤读取数据的回调
            //console.log(e);
            if(e.detail){
                if(this.comConfig.express){
                    let express = this.comConfig.express;
                    if(typeof express === 'function'){
                        this.form.weight=express(e.detail);
                    }else{
                        try {
                            this.form.weight=eval(express.replace("${value}", e.detail));
                        }catch(err){
                            console.error(err);
                        }
                    }
                }
            }
        },
        getEcVal(value){ // =540.000=550.00
            value = value.split("=");
            value = value[value.length-1];
            return (value.split("").reverse().join(""))*1;
        },
        searchUser(queryString, cbFn){  //搜索用户
            this.$axios.get(this.$kit.api.user.search+"?name="+encodeURIComponent(queryString)+"&type=1")
                .then((result) => {
                    if(result.status){
                        let users =[];
                        result.data.forEach(e => {
                            users.push({
                                id: e.id,
                                value: e.value
                            });
                        });
                        cbFn(users);
                    }
                });
        },
        selectUser(item) { //获取选中的用户
            this.form.userId = item.id;
            this.form.username = item.value;
            this.$refs['codeInputer'].focus();
        },
        computeVol(e){ //计算体积,根据个人设置的材积计算公式进行计算
            if(this.form.volumeStr){
                let vols = this.form.volumeStr.split("*");
                let vol1Len = this.volumn1.split("*");//长宽高
                let vol2Len = this.volumn2.split("*");//长宽
                let vol3Len = this.volumn3.split("*");//长
                // if(vols.length == 3){
                //     this.form.volume = (vols[0].trim() * vols[1].trim() * vols[2].trim()).toFixed(3);
                //     this.form.caiji = ((vols[0].trim() * vols[1].trim() * vols[2].trim())/28317).toFixed(2);
                //     this.form.cjweight = ((vols[0].trim() * vols[1].trim() * vols[2].trim())/6000).toFixed(2);
                //     return;
                // }
                let countStr = "";
                //volumn1='长*宽*高/27000'
                if(vols.length == vol1Len.length){
                    countStr = this.volumn1.replace("长",vols[0].trim()).replace("宽",vols[1].trim()).replace("高",vols[2].trim());
                }else if(vols.length == vol2Len.length){
                    countStr = this.volumn2.replace("长",vols[0].trim()).replace("宽",vols[1].trim());
                }else if(vols.length == vol3Len.length){
                    countStr = this.volumn3.replace("长",vols[0].trim());
                }
                console.log(countStr);
                // 将字符串分割成数组
                const arr = countStr.split("");
                // 准备一个容器
                let newArray = []
                // 遍历字符串数组
                arr.forEach((item, i) => {
                // 如果当前字符串是数字
                    if (this.isNumber(item)) {
                        // 拿到最后一个数组元素
                        let endStr = newArray[newArray.length - 1]
                        // 拿到最后一个数组元素的索引
                        const endIndex = newArray.length - 1
                        // 判断最后一个数组元素是否是数字
                        if (this.isNumber(endStr)) {
                        // 如果最后一个数组元素是数字,则进行拼接
                        endStr += item
                        // 修改最后一个数组元素
                        newArray.splice(endIndex, 1, endStr)
                        } else {
                        // 如果最后一个数组元素是运算符,则将当前项直接添加到数组
                        newArray.push(item)
                        }
                    } else {
                        // 如果当前项是运算符,则将当前项直接添加到数组
                        newArray.push(item)
                    }
                })
                // 将数字与运算符遍历,将字符串类型的数字转换成数字类型
                newArray = newArray.map((item) => (this.isNumber(item) ? parseFloat(item) : item))

                this.sumResutl(newArray)
                this.form.volume = newArray[0]?newArray[0].toFixed(3):0;
                return;
            }

            this.form.volume = 0;
        },
        // 计算当前字符串是否是数字
        isNumber(val){
            const regPos = /^\d+(\.\d+)?$/ // 非负浮点数
            const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/ // 负浮点数
            if (regPos.test(val) || regNeg.test(val)) {
                return true
            } else {
                return false
            }
        },
         // 通过递归算法,先判断数组元素是否只有一个,如果只有一个数组元素,则返回这个最终值,如果数组元素
        sumResutl(arr) {
            // 如果数组元素中有乘除加减运算符
            const i1 = arr.indexOf('*')
            const i2 = arr.indexOf('/')
            const i3 = arr.indexOf('-')
            const i4 = arr.indexOf('+')
            if (i1 !== -1) {
                // 拿到乘号运算符前面数字的索引
                const index = i1 - 1
                // 计算出结果
                const result = arr[index] * arr[i1 + 1]
                // 修改数组元素
                arr.splice(index, 3, result)
                // 如果剩下的元素不只一位,则继续调用函数自身
                if (arr.length > 1) this.sumResutl(arr)
            } else if (i2 !== -1) {
                // 拿到乘号运算符前面数字的索引
                const index = i2 - 1
                // 计算出结果
                const result = arr[index] / arr[i2 + 1]
                // 修改数组元素
                arr.splice(index, 3, result)
                // 如果剩下的元素不只一位,则继续调用函数自身
                if (arr.length > 1) this.sumResutl(arr)
            } else if (i3 !== -1) {
                // 拿到乘号运算符前面数字的索引
                const index = i3 - 1
                // 计算出结果
                const result = arr[index] - arr[i3 + 1]
                // 修改数组元素
                arr.splice(index, 3, result)
                // 如果剩下的元素不只一位,则继续调用函数自身
                if (arr.length > 1) this.sumResutl(arr)
            } else if (i4 !== -1) {
                // 拿到乘号运算符前面数字的索引
                const index = i4 - 1
                // 计算出结果
                const result = arr[index] + arr[i4 + 1]
                // 修改数组元素
                arr.splice(index, 3, result)
                // 如果剩下的元素不只一位,则继续调用函数自身
                if (arr.length > 1) this.sumResutl(arr)
            }
        },
        doInputPici(e){
            if(e.keyCode == 13){
                let batch = this.form.batchCode;
                if(batch){
                    if(batch.length>=4){
                        batch = batch.substring(0,3);
                    }
                    this.getPici(batch);
                }
            }
        },

        doInputCode(e){ //录入单号

            if(e.keyCode == 13){

                if(this.ctrls.indexOf('p')>-1){
                    this.saveVideoImg();
                }

                if(this.enableAutoFillInputPkgCode && this.ctrls.indexOf(2)>-1){
                    //如果满足袋号规则自动填入
                    if(this.pkgRuleRegExp && this.pkgRuleRegExp.test(this.form.code) && this.pkgRuleLength == this.form.code.length){
                        this.setPkgCode();
                        this.form.code = null;
                        this.playList.push(PkgNo);
                        this.playAudio();
                        // this.selectCode();
                    }else{
                        this.updateInOrOutStock();
                    }
                }else if(this.form.autoCheckShelfCode == 1){
                    if(this.isShelfCode(this.form.code)){
                        this.form.shelfCode = this.form.code;
                        this.playList.push(shelf);
                        this.playAudio();
                        this.form.code = null;
                        return;
                    }else{
                        this.updateInOrOutStock();
                    }
                }else{
                    this.updateInOrOutStock();
                }
            }
        },
        // listenInput(){ //监听输入
        //     if(this.form.code && this.form.code == this.prevCode){
        //         this.inputing = false;
        //         clearInterval(this.inputTimer);
        //         //勾选了装袋，并且启用了袋号过滤
        //         if(this.enableAutoFillInputPkgCode && this.ctrls.indexOf(2)>-1){
        //             //如果满足袋号规则自动填入
        //             if(this.pkgRuleRegExp && this.pkgRuleRegExp.test(this.form.code) && this.pkgRuleLength == this.form.code.length){
        //                 this.setPkgCode();
        //                 this.form.code = null;
        //                 this.playList.push(PkgNo);
        //                 this.playAudio();
        //                 // this.selectCode();
        //             }else{
        //                 this.updateInOrOutStock();
        //             }
        //         }else if(this.autoCheckShelfCode == 1){
        //             if(this.isShelfCode(this.form.code)){
        //                 this.form.shelfCode = this.form.code;
        //                 this.playList.push(shelf);
        //                 this.playAudio();
        //                 this.form.code = null;
        //                 return;
        //             }
        //         }else{
        //             this.updateInOrOutStock();
        //         }
        //         // console.log("最终输入值为：", this.form.code);
        //     }else{
        //         if(!this.inputing){
                    
        //             this.inputing = true;
        //             this.inputTimer = setInterval(() => {
        //                 this.listenInput();
        //             }, 500);
        //         }
        //         if(this.timerCount > 20){
        //             this.inputing = false;
        //             clearInterval(this.inputTimer);
        //             this.timerCount = 0;
        //             this.listenInput();
        //         }else{
        //             ++ this.timerCount;
        //         }
        //         this.prevCode = this.form.code;
        //     }
        // },

        selectCode(){ //选中单号
            let inputer = this.$refs.codeInputer.$el.querySelector("input");
            inputer.selectionStart = 0;
            inputer.selectionEnd = this.form.code.length;
        },
        clreaExpCompany(){
            this.form.expCompany = null;
        },
        clearUsername(){
            this.form.username = null;
            this.form.userId = null;
            this.$refs['codeInputer'].focus();
            this.$refs['userRef'].focus();
        },
        
        dogetPicByInnerCode(){
            //查询详情图片
            this.$axios.get(this.$kit.api.stock.getDetailByInnerCode+"?code="+this.form.code)
            .then((result) => {
                if(result.status){
                    this.datas1 = [];
                    this.datas1 = result.data;
                    this.datas1.forEach(e => {
                        if(e.productPrice){
                            e.productPrice = (e.productPrice/100).toFixed(3);
                        }
                    })
                }else{
                    this.datas1 = [];
                    this.playList.push(NoRecord);
                    this.playAudio();
                }
            });
        },

        //提交数据
        updateInOrOutStock(){
            if(!this.form.code){
                return;
            }
            //如果勾选了详情图片并且使用快递单号，则只查询单号对应的详情图片，不执行其他操作
            if(this.ctrls.indexOf('k')>-1 && this.form.codeType == 5){
                if(this.cacheCode == null){
                    this.cacheCode = this.form.code;
                    this.dogetPicByInnerCode();
                    this.form.code = null;
                    return;
                }else{
                    if(this.cacheCode != this.form.code){
                        this.cacheCode = this.form.code;
                        this.dogetPicByInnerCode();
                        this.form.code = null;
                        return;
                    }
                }
            }

            if(this.useSuffixStr){
                if(!this.form.suffixStr){
                    this.$message(this.msg("请输入后缀字符"));
                    return;
                }else{
                    this.form.code = this.form.code+this.form.suffixStr
                }
            }
            
            if(this.ctrls.indexOf(7)>-1 && this.form.orderOrigin == null){
                this.$message(this.msg("货物来源不能为空"));
                return;
            }

            if(this.ctrls.indexOf(7)>-1 && this.form.userId == null){
                this.$message(this.msg("归属人不能为空"));
                return;
            }

            if(this.ctrls.indexOf(7)>-1 && !this.form.expCompany){
                this.$message(this.msg("无记录快录配送方式不能为空"));
                return;
            }

            if(this.ctrls.indexOf(1)>-1 && this.form.shelfCode){
                if(this.form.shelfCode.indexOf('*') > -1){
                    this.$message(this.msg("架位号不能包含“*”"));
                    return;
                }
            }

            if(this.form.declareTicket == 1){
                if(!this.form.maxDeclareTickets || this.form.maxDeclareTickets < 0){
                    this.$message(this.msg("同证报关提醒数量不能小于0"));
                    return;
                }
            }

            if(this.ctrls.indexOf(2)>-1 ){

                if(this.form.pkgCode ){

                    if(!this.form.batchCode){
                        this.$message(this.msg("批次不能为空"));
                        return;
                    }

                    let isPush = true;
                    if(this.batchs.length > 0){
                        this.batchs.forEach(e => {
                            if(this.form.batchCode == e.value){
                                isPush = false;
                            }
                        })
                        if(isPush){
                            this.batchs.unshift({
                                label:this.form.batchCode,
                                value:this.form.batchCode,
                            })
                        }
                    }

                    //设置当前袋中的总票数(old)
                    // let totalTicket = this.pkgTicketTotal[this.form.pkgCode];
                    // if(totalTicket){
                    //     this.pkgTicketTotal[this.form.pkgCode] = totalTicket + 1;
                    // }else{
                    //     this.pkgTicketTotal[this.form.pkgCode] = 1;
                    // }
                }else{
                    this.$message(this.msg("袋号不能为空"));
                    this.form.code = null;
                    this.$refs['codeInputer'].focus();
                    this.playList.push(Warning);
                    this.playAudio();
                    return;
                }

                if(!this.form.transport){
                    this.$message(this.msg("运输方式不能为空"));
                    this.playList.push(Warning);
                    this.playAudio();
                    return;
                }
                
            }
            
            this.playList = [];
            this.playPosition = 0;

            let index = this.datas.length;
            let remarkWeight = "";
            if(this.form.weight){
                remarkWeight += this.form.weight+"&";
            }
            let row = {
                codeLabel: null,
                code: this.form.code,
                status: 0,
                msg: "",
                index: index,
                // weight:this.form.weight,
                remark:remarkWeight,
            };
            this.datas.push(row);

            //播报获取重量播报语音
            if(this.ctrls.indexOf(0)>-1 && this.form.weight && this.ctrls.indexOf('o')<0){
                if(this.isFull){
                    let twavs = this.playWeight.getWavMedia(this.form.weight+"");
                    twavs.forEach(w => this.playList.push(w))
                }else{
                    let twavs = this.playWeight.getSimpleWavMedia(this.form.weight+'');
                    twavs.forEach(w => this.playList.push(w))
                }
            }

            //检测配送不一致， 获取配送不一致提醒语音
            // if(!this.checkData(index, row)){
            //     return;
            // }
            
            var params = Object.assign({ctrls: this.ctrls.join(","), index: index}, this.form);
            this.form.code = null; 
            
            if(this.playList.length>0){
                this.playAudio();
            }

            if(!this.form.username){
                this.form.userId = null;
            }

            this.$refs.codeInputer.focus();

            if(this.ctrls.indexOf(5) > -1){
                this.loading = Loading.service({
                    lock: true,
                    text: "加载中...",
                    background: "rgba(0, 0, 0, 0.7)"
                });
            }

            //
            if(!this.savedBatchName){
                let param = {
                    name:this.form.batchCode
                }
                axios.request({
                    url: this.$kit.api.stock.saveBatch, 
                    method: 'post',
                    headers: {"Content-Type":"application/json; charset=utf-8",
                            "X-Requested-With":"XMLHttpRequest",
                        },
                    data: param,
                    withCredentials: true,
                })
                this.savedBatchName = true;
            }

            axios.request({
                url: this.$kit.api.stock.inout, 
                method: 'post',
                headers: {"Content-Type":"application/json; charset=utf-8",
                        "X-Requested-With":"XMLHttpRequest",
                    },
                data: params,
                withCredentials: true,
            }).then((response) => {
                    if(this.loading){
                        this.loading.close();
                        this.loading = null;
                    }
                    let result = response.data;
                    if(!result.status){
                        if(result.code == '-2'){
                            this.$message("未登录或登录过期");
                            this.$router.push("/login");
                        }else if(!result.data){
                            this.$message(result.msg);
                            if(result.msg.indexOf("单号已存在")>-1){
                                this.playList.push(Warning);
                                this.playAudio();
                            }
                            return;
                        }
                      
                    }

                    let row = this.datas[result.data.index];
                    if(row){
                        row.status = result.status?1:2;
                        if(!result.status){
                            row.msg = result.msg;
                        }
                    }
                    if(result.status){
                        
                        let data = result.data;
                        let msg1 = "";
                        //配送不一致提醒处理
                        if(this.form.noSameWarning && data && data.expCompany ){
                            if(this.prevCompany && this.prevCompany.length > 0){
                                if(this.prevCompany.indexOf(result.data.expCompany) == -1){
                                    msg1 += "<span style='font-size:12px; color:red;'>"
                                        +this.msg('上一票的配送方式和这一票配送不一致')
                                        +" </span>";
                                    if(this.isFull){
                                        this.playList.push(mixCompany);
                                        if(this.playList.length==1){
                                            this.playPosition = 0;
                                            this.playAudio();
                                        }
                                    }
                                }
                            }else{
                                this.prevCompany.push(result.data.expCompany);
                            }
                        }

                        let rweight = data.weight/1000;
                        //设置超票提醒的总票数(new)
                        if(rweight){
                            let arr = [data.code];
                            let piaoCode = this.pkgCodeTotal[this.form.pkgCode];
                            if(piaoCode){
                                if(this.pkgCodeTotal[this.form.pkgCode].indexOf(data.code) == -1){
                                    this.pkgCodeTotal[this.form.pkgCode].push(data.code);
                                }
                            }else{
                                this.pkgCodeTotal[this.form.pkgCode] = arr;
                            }
                        }

                        //超票提醒
                        if(this.form.gtTicket*1==1 && this.form.maxTickets*1>0 && this.form.pkgCode){
                            // let total = this.pkgTicketTotal[this.form.pkgCode]*1;
                            let total = this.pkgCodeTotal[this.form.pkgCode].length;
                            if(total > this.form.maxTickets*1){
                                msg1 += "<span style='font-size:12px; color:red;'>袋号："+this.form.pkgCode+"当前票数"+total+"，超过了设定的最大票数："+this.form.maxTickets+" </span> ";
                                this.playList.push(gtQtyMp3);
                            }else{
                                msg1 += "<span style='font-size:12px;'>袋号："+this.form.pkgCode+"当前票数为："+total+" </span>";
                            }
                        }

                        //超重提醒
                        if(this.form.gtWeight && this.form.maxWeight*1>0 && this.ctrls.indexOf(2)>-1){
                                let totalWeight = 0;

                                //{pkgCode:{code:1,code2:2},pkgCode:{code:1,code2:2}}
                                let res = data.msgs;
                                let ok = true;
                                if(res && res.length > 0){
                                    res.forEach(e => {
                                        if(e.indexOf('打包失败') > 0){
                                            ok = false;
                                        }
                                    })
                                }
                                if(ok){
                                    if(this.pkgWeightTotal && this.pkgWeightTotal[this.form.pkgCode]){
                                        let obj = this.pkgWeightTotal[this.form.pkgCode];
                                        if(!obj[data.code]){
                                            obj[data.code] = rweight;
                                        }
                                    }else{
                                        let obj = {};
                                        obj[data.code] = rweight;
                                        this.pkgWeightTotal[this.form.pkgCode] = obj;
                                    }
                                }

                                if(this.pkgWeightTotal && this.pkgWeightTotal[this.form.pkgCode]){
                                    let obj = this.pkgWeightTotal[this.form.pkgCode];
                                    for(let key  in obj){
                                        let w = obj[key]*1;
                                        totalWeight += w;
                                    }
                                }


                                if(totalWeight > this.form.maxWeight*1){
                                    msg1+= "<span style='font-size:12px; color:red;'>袋号："+this.form.pkgCode+"当前重量："
                                        +totalWeight.toFixed(4)
                                        +"KG，超过了设定的最大重量："+this.form.maxWeight+"KG"
                                        +" </span>";
                                    if(this.isFull){
                                        this.playList.push(gtWeightMp3);
                                    }
                                    
                                }
                        }


                        //设置提示消息
                        if(row){
                            let msgs = result.data.msgs.map(e => "<span style='font-size:12px;'>"+e+" </span>");
                            row.msg += (msgs? msgs.join(""):"")+msg1;
                            row.codeLabel = result.data.codeLabel;
                            // row.productName = result.data.productName;
                            let remarks = result.data.remark.map(e => e);
                            let strs = [];
                            if(remarks && remarks.length > 0){
                                for (let index = 0; index < remarks.length; index++) {
                                    const e = remarks[index];
                                    if(e){
                                        strs.push(e);
                                    }
                                }
                            }
                            row.remark += (strs? strs.join("&"):"");
                        }

                        //播报核重重量语音
                        if(this.ctrls.indexOf('o')>-1){
                            let weightCheck = result.data.weightCheck;
                            let twavs = this.playWeight.getWavMedia(weightCheck+"");
                            twavs.forEach(w => this.playList.push(w))
                            this.playAudio();
                        }

                        //设置播报语音
                        if(this.isFull){
                            if(data && data.playVideos && data.playVideos.length>0){
                                for(let i =0; i<data.playVideos.length; i++){
                                    let videoIndex = data.playVideos[i];
                                    if(this.playList.indexOf(videoIndex)==-1){
                                        this.playList.push(this.videos[videoIndex]);
                                    }
                                }
                            }
                            if(!this.playStatus && this.playList && this.playList.length > 0){
                                this.playAudio();
                            }
                        }
                        this.result = null;
                        this.result = data;
                        this.doPrint();
                        //清空单号输入框

                        if(!this.userLocked){
                            this.clearUsername();
                        }
                        
                    }else{
                        this.result = null;
                        this.$message(result.msg || this.msg('出现错误，请联系管理员'))
                    }
                    //  this.selectCode();
                    // this.form.code = null;
                    if(!this.volumeChecked){
                        //清空体积
                        this.form.volumeStr = null;
                        this.form.volume = 0;
                        this.form.caiji = null;
                        this.form.cjweight = null;
                    }
                    if(!this.form.remarkFixed){
                        this.form.remark = null;
                    }
                    //  this.$refs.codeInputer.focus();
                }).catch((error) => {
                        this.$message("网络请求错误，请检查网络连接");
                        console.log(error);
                        if(this.loading){
                            this.loading.close();
                            this.loading = null;
                        }
                    });
        },
        doPrint(){
            //设置打印控制
            if(this.printCtrls.length>0 || this.form.autoShelfCode == 1 || this.form.pinbaoShelfCode == 1){
                let printDatas = this.result.printDtos;
                let printTicket = null;
                let shelfCode = null;
                if(printDatas){
                    printTicket = printDatas.find(d => d.ticketDto);
                    shelfCode = printDatas.find(d => (d.shelfCodeDto && d.shelfCodeDto.barcodeAsBase64));
                }
                if(this.printCtrls.indexOf(1) == -1 && printTicket){
                    this.printCtrls.push(1);
                }
                if(shelfCode){
                    this.printCtrls.push(5);
                }else{
                    if(this.printCtrls.indexOf(5) > -1){
                        let index = this.printCtrls.indexOf(5);
                        this.printCtrls.splice(index,1);
                    }
                }

                let _this = this;
                if(printDatas){
                    if(_this.ctrls.indexOf('m')>-1){
                        _this.$nextTick(()=>{
                         _this.$refs.easyPrint2.$nextTick(() => {
                                setTimeout(()=>{
                                    _this.$refs.easyPrint2.print();
                                }, 1500);
                            });
                        });
                    }else if(_this.ctrls.indexOf('n')>-1){
                        _this.$nextTick(()=>{
                         _this.$refs.easyPrint3.$nextTick(() => {
                                setTimeout(()=>{
                                    _this.$refs.easyPrint3.print();
                                }, 1500);
                            });
                        });
                    }else if(_this.ctrls.indexOf('u')>-1){
                        _this.$nextTick(()=>{
                         _this.$refs.easyPrint4.$nextTick(() => {
                                setTimeout(()=>{
                                    _this.$refs.easyPrint4.print();
                                }, 1500);
                            });
                        });
                    }else if(_this.ctrls.indexOf('i')>-1){
                        _this.$nextTick(()=>{
                         _this.$refs.easyPrint6.$nextTick(() => {
                                setTimeout(()=>{
                                    _this.$refs.easyPrint6.print();
                                }, 1500);
                            });
                        });
                    }else if(_this.ctrls.indexOf('v')>-1){
                        _this.$nextTick(()=>{
                         _this.$refs.easyPrint7.$nextTick(() => {
                                setTimeout(()=>{
                                    _this.$refs.easyPrint7.print();
                                }, 1500);
                            });
                        });
                    }else if(_this.ctrls.indexOf('w')>-1){
                        _this.$nextTick(()=>{
                         _this.$refs.easyPrint8.$nextTick(() => {
                                setTimeout(()=>{
                                    _this.$refs.easyPrint8.print();
                                }, 1500);
                            });
                        });
                    }else if(_this.ctrls.indexOf('s')>-1){
                        _this.$nextTick(()=>{
                         _this.$refs.easyPrint5.$nextTick(() => {
                                setTimeout(()=>{
                                    _this.$refs.easyPrint5.print();
                                }, 1500);
                            });
                        });
                    }else if(_this.ctrls.indexOf('r')>-1){
                        _this.$nextTick(()=>{
                         _this.$refs.easyPrintZdy.$nextTick(() => {
                                setTimeout(()=>{
                                    _this.$refs.easyPrintZdy.print();
                                }, 1500);
                            });
                        });
                    }else{
                        _this.$nextTick(()=>{
                             _this.$refs.easyPrint.$nextTick(() => {
                                setTimeout(()=>{
                                    _this.$refs.easyPrint.print();
                                }, 1500);
                            });
                        });
                    }
                }
            }
        },
        checkData(index, row){
            //TODO 配送不一致提醒
            //超票提醒
            if(this.form.gtTicket*1==1 && this.form.maxTickets*1>0 && this.form.pkgCode){
                // let total = this.pkgTicketTotal[this.form.pkgCode]*1;
                let total = this.pkgCodeTotal[this.form.pkgCode].length;
                if(total > this.form.maxTickets*1){
                    row.msg += "<span style='font-size:12px; color:red;'>袋号："+this.form.pkgCode+"当前票数"+total+"，超过了设定的最大票数："+this.form.maxTickets+" </span> ";
                    this.playList.push(gtQtyMp3);
                }else{
                    row.msg += "<span style='font-size:12px;'>袋号："+this.form.pkgCode+"当前票数为："+total+" </span>";
                }
            }

            return true;
        },
        endPlay(){
            if(this.playPosition >= this.playList.length){
                this.playPosition = 0;
                this.playList = [];
                this.playStatus = false;
            }else{
                this.playAudio();
            }
        },
        playAudio(){
            this.playStatus = true;
            let audio = this.$refs.audioRef;
            audio.src = this.playList[this.playPosition++];
            audio.play(); 
        },
        //添加袋号自动录入规则
        addPkgRule(){
            if(this.selectedRuleType && this.selectedRuleLength){

                this.pkgRules.push({
                    type: this.selectedRuleType == '数字'?0:1,
                    name: this.selectedRuleType,
                    len: this.selectedRuleLength,
                });

                this.buildPkgRule(-1);
                this.selectedRuleType = null;
                this.selectedRuleLength = null;

            }
        },
        buildPkgRule(index){
            if(index>-1){
                this.pkgRules.splice(index, 1);
            }
            if(this.pkgRules.length==0){
                this.pkgRuleRegExp = null;
                return;
            }

            let regStr = "";
            let len = 0;
            this.pkgRules.forEach(r => {
                regStr += (r.type == 0?"[0-9]":"[a-zA-Z]");
                regStr += "{"+r.len+"}";
                len += r.len;
            });
            
            this.pkgRuleRegExp = new RegExp(regStr);
            this.pkgRuleLength = len;

        },
        setPkgCode(){
            let pcode = this.form.code;
          
            this.$axios.get(this.$kit.api.stock.checkSaveBatchPkg+"?batchNo="+(this.form.batchCode?this.form.batchCode:'')+"&pkgNo="+pcode)
                .then((result) => {
                    if(result.data){
                        this.form.pkgCode = pcode;
                    }else{
                        this.$message(result.msg || this.msg('袋号已占用'))
                        this.form.pkgCode = null;
                        this.playList.push(Warning);
                        this.playAudio();
                    }
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.xmain .router-tab-page {
    height: calc(100vh - 100px);
}
.inout-box{
    box-sizing: border-box;
    padding: 15px 0px;
    height:100%;
}
.store-list{
    padding:0px; margin:0px;
}
.store-list li{
    list-style: none;
    cursor: pointer;
    padding:8px 5px;
}
.store-list li:hover{
    color: #409EFF;
    background: rgba(0,0,0,0.1);
}
.ctrl .el-checkbox{
    margin-left:5px; margin-right:5px; margin-bottom:5px;
}
.my-form .el-form-item{
    margin-bottom: 0px;
}
.exec-result{margin:10px; box-sizing: border-box; color: #06af06; font-size:12px;}

.exec-result > *{
    line-height: 150%; 
}
.msg-warning{
    margin:10px; box-sizing: border-box;font-size:12px;
}
.success{color: #06af06;}
.danger{color: #f5581a;}
.head-panel{background: rgba(0,0,0,0.1); margin: 5px 0px;}

.checkbox-inputer{
    display:inline-block; white-space:nowrap; margin-left:10px;
}
.inline-center{
    display:flex; align-items:center;
}
.inline-label{
    white-space:nowrap; margin-right: 8px; font-size:14px; color:#606266;
}
.pts-checkbox{
    margin: 5px 15px 5px 0px !important;
}
.weight-item /deep/ .el-form-item__label{
    margin-top:17px;
    margin-left:15px;
    font-size:22px; 
    position:relative;
}
.weight-inputer{
    background: black;
    color: white;
    border:none;
    outline: none;
    padding: 10px; 
    font-size:34px;
    font-weight:bold;
    width: 200px;
}
.weight-unit{
    display:inline-block;
    position:relative;
    top:-5px;
    font-size:22px; 
}
.video_div{
    display:inline-block;
    position: relative;
    margin: 0 auto;
    width: 400px;
}
.video_el{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
</style>