<template>
<el-form label-width="100px">
    <el-row>
        <div style="margin-left:20px;margin-top:10px;margin-bottom:20px;">
            <span>请在打开的页面进行采购，如采购完成，请点此：<el-button id="syb_finish_buy_btn" type="success" size="mini"  @click="finishBuy()">{{msg('采购完成')}}</el-button></span>
        </div>
    </el-row>
    <el-row>
        <el-col :xs="16" :sm="12">
            <el-form-item :label="msg('采购单号')" label-width="100px">
                <el-input 
                    v-model="code" style="margin-top:0px;" size="small">
                </el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="8" :sm="8">
            <el-form-item :label="msg('采购平台')" label-width="100px">
                <el-select v-model="type" placeholder="平台" size="mini">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <!-- <el-input 
                    v-model="type" style="margin-top:0px;" size="small">
                </el-input> -->
            </el-form-item>
        </el-col>
    </el-row>

    <div style="text-align:center; margin-top: 1px;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>  

    <el-row>
        <el-table :data="batchs" style="width: 100%;margin-top: 10px;" size="small" ref="dataTable" max-height="400" stripe>
          <el-table-column label="其他采购单号">
            <!-- <el-table-column type="selection" min-width="55"></el-table-column> -->
            <el-table-column prop="created" :label="msg('采购时间')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="code" :label="msg('采购单号')" min-width="130" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="title" :label="msg('标题')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="spec" :label="msg('规格')" min-width="80" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="price" :label="msg('金额')" min-width="60" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="type" :label="msg('平台')" min-width="60" :show-overflow-tooltip="true" sortable="true"></el-table-column>
            <el-table-column
                fixed="right"
                :label="msg('操作')"
                width="60">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">选择</el-button>
                </template>
            </el-table-column>
          </el-table-column>
        </el-table>
    </el-row> 

</el-form> 
</template>

<script>
export default {
  name: 'StockDetailPurchase',
  data(){
    return{
        batchs:[],
        code:null,
        sinceTime:null,
        type:null,
        created:null,
        price:null,
        options:[
            {label:'淘宝',value:'taobao'},
            {label:'1688',value:'alibaba'},
            {label:'拼多多',value:'pdd'},
        ]
    }
  }, 
  props: {
      idx:Number,
      idx1:Number,
    },
  computed:{
  },
  mounted: function(){
  },
  methods:{
      init(){
            this.batchs= [];
            this.code = null;
            this.type = null;
            this.created = null;
            this.price = null,
            this.sinceTime = new Date();
      },
    onSubmit(){
        // var selectes = this.$refs.dataTable.selection;

        // let pc = '';
        // if(selectes.length > 0){
        //     pc = selectes.map(s=>s.expBatch).join(",");
        // }
        if(!this.code){
            this.$message(this.msg('订单编号不能为空'));
        }
        if(!this.type){
            this.$message(this.msg('平台不能为空'));
        }
        this.$emit("close-dg",
            {
                code:this.code,
                type:this.type,
                price:this.price,
                created:this.created,
                idx:this.idx,
                idx1:this.idx1
            });
    },
    finishBuy(){
        var _this = this;
        window.OKK_Orders.queryOrdersSince(0, function(results){
            // console.log(results);
            if(results && results.length > 0){
                // let created = null;
                // if(results[0].createTime){
                //     var unixTimestamp = new Date(results[0].createTime) ;
                //     created = unixTimestamp.getFullYear() + "-" + (unixTimestamp.getMonth() + 1) + "-" + unixTimestamp.getDate() + " " + unixTimestamp.getHours() + ":" + unixTimestamp.getMinutes() + ":" + unixTimestamp.getSeconds();
                // }
                // _this.code = results[0].id;
                // _this.type = results[0].type;
                // _this.created = created;
                results.forEach(e => {
                    let spec = null;
                    if(e.more.subOrders && e.more.subOrders[0].itemInfo.skuText[0]){
                        spec = e.more.subOrders[0].itemInfo.skuText[0].name+':'+e.more.subOrders[0].itemInfo.skuText[0].value;
                    }
                    let created = null;
                    if(e.createTime){
                        var unixTimestamp = new Date(e.createTime) ;
                        created = unixTimestamp.getFullYear() + "-" + (unixTimestamp.getMonth() + 1) + "-" + unixTimestamp.getDate() + " " + unixTimestamp.getHours() + ":" + unixTimestamp.getMinutes() + ":" + unixTimestamp.getSeconds();
                    }
                    let price;
                    if(e.type == "taobao"){
                        if(e.more.payInfo && e.more.payInfo.actualFee){
                            price = e.more.payInfo.actualFee;
                        }
                    }else if(e.type == "alibaba"){
                        if(e.more && e.more.totalPrice){
                            price = e.more.totalPrice;
                        }
                    }else if(e.type == "pdd"){
                        if(e.more && e.more.orderAmount){
                            price = e.more.orderAmount;
                        }
                    }
                    if(created == null){
                        created = new Date();
                    }
                    let res = {
                        code:e.id,
                        title:e.title,
                        spec:spec,
                        price:price,
                        created:created,
                        type:e.type
                    }
                    _this.batchs.push(res);
                });
                //将batchs按照时间顺序进行排序
                let len = _this.batchs.length;
                for (let i = 0; i < len; i++) {
                    for (let j = 0; j < len - 1- i; j++) {
                        let batch1 = _this.batchs[j];
                        let batch2 = _this.batchs[j+1];
                        var oDate1 = new Date(batch1.created);
                        var oDate2 = new Date(batch2.created);
                        if(oDate1.getTime() < oDate2.getTime()){
                            let temp = _this.batchs[j];
                            _this.batchs[j] = _this.batchs[j+1];
                            _this.batchs[j+1] = temp;
                        }
                    }
                }
                _this.code = _this.batchs[0].code;
                _this.type = _this.batchs[0].type;
                _this.price = _this.batchs[0].price;
                _this.created = _this.batchs[0].created;
            }
        });
    },
    handleClick(row){
        this.code = row.code;
        this.type = row.type;
        this.created = row.created;
        this.price = row.price;
    },

    
  }
}
</script>

<style scoped lang="scss">

</style>
