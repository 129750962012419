<!-- 详情分拆-->
<template>
<div class="content-panel">
    <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
        <el-col :span="20">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('库存管理')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{msg('详情分拆')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
    </el-row>
    <div class="table-wrap" :style="{height: (docHeight)+'px'}">
        <vxe-table
          border
          resizable
          :scroll-y="{gt: -1}"
          :span-method="mergeRowMethod"
          :data="datas">
          <vxe-table-column field="code" title="订单编号" min-width="100"></vxe-table-column>
          <vxe-table-column field="productTitle" title="商品标题" min-width="180"></vxe-table-column>
          <vxe-table-column field="productSpec" title="规格" min-width="120"></vxe-table-column>
          <vxe-table-column field="productQty" title="件数" min-width="60"></vxe-table-column>
          <vxe-table-column field="sku" title="SKU" min-width="60"></vxe-table-column>
          <vxe-table-column field="variationSku" title="货号" min-width="60"></vxe-table-column>
          <vxe-table-column field="" title="操作" min-width="60">
              <template v-slot="{ row,rowIndex }">
                <el-button type="primary" size="mini" @click="doSplit(row,rowIndex)">分拆</el-button>
            </template>
          </vxe-table-column>
        </vxe-table>
    </div>

    <el-dialog :title="msg('详情分拆')" :visible.sync="splitVisiable" :fullscreen="isMinScreen" @opened="openSplitDg"
        :close-on-click-modal="false" 
            width="600px">
        <stock-detail-split-dialog ref="splitDg" :detail="stockDetail" :qty="qty"  @close-dg="closeSplitDg"/>
    </el-dialog> 
</div>
</template>

<script>
import XEUtils from 'xe-utils';
import StockDetailSplitDialog from "./StockDetailSplitDialog.vue";
export default {
    name: "StockDetailSplit",
    components: {
        StockDetailSplitDialog:StockDetailSplitDialog
    },
    mounted(){
        this.init();
    },
    data(){
        return {
            datas: [],
            ids:null,
            stockDetail:{
                productTitle:null,
                productSpec:null,
                productQty:0,
                sku:null,
                variationSku:null,
                creater:0,
                code:null,
                id:null,
                stockId:null,
                index: null,
            },
            qty:0,
            splitVisiable:false,
            detail:null,
        }
    },
    computed: {
        isMinScreen: { //是否小屏幕，浏览器
            get() {
                return this.$store.state.isMinScreen;
            }
        },
        docHeight: { //表格的高度
            get() {
                var height = this.$store.state.docHeight;
                return height - 165;
            }
        }
    },
    methods:{
        init(){
            this.ids = window.localStorage.getItem("splitStockIds");
            this.getData();
        },
         // 通用行合并函数（将相同多列数据合并为一行）
        mergeRowMethod ({ row, _rowIndex, column, visibleData }) {

            const fields = ['code']
            const cellValue = XEUtils.get(row, column.property)
            if (cellValue && fields.includes(column.property)) {
                const prevRow = visibleData[_rowIndex - 1]
                let nextRow = visibleData[_rowIndex + 1]
                if (prevRow && XEUtils.get(prevRow, column.property) === cellValue) {
                    return { rowspan: 0, colspan: 0 }
                } else {
                    let countRowspan = 1
                    while (nextRow && XEUtils.get(nextRow, column.property) === cellValue) {
                        nextRow = visibleData[++countRowspan + _rowIndex]
                    }
                    if (countRowspan > 1) {
                        return { rowspan: countRowspan, colspan: 1 }
                    }
                }
            }
        },
        getData(){
            this.$axios.post(this.$kit.api.stock.stockSplit, this.ids)
                .then((result) => {
                    if(result.status){
                        let ds = result.data;
                        this.datas = ds;
                    }
                });
        },
        doSplit(row,index){
            this.stockDetail.productTitle = row.productTitle;
            this.stockDetail.productSpec = row.productSpec;
            this.stockDetail.productQty = row.productQty;
            this.stockDetail.sku = row.sku;
            this.stockDetail.variationSku = row.variationSku;
            this.stockDetail.creater = row.creater;
            this.stockDetail.code = row.code;
            this.stockDetail.id = row.id;
            this.stockDetail.stockId = row.stockId;
            this.stockDetail.index = index;
            this.qty = row.productQty;
            this.splitVisiable = true;
        },
        openSplitDg(){
            this.$nextTick(() => {
                this.$refs.splitDg.init();
            });
        },
        closeSplitDg(data){
            let detail = data.detail;
            let newQty = data.newQty;
            if(data.detail){
                this.datas.splice(detail.index+1,0,detail);
                this.datas[detail.index].productQty = newQty;
            }
            this.splitVisiable = false;
        },
    }
}
</script>

<style lang="scss" scoped>

.table-wrap{
    width:100%;
    overflow:auto;
}

@media screen and (min-width: 1200px) {
    body {
        overflow:auto;
    }
}


.th1 {width: 100px;}
.th2 {width: 120px;}
.th3 {width: 120px;}
.th4 {width: 120px; word-break: break-all;}
.th5 {width: 75px;}
.th6 {width: 150px;}
.th7 {width: 60px; word-break: break-all;}
.th8 {width: 100px; word-break: break-all;}
.th9 {width: 100px; word-break: break-all;}
</style>
