<!-- 入库出库 -->
<template>
<el-form ref="form" label-width="100px">
    <el-form-item :label="msg('入库件数')">
        <el-input type="number" v-model="productQty"></el-input>
    </el-form-item>
    <el-form-item :label="msg('备注')">
        <el-input v-model="remark"></el-input>
    </el-form-item>
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" @click="click">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'stockSplit',
  data(){
    return{
        productQty:0,
        remark:null,
    }
    
  }, 
  computed:{
  },
  props:{
      id:Number,
  },
  mounted: function(){
  },
  methods:{
    init(){
        this.productQty = null;
        this.remark = null;
    },
    onSubmit(){

        if(!this.productQty){
            this.$message(this.msg('请输入入库件数'));
            return;
        }
        let reg = new RegExp("^[0-9]*$");
        if(!reg.test(this.productQty)){
            this.$message(this.msg('格式不正确，只能输入数字'));
            return;
        }

        if(this.remark && this.remark.length > 25){
            this.$message(this.msg('备注内容过长'));
            return;
        }

        let param = {
            id : this.id,
            productQty : this.productQty,
            remark : this.remark,
            type: 0,
        }

        this.$axios.post(this.$kit.api.stock.updateQty, param)
        .then((result) => {
            if(result.status){
                this.$message(result.msg || this.msg('更新成功'));
                this.$emit("close-dg", true)
            }else{
                this.$message(result.msg || this.msg('更新失败'));
            }
        });
    },
    click(){
        this.$emit("close-dg", true)
    }
  },
  
}
</script>

<style scoped lang="scss">


</style>
