<!-- 货物采购 -->
<template>
  <div>
        <ctrl-box class="ctrl-box">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="list">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shuaxin1" font-size="27px;"/>
                            <span class="txt" >{{msg('刷新')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="doAccount()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-zongshu" font-size="27px;"/>
                            <span class="txt">{{msg('统计')}}</span>
                        </div>
                    </el-link>
                    <el-link :underline="false" @click="buy()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-huowucaigou" font-size="27px;"/>
                            <span class="txt">{{msg('采购')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="stockDetail()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-yulan" font-size="27px;"/>
                            <span class="txt">{{msg('预览详情')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="updateInnerCode()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-kuaidiguanli" font-size="27px;"/>
                            <span class="txt">{{msg('快递单号')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="updatePurchaseCode()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-procurement-copy" font-size="27px;"/>
                            <span class="txt">{{msg('采购单号')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="updateRemark(2)">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">{{msg('货单备注')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="updateRemark(1)">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-xiugai" font-size="27px;"/>
                            <span class="txt">{{msg('报单备注')}}</span>
                        </div>
                    </el-link>
                    <!-- 
                    <el-link :underline="false" @click="addDetail()">
                        <div class="wrap" >
                            <i class="iconfont icon-jiajianfen font22" ></i>
                            <span class="txt">{{msg('新增详情')}}</span>
                        </div>
                    </el-link>
                    <el-link :underline="false" @click="deleteDetail()">
                        <div class="wrap" >
                            <i class="iconfont icon-huodongguanli font22" ></i>
                            <span class="txt">{{msg('删除详情')}}</span>
                        </div>
                    </el-link>
                    -->
                    <el-link :underline="false" @click="doExport()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daochu2" font-size="27px;"/>
                            <span class="txt">{{msg('导出')}}</span>
                        </div>
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                export-config
                resizable border="full"
                size="medium"
                align="center"
                :cell-style="{width:'80px;',height:'80px'}"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="stockDetails"
                :span-method="mergeRowMethod"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>
        </div>

        <el-dialog :title="msg('查看订单')" :visible.sync="viewVisible" :fullscreen="isMinScreen" @opened="openedView"
            :close-on-click-modal="false" 
                width="850px">
            <buy-view ref="viewRef" :ids="detailIds" @close-dg="closeViewDg" />
        </el-dialog>

        <summary-detail
            :checked = "countModel.checked"
            :count = "countModel.count"
            :qty = "countModel.qty"
            :visible.sync = "countVisiable"
        />

        <el-dialog
            :title="msg('更新采购单号')"
            :visible.sync="purCodeVisiable"
            :close-on-click-modal="false"
            size="mini"
            width="40%">
            <el-form ref="form" label-width="100px">
                <el-form-item :label="msg('采购单号：')">
                    <el-input v-model="purchaseCode" :placeholder="msg('请输入采购单号')"></el-input>
                </el-form-item>
                <el-form-item :label="msg('采购平台：')">
                    <el-select v-model="purchasePlatform" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="purCodeVisiable = false">{{msg('取消')}}</el-button>
                <el-button type="primary" @click="doSavePurchase()">{{msg('确定')}}</el-button>
            </span>
        </el-dialog>

        <el-dialog
            :title="msg('采购')"
            :visible.sync="buytVisiable"
            :close-on-click-modal="false"
            size="mini"
            width="40%">
            <el-form ref="form" label-width="100px">
                <el-form-item :label="msg('采购链接：')">
                    <el-input v-model="purchaseLink" :placeholder="msg('请输入采购链接')"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="buytVisiable = false">{{msg('取消')}}</el-button>
                <el-button type="primary" @click="doBuy()">{{msg('采购')}}</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="msg('更新快递单号')" :visible.sync="innerCodeVisible" :fullscreen="isMinScreen" @opened="openedInnerCode"
            :close-on-click-modal="false" 
                width="70%">
            <stock-detail-buy-inner-code ref="innerCodeRef" @close-dg="closeInnerCodeDg" />
        </el-dialog>

        <el-dialog :title="msg('采购单号')" :visible.sync="purchaseVisiable" :fullscreen="isMinScreen"  @opened="openPurchasDg"
            :close-on-click-modal="false"
                width="60%">
            <stock-detail-purchase ref="purchasDg" :idx="0" :idx1="0"  @close-dg="closePurchasDg"/>
        </el-dialog>

        <el-dialog :title="msg('新增')" :visible.sync="addDetailVisiable" :fullscreen="isMinScreen"  @opened="openAddDg"
            :close-on-click-modal="false" 
                width="60%">
            <stock-detail-add ref="addDetailDg" :id="addStockId"  @close-dg="closeAddDg"/>
        </el-dialog>

        <el-dialog :title="msg('查看库存')" :visible.sync="storeVisiable" :fullscreen="isMinScreen"  @opened="openStoreDg"
            :close-on-click-modal="false" 
                width="80%">
            <stock-detail-check ref="storeDg" :detailId="checkId"  @close-dg="closeStoreDg"/>
        </el-dialog>

    </div>  
</template>
<script>

import CtrlBox from "../../components/CtrlBox.vue";
import StockDetailBuyInnerCode from "./StockDetailBuyInnerCode.vue";
import StockDetailAdd from "./StockDetailAdd.vue";
import StockDetailCheck from "./StockDetailCheck.vue";
import StockDetailPurchase from "./StockDetailPurchase.vue";
import DetailBuyService from "../order/DetailBuyService";
import SummaryDetail from "../order/SummaryDetail.vue";
export default {
    name: 'StockDetailBuy',
    components:{
        CtrlBox: CtrlBox,
        StockDetailBuyInnerCode:StockDetailBuyInnerCode,
        StockDetailAdd:StockDetailAdd,
        StockDetailCheck:StockDetailCheck,
        StockDetailPurchase:StockDetailPurchase,
        SummaryDetail : SummaryDetail
    },
    mounted:function(){
        this.init();
    },
    data(){
        return {
            showQuery: false,
            curr: null,
            columns: [],
            stockDetails: [],
            viewVisible:false,
            detailIds:null,
            ids: null,
            countVisiable:false,
            innerCodeVisible:false,
            innerCodePara:null,
            countModel:{
                checked:0,
                count:0,
                qty:0
            },
            options:[
                {label:'淘宝',value:'taobao'},
                {label:'1688',value:'alibaba'},
                {label:'拼多多',value:'pdd'},
            ],
            purchaseCode:null,
            purchasePlatform:null,
            purchaseTime:null,
            purchaseCost:null,
            purCodeVisiable:false,
            purchaseVisiable:false,
            addDetailVisiable:false,
            addStockId:null,
            storeVisiable:false,
            checkId:null,
            buytVisiable:false,
            purchaseLink:null,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
    },
    methods: {
        init(){
            this.ids = window.localStorage.getItem("viewDetailIdsNew");
            this.buildColumns();
            this.list();
        },
        buildColumns(){
            this.columns = [
                {type: 'checkbox', minWidth: 40,},
                {field: 'id', title: this.msg('ID'), minWidth: 60, sortable: true},
                {field: 'code', title: this.msg('订单编号'), minWidth: 160, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'productThumb', title: this.msg('商品图'), width: 120, sortable: true,
                    slots: {
                        default: ({ row }) => {
                            let thumb = row.productThumb
                            return thumb?[
                                <el-image  style="width: 80px;height:80px" src={this.$kit.api.imgView+thumb} preview-src-list={[this.$kit.api.imgView+thumb]}></el-image>
                            ]: [thumb];
                        },
                    }
                },
                { title: this.msg('操作'), width: 130,
                    slots: {
                        default: ({ row }) => {
                            return [
                                    <span class="id-cell1"><i class="add-cell el-icon-circle-plus-outline">新增</i></span>,
                                    <span class="id-cell2"><i class="delete-cell el-icon-delete">删除</i></span>,
                                    <br/>,
                                    <span class="id-cell3"><i class="view-cell el-icon-zoom-in">检查库存</i></span>,
                                    // <i class="delete-cell el-icon-delete"><vxe-button type="text" icon="el-icon-delete">删除</vxe-button></i>
                                ]
                        },
                    }
                },
                {field: 'status', title: this.msg('货态'), minWidth: 80, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                    slots: {
                            default: ({ row }) => {
                                let type = this.dicts.stockStatus.find(d => d.value === row.status);
                                return [
                                    <el-tag type={type?type.type:''}>{type?type.label:row.status}</el-tag>
                                ];
                            },
                        }
                },
                {field: 'innerExpCode', title: this.msg('快递单号'), minWidth: 160, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'},
                    slots: {
                            default: ({ row }) => {
                                return [
                                    <span>{row.innerExpCode}</span>,
                                    <br/>,
                                    <span>{row.shelfCode}</span>
                                ];
                            },
                        }
                },
                {field: 'purchaseStatus', title: this.msg('采购状态'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                    slots: {
                            default: ({ row }) => {
                                let type = this.dicts.purchaseStatuses.find(d => d.value === row.purchaseStatus);
                                return [
                                    <el-tag type={type?type.type:''}>{type?type.label:row.purchaseStatus}</el-tag>
                                ];
                            },
                        }
                },
                {field: 'hasLink', title: this.msg('链接'), minWidth: 60,sortable: true,
                    slots: {
                            default: ({ row }) => {
                                return [
                                    row.hasLink==1?<i class="el-icon-link"></i>:""
                                ];
                            },
                        }
                },
                {field: 'purchaseCode', title: this.msg('采购单号'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'},
                    slots: {
                            default: ({ row }) => {
                                return [
                                    <span>{row.purchaseCode}</span>,
                                    <br/>,
                                    <span>{row.purchasePlatform}</span>
                                ];
                            },
                        }
                },
                {field: 'remark1', title: this.msg('货单/报单备注'), minWidth: 160, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'},
                    slots: {
                            default: ({ row }) => {
                                return [
                                    <span style="color: #67C23A">{row.remark1}</span>,
                                    <br/>,
                                    <span style="color: #F56C6C">{row.remark2}</span>
                                ];
                            },
                        }
                },
                {field: 'shopSellerMsg', title: this.msg('买家/卖家备注'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'},
                    slots: {
                            default: ({ row }) => {
                                return [
                                    <span>{row.shopSellerMsg}</span>,
                                    <br/>,
                                    <span>{row.note}</span>
                                ];
                            },
                        }
                },
                {field: 'productQty', title: this.msg('数量'), minWidth: 60, sortable: true,
                    filters:[{data: {type: 'has', isCase: false, name: ''}}],
                    filterRender:{name: 'FilterComplex'},
                    slots: {
                            default: ({ row }) => {
                                return [
                                    <span>{row.productQty}</span>,
                                    <br/>,
                                    <span>${row.productPrice?(row.productPrice*0.01).toFixed(2):0}</span>
                                ];
                            },
                        }
                },
                {field: 'sku', title: this.msg('SKU'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: 'variationSku', title: this.msg('货号'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: 'productSpec', title: this.msg('规格'), minWidth: 90, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: 'productTitle', title: this.msg('商品标题'), minWidth: 90, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: 'detailQty', title: this.msg('品类'), minWidth: 60, sortable: true,
                    filters:[{data: {type: 'has', isCase: false, name: ''}}],
                    filterRender:{name: 'FilterComplex'}
                },
                {field: 'created', title: this.msg('下单时间'), minWidth: 120, sortable: true,
                    filters:[{data: []}],
                    filterRender:{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                },
                {field: 'shopDayToShip', title: this.msg('最后出货时间'), minWidth: 130, sortable: true,
                    filters:[{data: []}],
                    filterRender:{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                },
            ]
        },
        list(){
            this.$axios.post(this.$kit.api.stockDetail.detailBuyList, {ids:this.ids})
                .then((result) => {
                    if(result.status){
                        this.stockDetails = result.data;
                        // this.query.pageTotal = result.data.total;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        //点击行，选中行
        checkRow({row,rowIndex, $event, column, columnIndex}){
            //如果点击checkbox 则不处理
            let className = $event.target.className;
            // console.log(className);
            if(columnIndex > 0){  //选择 
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
            if(className.indexOf('add-cell') > -1){
                this.addDetail(row);
            }
            if(className.indexOf('delete-cell') > -1){
                this.deleteDetail(row);
            }
            if(className.indexOf('view-cell') > -1){
                this.checkStore(row);
            }
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        accetReport(){//报单受理
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let ids = '';
            selectes.forEach(e => {
                ids += e.ids+',';
            });
            this.$confirm('是否确认受理?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post(this.$kit.api.stock.acceptReport, ids)
                .then((result) => {
                    if(result.status){
                        // let data = result.data;
                        // selectes.forEach(e => {
                        //     e.batch = data.batch;
                        // });
                        this.list();
                        this.$message(this.msg('受理成功'));
                    }else{
                        this.$message(this.msg('受理失败'));
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消'
                });          
            });
        },
        cancelReport(){//撤销受理
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let ids = '';
            selectes.forEach(e => {
                ids += e.ids+',';
            });
            this.$confirm('是否确认撤销受理?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post(this.$kit.api.stock.cancelReport, ids)
                .then((result) => {
                    if(result.status){
                        // let data = result.data;
                        // selectes.forEach(e => {
                        //     e.batch = '';
                        // });
                        this.list();
                        this.$message(this.msg('撤销成功'));
                    }else{
                        this.$message(this.msg('撤销失败'));
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消'
                });          
            });
        },
        stockView(row){//查看订单
            this.detailIds = row.ids;
            this.viewVisible = true;
        },
        openedView(){
            this.$nextTick(() => {
                this.$refs.viewRef.init();
            });
        },
        closeViewDg(){
            this.viewVisible = false;
        },
        inputCode(row){//输入快递单号
            this.$prompt('请输入快递单号', '提示', {
                closeOnClickModal:false,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                if(!value){
                    this.$message(this.msg('请输入快递单号'));
                    return;
                }
                let param = {
                    ids : row.ids,
                    innerExpCode : value
                }
                this.$axios.post(this.$kit.api.stock.detailBuyInputCode, param)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        row.innerExpCode = value;
                        if(data.shelfCode){
                            row.shelfCode = data.shelfCode;
                        }
                        if(data.upperDate){
                            row.upperDate = data.upperDate;
                        }
                        this.$message(result.msg || this.msg('更新成功'));
                    }else{
                        this.$message(result.msg || this.msg('更新失败'));
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });       
            });
        },
        inputShelfCode(row){
            this.$prompt('请输入架位号', '提示', {
                closeOnClickModal:false,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                if(!value){
                    this.$message(this.msg('请输入架位号'));
                    return;
                }
                let param = {
                    ids : row.ids,
                    shelfCode : value
                }
                this.$axios.post(this.$kit.api.stock.detailBuyInputShelfCode, param)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        row.shelfCode = value;
                        row.upperDate = data;
                        this.$message(result.msg || this.msg('更新成功'));
                    }else{
                        this.$message(result.msg || this.msg('更新失败'));
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });       
            });
        },
        updateMark(row){//点到
            this.$axios.post(this.$kit.api.stock.updateMark, row.ids)
                .then((result) => {
                    if(result.status){
                        row.mark = 1;
                        this.$message(result.msg || this.msg('更新成功'));
                    }else{
                        this.$message(result.msg || this.msg('更新失败'));
                    }
                });
        },
        doAccount(){//统计
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            let count = DetailBuyService.summary(selectes);
            if(count){
                this.countModel = count;
                this.countVisiable = true;
            }
        },
        // 通用行合并函数（将相同多列数据合并为一行）
        mergeRowMethod ({ row, _rowIndex, column, visibleData }) {
            const fields = ['code']
            const cellValue = row[column.property]
            if (cellValue && fields.includes(column.property)) {
            const prevRow = visibleData[_rowIndex - 1]
            let nextRow = visibleData[_rowIndex + 1]
            if (prevRow && prevRow[column.property] === cellValue) {
                return { rowspan: 0, colspan: 0 }
            } else {
                let countRowspan = 1
                while (nextRow && nextRow[column.property] === cellValue) {
                nextRow = visibleData[++countRowspan + _rowIndex]
                }
                if (countRowspan > 1) {
                return { rowspan: countRowspan, colspan: 1 }
                }
            }
            }
        },
        //预览详情
        stockDetail(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            let ids = selectes.map(e => e.stockId).join(",");
            window.localStorage.setItem("viewDetailIds", ids);
            this.$tabs.open("/admin/stock/stock-detail");
        },
        buy(){
            if(!this.hasPermission("stock:detailPurchase")){
                this.$message(this.msg('没有权限或非超级会员，不能进行快捷采购！'));
                return;
            }
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            var _this = this;
            let para = [];
            selectes.forEach(e=>{
                para.push({
                    id:e.id,
                    stockId:e.stockId,
                    link:this.purchaseLink?this.purchaseLink:""
                })
            })
            //点击采购时，如有采购链接，直接跳转到采购网址，如没有采购链接的，则再弹出链接录入框
            this.$axios.post(this.$kit.api.stockDetail.openBuyLinks,para
                ).then((result) => {
                    if(result.status){
                        window.open(result.data,"_blank")
                        //弹出一个新页面
                        this.buytVisiable = false;
                        this.purchaseVisiable = true;
                    }else{
                        this.purchaseLink = null;
                        this.buytVisiable = true;
                    }
                });

        },
        doBuy(){//采购
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            var _this = this;
            let para = [];
            selectes.forEach(e=>{
                para.push({
                    id:e.id,
                    stockId:e.stockId,
                    link:this.purchaseLink?this.purchaseLink:""
                })
            })
            //根据详情标题，搜索采购链接表，查找对应链接
            //如果找到，则打开新链接
            //如果没找到，则提示输入链接
            this.$axios.post(this.$kit.api.stockDetail.openBuyLinks,para
                ).then((result) => {
                    if(result.status){
                        window.open(result.data,"_blank")
                        //弹出一个新页面
                        this.buytVisiable = false;
                        this.purchaseVisiable = true;
                    }else{
                        // this.$alert(this.msg('未匹配到采购链接，请自行输入链接后再次采购'), this.msg('提示'), {
                        //     confirmButtonText: this.msg('确定'),
                        // });
                        this.$message({
                            type: 'info',
                            message: result.msg?result.msg:"未匹配到采购链接，请自行输入链接后再次采购"
                        });
                    }
                });

        },
        openPurchasDg(){
            this.$nextTick(() => {
                this.$refs.purchasDg.init();
            });
        },
        closePurchasDg(data){
            this.purchaseVisiable = false;
            this.purchaseCode = data.code;
            this.purchaseTime = data.created;
            this.purchasePlatform = data.type;
            this.purchaseCost = data.price;
            this.doSavePurchase();
        },
        //快递单号
        updateInnerCode(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let para = [];
            selectes.forEach(e=> {
                para.push({
                    detailId:e.id,
                    stockId:e.stockId,
                    code:e.code,
                })
            })
            this.innerCodePara = para;
            this.innerCodeVisible = true;
        },
        openedInnerCode(){
            this.$nextTick(() => {
                this.$refs.innerCodeRef.init(this.innerCodePara);
            });
        },
        closeInnerCodeDg(){
            this.innerCodeVisible = false;
            this.list();
        },
        //采购单号
        updatePurchaseCode(){
            if(!this.hasPermission("stock:detailPurchase")){
                this.$message(this.msg('没有权限或非超级会员，不能进行快捷采购！'));
                return;
            }

            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            this.purchaseCode = null;
            this.purchasePlatform = null;
            this.purCodeVisiable = true;
        },
        doSavePurchase(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            if(!this.purchaseCode){
                this.$message(this.msg('采购单号不存在'));
                return;
            }
            if(!this.purchasePlatform){
                this.$message(this.msg('请选择采购平台'));
                return;
            }
            let cost = 0;
            let resCost = this.purchaseCost;
            if(resCost){
                cost = (resCost*100).toFixed(0);
            }
            let para = [];
            selectes.forEach(e=>{
                para.push({
                    id:e.id,
                    stockId:e.stockId
                })
            })
            this.$axios.post(this.$kit.api.stockDetail.updateDetailPurchaseCodes
                        +"?code="+this.purchaseCode
                        +"&type="+this.purchasePlatform
                        +"&created="+this.purchaseTime
                        +"&cost="+cost,
                        para
            ).then((result) => {
                if(result.status){
                    this.purCodeVisiable = false;
                    this.list();
                }
                this.$message(result.msg);
            });
            // this.$confirm(this.msg('保存采购单号？'), this.msg('提示'), {
            //     confirmButtonText: this.msg('确定'),
            //     cancelButtonText: this.msg('取消'),
            // }).then(() => {
            // }).catch(() => {
            //     this.list();
            //     this.$message({
            //         type: 'info',
            //         message: this.msg('取消'),
            //     });
            // });
        },
        //货单备注/报单备注
        updateRemark(type){
            if(!this.hasPermission("stock:detailEdit")){
                this.$message(this.msg('无操作权限'));
                return;
            }

            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            let label = "";
            if(type == 1){
                label = '请输入报单备注';
            }else{
                label = '请输入货单备注';
            }
            let ids = selectes.map(e=> e.stockId).join(',');

            this.$prompt(label, this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
            }).then(({ value }) => {
                this.$axios.post(this.$kit.api.stock.updateRemarks+"?t="+type, {ids:ids,remark:value?value:''})
                    .then((result) => {
                        if(result.status){
                            this.list();
                            this.$message(result.msg);
                        }else{
                            this.$message(result.msg?result.msg:this.msg('更新失败'));
                        }
                    });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        //新增详情
        addDetail(row){

            let status = row.status;
            this.addStockId = row.stockId;
            if(!this.hasPermission("stock:detailEdit")){
                this.$message(this.msg('无操作权限'));
                return;
            }
            if(status >= 13 && status <= 23 && status != 20){
                this.addDetailVisiable = true;
            }else{
                if(this.hasPermission("stock:detailAdd")){
                    this.addDetailVisiable = true;
                }else{
                    this.$message(this.msg('该订单不能新增'));
                }
            }
        },
        openAddDg(){
            this.$nextTick(() => {
                this.$refs.addDetailDg.init();
            });
        },
        closeAddDg(data){
            this.list();
            this.addDetailVisiable = false;
        },
        //删除详情
        deleteDetail(row){

            if(!this.hasPermission("stock:detailEdit")){
                this.$message(this.msg('无操作权限'));
                return;
            }

            let stockId = row.stockId;
            let i = 0;
            this.stockDetails.forEach(e=>{
                if(stockId == e.stockId){
                    i++;
                }
            })
            if(i <= 1){
                this.$message(this.msg("必须保留一条详情"));
                return;
            }

            let status = row.status;
            if(status >= 13 && status <= 23){
                this.doDelete(row);
            }else{
                if(this.hasPermission("stock:detailAddDelete")){
                    this.doDelete(row);
                }else{
                    this.$message(this.msg('该订单不能删除'));
                }
            }
        },
        doDelete(row){
            // var selectes = this.$refs.dataTable.getCheckboxRecords();
            // if(selectes.length == 0){
            //     this.$message(this.msg('请选中一条数据进行操作'));
            //     return;
            // }
            let detailId = row.id;
            var _this = this;
            this.$axios.post(this.$kit.api.stock.deleteDetail+'?id='+detailId)
                .then((result)=>{
                    if(result.status){
                        _this.list();
                        _this.$message(result.msg || _this.msg("删除成功"));
                    }else{
                        _this.$message(result.msg || _this.msg("删除失败"));
                    }
                });
        },
        checkStore(row){
            this.checkId = row.id;
            this.storeVisiable = true;
        },
        openStoreDg(){
            this.$nextTick(() => {
                this.$refs.storeDg.init();
            });
        },
        closeStoreDg(){
            this.storeVisiable = false;
        },
    },
}
</script>

<style lang="scss" scoped>
.add-cell{cursor:pointer; display:inline-block; margin-right:10px;}
.delete-cell{cursor:pointer; display:inline-block; margin-right:10px;}
.view-cell{cursor:pointer; display:inline-block; margin-right:1px;}
.view-edit{cursor:pointer; display:inline-block;}
.mark-cell{cursor:pointer; display:inline-block;color:red;margin-right: 5px;}
.remove-cell{cursor:pointer; display:inline-block; color:red;}
</style>
