<!-- 入库出库-->
<template>
<div class="content-panel">
    <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
        <el-col :span="20">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('库存管理')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{msg('入库出库')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
    </el-row>
    <div class="table-wrap" :style="{height: (docHeight)+'px'}">
        <vxe-grid ref="dataTable" :height="docHeight" 
            export-config
            resizable border="full"
            size="mini"
            show-overflow highlight-hover-row
            highlight-current-row highlight-hover-column highlight-current-column
            :data="datas"
            :columns="columns"
            @cell-click="checkRow"
        ></vxe-grid>
    </div>

    <el-dialog :title="msg('入库')" :visible.sync="qtyVisiable" :fullscreen="isMinScreen" @opened="openQtyDg"
        :close-on-click-modal="false" 
            width="600px">
        <stock-qty-edit-dialog ref="qtyDg" :id="detailId"  @close-dg="closeQtyDg"/>
    </el-dialog> 

    <el-dialog :title="msg('出库')" :visible.sync="qty1Visiable" :fullscreen="isMinScreen" @opened="openQty1Dg"
        :close-on-click-modal="false" 
            width="600px">
        <stock-qty-edit-dialog1 ref="qty1Dg" :id="detailId"  @close-dg="closeQty1Dg"/>
    </el-dialog> 

</div>
</template>

<script>
import StockQtyEditDialog from "./StockQtyEditDialog.vue";
import StockQtyEditDialog1 from "./StockQtyEditDialog1.vue";
export default {
    name: "StockQtyEdit",
    components: {
        StockQtyEditDialog:StockQtyEditDialog,
        StockQtyEditDialog1:StockQtyEditDialog1
    },
    mounted(){
        this.init();
    },
    data(){
        return {
            datas: [],
            ids: null,
            columns: [],
            qtyVisiable:false,
            qty1Visiable:false,
            detailId:null,
        }
    },
    computed: {
        isMinScreen: { //是否小屏幕，浏览器
            get() {
                return this.$store.state.isMinScreen;
            }
        },
        docHeight: { //表格的高度
            get() {
                var height = this.$store.state.docHeight;
                return height - 220;
            }
        }
    },
    methods:{
        init(){
            this.ids = window.localStorage.getItem("qtyEditIds");
            this.buildColumns();
            this.getData();
        },
        openQtyDg(){
            this.$nextTick(() => {
                this.$refs.qtyDg.init();
            });
        },
        openQty1Dg(){
            this.$nextTick(() => {
                this.$refs.qty1Dg.init();
            });
        },
        closeQtyDg(){
            this.qtyVisiable = false;
            this.detailId = null;
            this.getData();
        },
        closeQty1Dg(){
            this.qty1Visiable = false;
            this.detailId = null;
            this.getData();
        },
        buildColumns(){
            this.columns = [
                {type: 'seq', minWidth: 40,},
                {field: 'orderCode', title: this.msg('订单编号'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'productTitle', title: this.msg('货物标题'), minWidth: 160, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'productSpec', title: this.msg('规格'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'productQty', title: this.msg('库存数'), minWidth: 80, sortable: true,
                    filters:[{data: {type: 'has', isCase: false, name: ''}}],
                    filterRender:{name: 'FilterComplex'}
                },
                {field: '', title: this.msg('入库件数'), minWidth: 80, sortable: true,
                    slots: {
                        // 使用渲染函数
                        default: ({ row }) => {
                            return [
                                <span class="id-cell"><i class="in-edit el-icon-edit"></i></span>
                            ]
                        }
                    }
                },
                {field: '', title: this.msg('出库件数'), minWidth: 80, sortable: true,
                    slots: {
                        // 使用渲染函数
                        default: ({ row }) => {
                            return [
                                <span class="id-cell"><i class="out-edit el-icon-edit"></i></span>
                            ]
                        }
                    }
                },
            ]
        },
        getData(){
            this.$axios.post(this.$kit.api.stock.stockQtyEditList, this.ids)
                .then((result) => {
                    if(result.status){
                        let ds = result.data;
                        this.datas = ds;
                    }
                });
        },
        //点击行，选中行
        checkRow({row,rowIndex, $event, column, columnIndex}){
            //如果点击checkbox 则不处理
            let className = $event.target.className;
            // console.log(columnIndex);
            if(columnIndex == 5 ){
                if(className.indexOf('in-edit')>-1){
                    this.inQty(row);
                }
            }else if(columnIndex == 6 ){
                if(className.indexOf('out-edit')>-1){
                    this.outQty(row);
                }
            }
        },
        inQty(row){
            this.qtyVisiable = true;
            this.detailId = row.id;
            // this.$prompt('请输入入库件数', '提示', {
            //     closeOnClickModal:false,
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            // }).then(({ value }) => {
            //     if(!value){
            //         this.$message(this.msg('请输入入库件数'));
            //         return;
            //     }
            //     let reg = new RegExp("^[0-9]*$");
            //     if(!reg.test(value)){
            //         this.$message(this.msg('格式不正确，只能输入数字'));
            //         return;
            //     }
            //     let param = {
            //         id : row.id,
            //         productQty : value,
            //         type: 0,
            //     }
            //     this.$axios.post(this.$kit.api.stock.updateQty, param)
            //     .then((result) => {
            //         if(result.status){
            //             this.getData();
            //             this.$message(result.msg || this.msg('更新成功'));
            //         }else{
            //             this.$message(result.msg || this.msg('更新失败'));
            //         }
            //     });
            // }).catch(() => {
            //     this.$message({
            //         type: 'info',
            //         message: '取消输入'
            //     });       
            // });
        },
        outQty(row){
            this.qty1Visiable = true;
            this.detailId = row.id;
            // this.$prompt('请输入出库件数', '提示', {
            //     closeOnClickModal:false,
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            // }).then(({ value }) => {
            //     if(!value){
            //         this.$message(this.msg('请输入出库件数'));
            //         return;
            //     }
            //     let reg = new RegExp("^[0-9]*$");
            //     if(!reg.test(value)){
            //         this.$message(this.msg('格式不正确，只能输入数字'));
            //         return;
            //     }
            //     let param = {
            //         id : row.id,
            //         productQty : value,
            //         type: 1,
            //     }
            //    this.$axios.post(this.$kit.api.stock.updateQty, param)
            //     .then((result) => {
            //         if(result.status){
            //             this.getData();
            //             this.$message(result.msg || this.msg('更新成功'));
            //         }else{
            //             this.$message(this.msg('库存不足，更新失败'));
            //         }
            //     });
            // }).catch(() => {
            //     this.$message({
            //         type: 'info',
            //         message: '取消输入'
            //     });       
            // });
        }
    }
}
</script>

<style lang="scss" scoped>

.table-wrap{
    width:100%;
    overflow:auto;
}
.in-edit{cursor:pointer; display:inline-block;}
.out-edit{cursor:pointer; display:inline-block;}

</style>
