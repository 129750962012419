<!-- 详情分拆 -->
<template>
<el-form ref="form" label-width="100px">
    <el-form-item :label="msg('商品标题')">
        <el-input type="textarea" :rows="2" v-model="productTitle"></el-input>
    </el-form-item>
    <el-form-item :label="msg('规格')">
        <el-input v-model="productSpec"></el-input>
    </el-form-item>
    <el-form-item :label="msg('件数')">
        <el-input type="number" v-model="productQty"></el-input>
    </el-form-item>
    <el-form-item :label="msg('SKU')">
        <el-input type="number" v-model="sku"></el-input>
    </el-form-item>
    <el-form-item :label="msg('货号')">
        <el-input type="number" v-model="variationSku"></el-input>
    </el-form-item>
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('分拆')}}</span></el-button>
        <el-button  size="mini" @click="click">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'stockSplit',
  data(){
    return{
        productTitle:null,
        productSpec:null,
        productQty:0,
        sku:null,
        variationSku:null,
        code:null,
        creater:0,
        detailId:null,
        index:null,
    }
    
  }, 
  computed:{
  },
  props:{
      detail:Object,
      qty:Number
  },
  mounted: function(){
  },
  methods:{
    init(){
        this.productTitle = this.detail.productTitle;
        this.productSpec = this.detail.productSpec;
        this.productQty = this.detail.productQty;
        this.sku = this.detail.sku;
        this.variationSku = this.detail.variationSku;
        this.creater = this.detail.creater;
        this.code = this.detail.code;
        this.detailId = this.detail.id;
        this.stockId = this.detail.stockId;
        this.index = this.detail.index;
    },
    onSubmit(){
        let newQty = this.qty;
        if(this.productQty && this.productQty > 0){

            if(this.productQty <= this.qty){
                newQty = this.qty - this.productQty;
            }else{
                this.$message(this.msg('分拆数量必须小于等于原数量'));
                return;
            }
        }else if(this.productQty == 0){
            this.$message(this.msg('件数不能等于0'));
            return;
        }else{
            this.$message(this.msg('请输入件数'));
            return;
        }
        let stockDetail = {
            productTitle: this.productTitle,
            productQty: this.productQty,
            productSpec: this.productSpec,
            sku: this.sku,
            variationSku: this.variationSku,
            creater: this.creater,
            code: this.code,
            id: this.detailId,
            stockId: this.stockId,
        }

        this.$axios.post(this.$kit.api.stock.detailCreate+"?qty="+newQty, stockDetail)
            .then((result) => {
                if(result.status){
                    let data = result.data;
                    data['index'] = this.index;
                    this.$emit("close-dg", {detail:  data,newQty: newQty})
                }else{
                    this.$message(this.msg('分拆失败'));
                }
            });
    },
    click(){
        this.$emit("close-dg", {detail:  null,newQty: this.qty})
    }
  },
  
}
</script>

<style scoped lang="scss">


</style>
