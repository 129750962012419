import { Message  } from "element-ui";
import i18nKit from "../../plugins/I18N.js";
let i18n = i18nKit.methods;

/**
 * 统计： 选中的stockDetail的数据
 * @param {*} selectedDatas 
 * @returns 
 */
const summary = function(selectedDatas){
    var selectes = selectedDatas; //this.$refs.dataTable.getCheckboxRecords();
    if(selectes.length == 0){
        Message(i18n.msg('请选中一条数据进行操作'));
        return null;
    }
    let qty = 0;
    let stockArr = [];
    selectes.forEach(e=>{
        
        let productQty = e.productQty || e.detail.productQty;

        if(productQty && productQty > 0){
            qty += productQty;
        }
        stockArr.push(e.stockId*1);
    })
    let stockNums = Array.from(new Set(stockArr));
    let countModel = {
        checked:selectes.length,
        count:stockNums.length,
        qty:qty
    }

    return countModel;
}


export default {

    summary: summary

}
